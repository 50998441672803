/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Descriptions, Tag, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import GreenDrop from '../../../../assets/images/icons/green-drop.svg';
import BlueDrop from '../../../../assets/images/icons/blue-drop.svg';
import OrangeDrop from '../../../../assets/images/icons/orange-drop.svg';
import setVersionItem from '../../../../utils/constants/setVersionItem';

const IndexItem = ({ title, data, version, extra }) => {
  const { t } = useTranslation();

  const subTitleList = {
    why: t('wallet.subTitleList.why'),
    what: t('wallet.subTitleList.what'),
    prior: t('wallet.subTitleList.prior'),
    actors: t('wallet.subTitleList.actors'),
    cost: t('wallet.subTitleList.cost'),
    context: t('wallet.subTitleList.context'),
    risks: t('wallet.subTitleList.risks'),
    planing: t('wallet.subTitleList.planing'),
    utility: t('wallet.subTitleList.utility'),
    plan_com: t('wallet.subTitleList.plan_com')
  };

  const getVersion = () => {
    if (version === 1)
      return (
        <span
          style={{
            backgroundImage: `url(${OrangeDrop})`,
            height: 32,
            width: 24,
            marginBottom: 6
          }}
        >
          <span
            style={{
              fontSize: 10.5,
              color: 'white',
              fontWeight: 700,
              position: 'relative',
              top: 10,
              left: 5
            }}
          >
            V-I
          </span>
        </span>
      );
    if (version === 2)
      return (
        <span
          style={{
            backgroundImage: `url(${BlueDrop})`,
            height: 32,
            width: 24,
            marginBottom: 6
          }}
        >
          <span
            style={{
              fontSize: 10.5,
              color: 'white',
              fontWeight: 700,
              position: 'relative',
              top: 10,
              left: 5
            }}
          >
            {`V${version - 2}`}
          </span>
        </span>
      );
    if (version === 3)
      return (
        <span
          style={{
            backgroundImage: `url(${GreenDrop})`,
            height: 32,
            width: 24,
            marginBottom: 6
          }}
        >
          <span
            style={{
              fontSize: 10.5,
              color: 'white',
              fontWeight: 700,
              position: 'relative',
              top: 10,
              left: 5
            }}
          >
            {`V${version - 2}`}
          </span>
        </span>
      );
    if (version > 3)
      return (
        <span
          style={{
            backgroundImage: `url(${GreenDrop})`,
            height: 32,
            width: 24,
            marginBottom: 6
          }}
        >
          <span
            style={{
              fontSize: 10.5,
              color: 'white',
              fontWeight: 700,
              position: 'relative',
              top: 10,
              left: 5
            }}
          >
            {`V${version - 2}`}
          </span>
        </span>
      );
  };

  return data !== null ? (
    <>
      <Descriptions
        column={4}
        extra={
          <>
            {setVersionItem(version)}
            <Divider type="vertical" style={{ marginLeft: 16, height: 24 }} />
            {extra}
          </>
        }
        title={
          <span>
            {`${t(`projects.form.${title}`).toUpperCase()}: ${
              subTitleList[title]
            }`}
          </span>
        }
      >
        <Descriptions.Item label={t('projects.details.last_version')}>
          <Tag color="var(--secondaryColor)">
            {Moment(data.event.date).format('DD-MM-YYYY')}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.event_type')}>
          <Tag color="blue">{data.event.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.wording')}>
          <Tag color="blue">{data.event.name}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.comment')}>
          {data.event.comment ? (
            data.event.comment
          ) : (
            <Tag color="red">Aucun commentaire</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  ) : (
    <Descriptions
      column={4}
      extra={getVersion()}
      title={`${t(`projects.form.${title}`).toUpperCase()}: ${
        subTitleList[title]
      }`}
    />
  );
};

IndexItem.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.object],
    PropTypes.array
  ),
  version: PropTypes.number,
  extra: PropTypes.element
};

IndexItem.defaultProps = {
  title: null,
  data: null,
  version: null,
  extra: null
};

export default IndexItem;
