import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DatePicker, Form, Row, Col, Button } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import useProjectContext from '../../../../contexts/ProjectContext';

const UpdateDuplicate = ({ data, visible, setVisible, idPlanCom, setData }) => {
  const { patchDuplicateEvent, deleteDuplicateEvent } = useProjectContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const handleSubmit = (values) => {
    const { start_date } = values;
    const body = {
      ...data,
      start_date: moment(start_date).endOf('day')
    };
    patchDuplicateEvent(body, idPlanCom);
    setData();
    setVisible(false);
  };
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        start_date: moment(data.start_date) || Date.now()
      });
    }
  }, [data]);

  const deleteEventToDuplicateArray = () => {
    deleteDuplicateEvent(data, idPlanCom);
    setData();
    setVisible(!visible);
  };
  return (
    <Form
      name="plan_com"
      layout="vertical"
      onFinish={(values) => {
        handleSubmit(values);
      }}
      form={form}
    >
      <Row
        justify="center"
        style={{
          position: 'relative',
          marginBottom: 30
        }}
      >
        <Col span={24}>
          <Form.Item
            label={t('projects.form.items.plan_com.update_duplicate')}
            name={['start_date']}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Button
            style={{ margin: '0 6px' }}
            type="link"
            icon={<DeleteOutlined />}
            danger
            onClick={() => deleteEventToDuplicateArray()}
          >
            {t('projects.form.deliverables_plan_com.duplicate.delete_event')}
          </Button>
          <Button type="add" htmlType="submit" icon={<CheckOutlined />}>
            {t('projects.form.deliverables_plan_com.duplicate.update_event')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

UpdateDuplicate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  idPlanCom: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

export default UpdateDuplicate;
