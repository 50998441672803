import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';
import { Col, Row } from 'antd';

const ShowModalBizchart = ({ data, purpose, mean }) => {
  const [dataChart, setDataChart] = useState({});
  const cols = {
    time: {
      range: [0, 1]
    },
    pourcentage: {
      type: 'linear',
      tickCount: 5,
      ticks: ['0', '25', '50', '75', '100']
    }
  };

  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };

  const getData = () => {
    const chartFede = [];
    if (data.plan_com[data.plan_com.length - 1].deliverables_plan_com) {
      data.plan_com[data.plan_com.length - 1].deliverables_plan_com.forEach(
        (event) => {
          chartFede.push({
            time: `${
              event !== null
                ? moment(event?.start_date).format('DD-MM-YYYY')
                : ''
            }`,
            city: 'Fédération',
            comment: event?.goal_description,
            description: `${event?.name}`,
            pourcentage: event?.federation || 50
          });
        }
      );
      setDataChart(chartFede);
    }
  };
  useEffect(() => {
    if (data) getData();
  }, [purpose, data]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart
            theme="light"
            padding="auto"
            height={400}
            data={dataChart}
            autoFit
            scale={cols}
          >
            <span className="main-title" style={styles.mainTitle}>
              Variation de la Fédération du projet selon les évènements
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col span={24}>
                  <span>{`Moyenne ${mean} %`}</span>
                </Col>
              </Row>
            </span>
            <Axis />
            <Tooltip shared />
            <Legend />
            <Axis name="time" />
            <Axis
              name="pourcentage"
              label={{
                formatter: (val) => `${val}%`
              }}
            />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />
            <Geom
              type="line"
              position="time*pourcentage"
              size={2}
              color="city"
              shape="smooth"
            />
            <Geom
              tooltip={[
                'time*description*pourcentage*city*comment',
                (time, description, pourcentage, city, comment) => {
                  return {
                    name: `${comment}`,
                    title: `Event: ${description}`,
                    value: `${pourcentage} %`
                  };
                }
              ]}
              type="point"
              position="time*pourcentage"
              size={4}
              shape="circle"
              color="city"
              style={{
                stroke: '#fff',
                lineWidth: 1
              }}
            />
          </Chart>
        </div>
      ) : (
        <span>
          Oups, la version du projet doit être en V+ ou les versions de
          l&apos;item Féfération doivent être saisies
        </span>
      )}
    </>
  );
};

ShowModalBizchart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  mean: PropTypes.number
};

ShowModalBizchart.defaultProps = {
  data: null,
  purpose: null,
  mean: null
};

export default ShowModalBizchart;
