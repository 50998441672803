/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import {
  listObjectForItems,
  getIndexByVersionSelected
} from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';

const projectState = (t, project, filter) => {
  const actorsList = project.actors[project.actors.length - 1].actors_list;
  const getActor = (actorId) => {
    if (actorId) {
      const result = actorsList.map(
        (a) =>
          a._id === actorId &&
          `${a.group || ''} ${a.first_name || ''} ${a.last_name || ''}`
      );
      return result[0] || '';
    }
    return '';
  };
  const checkDataAndReturnSorting = (data, type) => {
    switch (type) {
      case 'prior':
        return [
          data.type,
          data.denomination,
          moment(data.creation_date).format('DD-MM-YYYY'),
          data.description
        ];
      case 'actors':
        return [
          data.group,
          data.effective,
          data.first_name,
          data.last_name,
          data.code,
          data.role,
          data.gain,
          data.coefficient
        ];
      case 'context':
        return [data.denomination, data.type, data.impact, data.description];
      case 'cost':
        return [
          data.denomination,
          data.type,
          data.quote,
          `${data.charge} j/h`,
          `${data.cost_value} €`,
          data.description
        ];
      case 'risks':
        return [
          data.number,
          data.potential_risk,
          data.type_of_risk,
          data.impact_effect,
          data.criticality,
          data.decision,
          data.measure_description
        ];
      case 'planing':
        if (data.planning) {
          return [
            data.description,
            data.global_type,
            data.comment,
            moment(data.planning.start_date).format('DD-MM-YYYY'),
            moment(data.planning.due_date).format('DD-MM-YYYY'),
            getActor(data.planning.actor),
            data.planning.status,
            `${data.planning.percentage} %`
          ];
        }
        return false;
      case 'utility':
        if (data.utility) {
          return [
            data.description,
            data.global_type,
            data.utility.use_for,
            getActor(data.utility.beneficiaries),
            data.utility.why,
            data.utility.coefficient
          ];
        }
        return false;
      case 'plan_com':
        return [
          data.name,
          moment(data.start_date).format('DD-MM-YYYY'),
          moment(data.due_date).format('DD-MM-YYYY'),
          data.number_frequency && data.type_frequency
            ? `${data.number_frequency} tous les ${data.type_frequency} jours`
            : '',
          data.type,
          data.channel,
          data.impact,
          getActor(data.in_charge_of),
          getActor(data.transmitter),
          getActor(data.target),
          data.federation,
          data.goal_description,
          data.message
        ];
      default:
        return false;
    }
  };
  const workbook = new Workbook();
  try {
    filter.items.forEach((key) => {
      const worksheet = workbook.addWorksheet(`${t(`projects.form.${key}`)}`);
      worksheet.properties.defaultColWidth = 15;
      worksheet.properties.defaultRowHeight = 20;
      const newHeader = headerByitems(false);
      const header = newHeader[key].map(({ label }) => {
        return t(`projects.form.${label}`);
      });
      const headerRow = worksheet.addRow(header);

      headerRow.eachCell((cell) => {
        cell.width = cell._value.model.value?.length + 10;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '202364' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: false,
          color: { argb: 'FFFFFF' },
          size: 11
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      if (key === 'what' || key === 'why') {
        worksheet.addRow([
          project[key][getIndexByVersionSelected(filter.version, project[key])]
            .description
        ]);
      } else {
        let row;
        project[
          key === 'planing' || key === 'utility' ? 'manage_planning' : key
        ][
          getIndexByVersionSelected(
            filter.version,
            project[
              key === 'planing' || key === 'utility' ? 'manage_planning' : key
            ]
          )
        ][listObjectForItems[key]].forEach((c) => {
          row = checkDataAndReturnSorting(c, key);
          if (row) worksheet.addRow(row);
        });
      }
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default projectState;
