/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tag, Collapse, List, Button, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import IndexItem from './IndexItem';
import WhatDisplay from '../ItemsDisplay/WhatDisplay';
import WhyDisplay from '../ItemsDisplay/WhyDisplay';
import PriorDisplay from '../ItemsDisplay/PriorDisplay';
import ContextDisplay from '../ItemsDisplay/ContextDisplay';
import UtilityDisplay from '../ItemsDisplay/UtilityDisplay';
import CostDisplay from '../ItemsDisplay/CostDisplay';
import ActorsDisplay from '../ItemsDisplay/ActorsDisplay';
import PlaningDisplay from '../ItemsDisplay/PlaningDisplay';
import PlanComDisplay from '../ItemsDisplay/PlanComDisplay';
import ButtonDeleteAll from './ButtonDeleteAll';
import RiskDisplay from '../ItemsDisplay/RiskDisplay';
import useProjectContext from '../../../../contexts/ProjectContext';
import {
  EditIcon,
  VInitIcon,
  V0Icon,
  VPlusIcon,
  VIconGray
} from '../../../../utils/constants/customIcon';

const CreateItemContainer = ({
  title,
  data,
  projectId,
  getProject,
  openModal,
  visibleRole,
  generateForm,
  managePlanning,
  loading,
  actors
}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const [index, setIndex] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const noItem = '';
  const ItemCheckList = {
    what: WhatDisplay,
    why: WhyDisplay,
    prior: PriorDisplay,
    context: ContextDisplay,
    utility: UtilityDisplay,
    cost: CostDisplay,
    actors: ActorsDisplay,
    planing: PlaningDisplay,
    plan_com: PlanComDisplay,
    risks: RiskDisplay
  };
  const { project } = useProjectContext();
  const config = {
    onGetResource: {
      setFields: (dataConfig) => ({
        ...dataConfig,
        files: [],
        _id: dataConfig._id,
        event: {
          date: moment(dataConfig.event.date),
          name: dataConfig.event.name,
          type: dataConfig.event.type,
          comment: dataConfig.event.comment
        }
      })
    }
  };
  const itemRenderShow = (current, type, originalElement) => {
    if (type === 'page') {
      if (current === 1) {
        if (current !== currentPage) {
          return (
            <span>
              <VIconGray />
              <span
                style={{
                  fontSize: 10.5,
                  fontWeight: 'bold',
                  color: 'white',
                  position: 'relative',
                  bottom: 26
                }}
              >
                {`V${current - 2}`}
              </span>
            </span>
          );
        }

        return (
          <span>
            <VInitIcon />
            <span
              style={{
                fontSize: 10.5,
                fontWeight: 'bold',
                color: 'white',
                position: 'relative',
                bottom: 26
              }}
            >
              V-I
            </span>
          </span>
        );
      }

      if (current === 2) {
        if (current !== currentPage) {
          return (
            <span>
              <VIconGray />
              <span
                style={{
                  fontSize: 10.5,
                  fontWeight: 'bold',
                  color: 'white',
                  position: 'relative',
                  bottom: 26
                }}
              >
                {`V${current - 2}`}
              </span>
            </span>
          );
        }
        return (
          <span>
            <V0Icon />
            <span
              style={{
                fontSize: 10.5,
                fontWeight: 'bold',
                color: 'white',
                position: 'relative',
                bottom: 26
              }}
            >
              {`V${current - 2}`}
            </span>
          </span>
        );
      }

      if (current === 3) {
        if (current !== currentPage) {
          return (
            <span>
              <VIconGray />
              <span
                style={{
                  fontSize: 10.5,
                  fontWeight: 'bold',
                  color: 'white',
                  position: 'relative',
                  bottom: 26
                }}
              >
                {`V${current - 2}`}
              </span>
            </span>
          );
        }
        return (
          <span>
            <VPlusIcon />
            <span
              style={{
                fontSize: 10.5,
                fontWeight: 'bold',
                color: 'white',
                position: 'relative',
                bottom: 26
              }}
            >
              {`V${current - 2}`}
            </span>
          </span>
        );
      }

      // eslint-disable-next-line react/jsx-one-expression-per-line
      if (current > 3) {
        if (current !== currentPage) {
          return (
            <span>
              <VIconGray />
              <span
                style={{
                  fontSize: 10.5,
                  fontWeight: 'bold',
                  color: 'white',
                  position: 'relative',
                  bottom: 26
                }}
              >
                {`V${current - 2}`}
              </span>
            </span>
          );
        }
        return (
          <span>
            <VPlusIcon />
            <span
              style={{
                fontSize: 10.5,
                fontWeight: 'bold',
                color: 'white',
                position: 'relative',
                bottom: 26
              }}
            >
              {`V${current - 2}`}
            </span>
          </span>
        );
      }
    }
    return originalElement;
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIndex(itemRenderShow(currentPage));
  };
  useEffect(() => {
    if (data && data.length) {
      setCurrentPage(data.length);
    }
  }, [data.length]);

  return (
    data !== undefined && (
      <>
        <Collapse
          collapsible="header"
          defaultActiveKey={['0']}
          style={{
            backgroundColor: '#FFF',
            borderRadius: '5px'
          }}
          className="item-header-collapse"
        >
          <Panel
            showArrow={false}
            header={
              data[data.length - 1] ? (
                <>
                  <Row justify="center">
                    <span
                      style={{ fontSize: 14, color: 'var(--disabledColor)' }}
                    >
                      {`${t('projects.form.details_items')} ${t(
                        `projects.form.${title}`
                      ).toUpperCase()}`}
                    </span>
                    <IndexItem
                      key={data[currentPage - 1]?._id || null}
                      data={
                        data[currentPage - 1] ? data[currentPage - 1] : null
                      }
                      title={title}
                      version={data.length ? currentPage : null}
                      extra={
                        visibleRole ? (
                          <>
                            <Button
                              disabled={project?.status === 'close'}
                              onClick={() =>
                                openModal(
                                  data[currentPage - 1],
                                  managePlanning || title,
                                  index,
                                  'event',
                                  'event',
                                  config
                                )
                              }
                              size="small"
                              type="link"
                              style={{
                                color: 'var(--secondaryColor)',
                                top: -2
                              }}
                            >
                              <EditIcon />
                            </Button>
                            {data[currentPage - 1] !== null &&
                            title !== 'actors' ? (
                              <ButtonDeleteAll
                                purpose="version"
                                title={managePlanning || title}
                                projectId={projectId}
                                titleId={data[currentPage - 1]?._id || null}
                                handleCancel={getProject}
                              />
                            ) : null}
                          </>
                        ) : null
                      }
                    />
                  </Row>
                </>
              ) : (
                <>
                  <Descriptions
                    title={t(`projects.form.${title}`).toUpperCase()}
                    extra={
                      visibleRole && (
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() =>
                            openModal(
                              noItem,
                              managePlanning || title,
                              index,
                              'event',
                              'event',
                              config
                            )
                          }
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: -2
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )
                    }
                  >
                    <Descriptions.Item
                      label={t('projects.details.last_version')}
                    >
                      <Tag color="#108ee9">Aucune version disponible</Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )
            }
          >
            <Spin spinning={loading}>
              <List
                pagination={{
                  pageSize: 1,
                  current: currentPage,
                  total: data ? data.length : 1,
                  itemRender: itemRenderShow,
                  onChange: (page) => handlePageChange(page),
                  showTotal: (total) => (
                    <span count={total || 0}>
                      {`${total} ${t('wallet.versions')}`}
                    </span>
                  )
                }}
              >
                <List.Item>
                  {data && data[currentPage - 1] ? (
                    <>
                      {Object.entries(ItemCheckList).map(([key, Value]) => {
                        return title === key ? (
                          <Value
                            changeVersion={openModal}
                            key={data[currentPage - 1]._id}
                            data={data[currentPage - 1]}
                            id={projectId}
                            title={key}
                            getProject={getProject}
                            generateForm={generateForm}
                            actors={actors || null}
                            role={visibleRole}
                          />
                        ) : null;
                      })}
                    </>
                  ) : (
                    <Descriptions>
                      <Descriptions.Item>
                        <Tag color="red">
                          Aucune donnée dans cette version Item
                        </Tag>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </List.Item>
              </List>
            </Spin>
          </Panel>
        </Collapse>
      </>
    )
  );
};
CreateItemContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  projectId: PropTypes.string,
  openModal: PropTypes.func,
  getProject: PropTypes.func.isRequired,
  visibleRole: PropTypes.bool.isRequired,
  indexPanel: PropTypes.string,
  generateForm: PropTypes.func,
  managePlanning: PropTypes.string,
  loading: PropTypes.bool,
  actors: PropTypes.oneOfType([PropTypes.array])
};

CreateItemContainer.defaultProps = {
  title: null,
  projectId: null,
  openModal: null,
  indexPanel: null,
  generateForm: null,
  managePlanning: null,
  loading: false,
  actors: null
};

export default CreateItemContainer;
