import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Row, Col, Select, Spin, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { ManageStatusIcon } from '../../../../utils/constants/customIcon';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from '../fieldsItems/itemsFields';

const { Option } = Select;

const ButtonManageStatus = ({ project, getProject, checkRole }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const fields = useFields('event', project?._id || null);
  const [managers, setManagers] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');
  const { message, success } = useErrorMessage();
  const [form] = Form.useForm();
  const listStatus = {
    commercial: ['DRAFT', 'V-Init', 'V0'],
    manager: ['V0', 'V1', 'VX', 'close'],
    admins: ['DRAFT', 'V-Init', 'V0', 'V1', 'VX', 'close']
  };

  const updateStatus = async (bodyArg, assignee) => {
    setLoading(false);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project._id}`,
        body: { ...bodyArg, ...assignee }
      });
      getProject();
      checkRole();
      success(`La version de ${project.name} a changé`);
      setLoading(true);
    } catch (e) {
      if (e.response) message(e.response.status);
      setLoading(false);
    }
  };

  const getManagers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?filter[role]=managers:PROJECT_MANAGER'
      });
      setManagers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getManagers();
    })();
  }, [getManagers]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    if (values.status === 'V0') {
      if (project.assignee !== undefined) await updateStatus(values);
    }

    if (values.status === 'V-Init') {
      const actorProject = managers.filter((a) => a._id === values.assignee);
      const actorsObject = {
        actors: {
          event: {
            date: Date.now(),
            name: 'Attribution à un chef de projet',
            type: 'Initialisation'
          },
          actors_list: [
            {
              group: 'Citizen Can',
              effective: 1,
              first_name: actorProject[0].first_name,
              last_name: actorProject[0].last_name,
              code: `${actorProject[0].first_name.charAt(
                0
              )}${actorProject[0].last_name.charAt(0)}${Math.floor(
                Math.random() * (100 - 10) + 10
              )}`,
              role: 'Chef de projet',
              coefficient: 100
            }
          ]
        }
      };
      await updateStatus(values, actorsObject);
    } else await updateStatus(values);
    setVisible(false);
  };

  const getRoleForSelect = (role, item) => {
    if (role === 'admins:ADMIN') {
      const indexTodelete = listStatus.admins.indexOf(project.status);
      return item
        ? listStatus.admins.slice(indexTodelete + 1)[0]
        : listStatus.admins.slice(indexTodelete + 1);
    }
    if (role === 'commercials:COMMERCIAL') {
      const indexTodelete = listStatus.commercial.indexOf(project.status);
      return item
        ? listStatus.commercial.slice(indexTodelete + 1)[0]
        : listStatus.commercial.slice(indexTodelete + 1);
    }
    if (role === 'managers:PROJECT_MANAGER') {
      const indexTodelete = listStatus.manager.indexOf(project.status);
      return item
        ? listStatus.manager.slice(indexTodelete + 1)[0]
        : listStatus.manager.slice(indexTodelete + 1);
    }
    return listStatus.manager;
  };

  const checkRoleForDisplayButton = () => {
    if (
      user?.role === 'commercials:COMMERCIAL' &&
      project.status !== 'V0' &&
      project.status !== 'V1'
    ) {
      return (
        <Button
          type="add"
          onClick={showModal}
          style={{ height: 34, display: 'flex', alignItems: 'center' }}
        >
          <ManageStatusIcon />
          <span>{`${t('projects.assignment_manager')}`}</span>
        </Button>
      );
    }
    if (
      user?.role === 'managers:PROJECT_MANAGER' ||
      user?.role === 'admins:ADMIN'
    ) {
      return (
        <Button
          type="add"
          onClick={showModal}
          style={{ height: 34, display: 'flex', alignItems: 'center' }}
        >
          <ManageStatusIcon />
          <span>{`${t('projects.change_version')}`}</span>
        </Button>
      );
    }
    return null;
  };

  return project ? (
    <Row justify="end" style={{ right: 42 }}>
      <Col>
        <Row justify="end" align="middle">
          {checkRoleForDisplayButton()}
        </Row>
        <Modal
          visible={visible}
          title={`Changer le statut de ${project.name} ?`}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...formItemLayout}
            onFinish={handleSubmit}
            initialValues={{
              ...project,
              status: getRoleForSelect(user?.role, project.status),
              assignee: project?.assignee
            }}
            form={form}
          >
            <Row justify="space-between">
              <Col span={24}>
                <Form.Item
                  label={t('projects.details.status')}
                  name={['status']}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ textAlign: 'center', width: 200 }}
                    onChange={(event) => setSelectedStatus(event)}
                  >
                    {getRoleForSelect(user?.role).map((type) => (
                      <Option value={type} key={type}>
                        {t(`projects.status.${type}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {(project.status === 'V0' || project.status === 'V1') &&
                  selectedStatus !== 'close' && (
                    <>
                      <Divider />
                      {fields.map((field) => generateFields('event', field))}
                    </>
                  )}
              </Col>
              {project.status === 'DRAFT' ||
              (project.status === 'V-Init' &&
                project.assignee === undefined) ? (
                // eslint-disable-next-line react/jsx-indent
                <Col span={24}>
                  <Form.Item
                    label={t('projects.form.project_manager')}
                    name={['assignee']}
                    rules={[{ required: true }]}
                  >
                    <Select style={{ textAlign: 'center', width: 200 }}>
                      {managers?.map((type) => (
                        <Option value={type._id} key={type._id}>
                          {`${type.first_name} ${type.last_name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            <Form.Item {...tailFormItemLayout}>
              <Row justify="center">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={handleCancel}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  ) : (
    <Spin spinning={loading} />
  );
};

ButtonManageStatus.propTypes = {
  project: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  getProject: PropTypes.func.isRequired,
  checkRole: PropTypes.func.isRequired
};

export default ButtonManageStatus;
