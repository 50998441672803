import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Descriptions, Card, Button, Modal, Row, Divider, Badge } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import { CreateIcon, EditIcon } from '../../../../utils/constants/customIcon';
import useProjectContext from '../../../../contexts/ProjectContext';

const ContextDisplay = ({
  data,
  title,
  id,
  getProject,
  generateForm,
  role
}) => {
  const { setDataToModalFiles, getFilesByItem, project } = useProjectContext();
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataFactor, setDataFactor] = useState();

  const config = {
    onGetResource: {
      // eslint-disable-next-line no-shadow
      setFields: (dataT) => ({
        ...dataT,
        factors: { ...dataFactor }
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, factor) => {
    setVisible(idRender);
    setDataFactor({ ...factor });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }

    return 1;
  };

  useEffect(() => {
    if (data.files) getFilesByItem(data.files);
  }, [project]);

  return (
    <>
      {data.factors.map((e) => {
        const { _id, files, type, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            {visible === _id && dataFactor ? (
              generateForm('edit', title, data, closeForm, 'factors', config)
            ) : (
              <Card>
                <Descriptions
                  extra={
                    role ? (
                      <Row justify="space-around">
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() => (role ? openForm(_id, e) : null)}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: 2
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Divider
                          type="vertical"
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            height: 30
                          }}
                        />
                        <Badge count={files?.length}>
                          <Button
                            disabled={project?.status === 'close'}
                            size="small"
                            style={{ bottom: -5 }}
                            onClick={() =>
                              setDataToModalFiles(
                                files || [],
                                title,
                                data._id,
                                'factors',
                                _id
                              )
                            }
                            type="primary"
                            icon={<CloudServerOutlined size={20} />}
                          />
                        </Badge>
                        <Divider
                          type="vertical"
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            height: 30
                          }}
                        />
                        <ButtonDeleteAll
                          purpose="matrice"
                          projectId={id}
                          title={title}
                          titleId={data._id}
                          matriceName="factors"
                          matriceId={_id}
                          handleCancel={closeForm}
                        />
                      </Row>
                    ) : null
                  }
                  key={_id}
                  column={4}
                  title={type}
                  size="small"
                >
                  {Object.entries(rest).map(([key, value]) => {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={t(`projects.form.items.context.factors.${key}`)}
                        span={setSpan(key)}
                      >
                        {value}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            )}
          </React.Fragment>
        );
      })}
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={1000}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            title,
            data,
            closeFormModal,
            'factors'
          )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => openFormModal()}
        >
          <CreateIcon />
          {t('projects.form.items.context.factors.addFactor')}
        </Button>
      ) : null}
    </>
  );
};

ContextDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool
};

ContextDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false
};

export default ContextDisplay;
