import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Input, InputNumber, Divider, Button, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const useRisksFields = (id, enums, loading) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);
  const impact = ['Très limité', 'Limité', 'Modéré', 'Elevé', 'Très élevé'];
  const probality = ['Très faible', 'Faible', 'Moyen', 'Fort', 'Très fort'];
  const detection = [
    'Fortes probalités de détection',
    "Probalités d'être détecté",
    'Détectable',
    'Presque indétectable',
    'Indétectable'
  ];
  const decision = [
    'Couverture préventive du risque',
    'Couverture curative du risque',
    'Pas de couverture du risque grave'
  ];

  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };
  const risksFields = [
    {
      name: ['risk_list', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['risk_list', 'number'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['risk_list', 'potential_risk'],
      rules: [{ required: false }],
      input: <TextArea />
    },
    {
      name: ['risk_list', 'type_of_risk'],
      rules: [{ required: true }],
      input: enums ? (
        <Select
          showSearch
          style={{ width: 240 }}
          placeholder="Choisir un type"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()} disabled={!newOption}>
                  <PlusOutlined />
                  {t(`projects.form.risk_list.title`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.types_of_risk.map((ele) => (
            <Option key={ele}>{ele}</Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['risk_list', 'impact_effect'],
      rules: [{ required: false }],
      input: <TextArea />
    },
    {
      name: ['risk_list', 'probality'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {probality.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'detection'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {detection.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'impact'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {impact.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'criticality'],
      rules: [{ required: false }],
      input: <InputNumber disabled />
    },
    {
      name: ['risk_list', 'decision'],
      rules: [{ required: false }],
      input: (
        <Select loading={loading}>
          {decision.map((value) => {
            return value ? (
              <Option key={value} value={value}>
                {value}
              </Option>
            ) : null;
          })}
        </Select>
      )
    },
    {
      name: ['risk_list', 'decision_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD-MM-YYYY"
          bordered={false}
          placeholder="Date..."
        />
      )
    },
    {
      name: ['risk_list', 'measure_description'],
      rules: [{ required: false }],
      input: <TextArea />
    }
  ];

  return { risksFields };
};

export default useRisksFields;
