import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Row, Tag, Tabs, Col, Spin, Card, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import setIconStatus from '../../utils/constants/setIconStatus';
import useTime from '../home/indicatorsCommercial/time';
import useCost from '../home/indicatorsCommercial/cost';
import useUtility from '../home/indicatorsCommercial/utility';
import useFederation from '../home/indicatorsCommercial/federation';
import BalanceSheetTab from './BalanceSheetTab';
import useBalanceSheetContext from './BalanceSheetContext';
import useHandleResize from '../../utils/HandleResize';
import useLanguageContext from '../../contexts/LanguageContext';

const ShowBalanceSheet = () => {
  const { message } = useErrorMessage();
  const {
    getProjects,
    projects,
    loading,
    setLoading
  } = useBalanceSheetContext();
  const { dispatchAPI } = useAuthContext();
  const { locale } = useLanguageContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [KeyTab, setKeyTab] = useState();
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [checkError, setCheckError] = useState(false);
  const indicatorsResume = {
    time: useTime,
    cost: useCost,
    utility: useUtility,
    federation: useFederation
  };
  const { TabPane } = Tabs;
  const { width } = useHandleResize();

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/reporting?filter[type]=parent_BALANCE_SHEET'
      });
      setUsedTemplates(data);
      if (data.length === 0) setCheckError(true);
    } catch (e) {
      setLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const generateBalanceSheet = async (balanceId, data, name) => {
    try {
      const response = await dispatchAPI('POST', {
        url: `/balance-sheet/generate/${balanceId}/${
          usedTemplates[usedTemplates.length - 1]._id
        }`,
        responseType: 'arraybuffer',
        body: { data: { ...data }, translation: locale }
      });
      const bfArray = new Uint8Array(response.data.data);
      const blob = new Blob([bfArray], {
        type: 'application/docx'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Bilan ${name}.docx`;
      setLoading(false);
      a.click();
    } catch (e) {
      setLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProjects();
      await getTemplateSetting();
      setLoading(false);
    })();
  }, [getProjects]);

  // useEffect(() => {
  //   if (projects) {
  //     setKeyTab(projects[0]?._id);
  //   }
  // }, [projects]);

  const getKeyTab = (activekey) => {
    setKeyTab(activekey);
  };

  const generateDocx = (project) => {
    const dataToSend = {
      name: project.name,
      status: project.status,
      assignee: project.assignee,
      ...project.balance_sheet
    };
    generateBalanceSheet(project.balance_sheet._id, dataToSend, project.name);
  };

  return projects ? (
    <>
      <Spin spinning={loading} tip={t('global.loading')}>
        <div style={{ fontSize: '21px', marginBottom: 10 }}>
          {t(`${pathname.substring(1)}.title`)}
        </div>
        <Tabs activeKey={KeyTab} onChange={(activeKey) => getKeyTab(activeKey)}>
          {projects.length > 0 ? (
            projects.map((project) => {
              return (
                <TabPane
                  tab={setIconStatus(project.name, project.status, true, 29)}
                  key={project._id}
                >
                  <Row justify="space-around" align="middle">
                    <Col style={{ textAlign: 'center' }} xl={10} sm={24}>
                      <Card style={{ marginBottom: width > 1200 ? 0 : 16 }}>
                        <Row justify="space-around">
                          {Object.entries(indicatorsResume).map(
                            ([key, value]) => (
                              <Col span={6} key={key}>
                                {value(project, t)}
                              </Col>
                            )
                          )}
                        </Row>
                      </Card>
                    </Col>
                    <Col style={{ textAlign: 'center' }} xl={6} sm={10}>
                      {usedTemplates[usedTemplates.length - 1] ? (
                        <Button
                          type="add"
                          style={{ minHeight: 50, margin: '10px 0' }}
                          onClick={() => generateDocx(project)}
                        >
                          {`${t('balance_sheet.create_balance_sheet')} ${
                            project.name
                          }`}
                        </Button>
                      ) : null}
                      {checkError ? (
                        <>
                          <Col span={24}>
                            <ExclamationCircleOutlined />
                            <span>{t('reporting.no_template')}</span>
                          </Col>

                          <Tag color="red" style={{ justifyContent: 'center' }}>
                            {t('global.error')}
                          </Tag>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={24}>
                      <BalanceSheetTab data={project} />
                    </Col>
                  </Row>
                </TabPane>
              );
            })
          ) : (
            <Row justify="center">{t('errors.message.no_projects')}</Row>
          )}
        </Tabs>
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

export default ShowBalanceSheet;
