/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Descriptions,
  Tag,
  Button,
  Collapse,
  Tabs,
  Modal,
  Row,
  Card,
  Col,
  Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import Gantt from '../../../../components/Widgets/Gantt';
import { CreateIcon, EditIcon } from '../../../../utils/constants/customIcon';
import useProjectContext from '../../../../contexts/ProjectContext';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const PlaningDisplay = ({
  data,
  title,
  id,
  getProject,
  generateForm,
  actors,
  role
}) => {
  const { t } = useTranslation();
  const typeOfPlaning = { planning: 'Planning', utility: 'Utilité' };
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataDeliverables, setDataDeliverables] = useState();
  const [visibleLife, setVisibleLife] = useState();
  const [dataChart, setDataChart] = useState();
  const [ganttConfig, setGanttConfig] = useState();
  const [ganttData, setGanttData] = useState();
  const { project } = useProjectContext();
  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        deliverables: {
          ...dataDeliverables,
          global_type: 'Informations'
        }
      })
    }
  };

  const configLifeProject = {
    onGetResource: {
      setFields: (dataLife) => ({
        ...dataLife,
        start_project:
          dataLife.start_project !== null ? moment(dataLife.start_project) : '',
        end_project:
          dataLife.end_project !== null ? moment(dataLife.end_project) : ''
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openFormGantt = (idDeliv, deliverable) => {
    if ((idDeliv, deliverable)) {
      setGanttConfig(true);
      const { start_date, due_date, ...rest } = deliverable.planning;
      setDataDeliverables({
        ...deliverable,
        planning: {
          ...rest,
          start_date: start_date !== undefined ? moment(start_date) : undefined,
          due_date: moment(due_date)
        }
      });
      setVisibleModal(true);
    }
  };
  const closeFormGantt = () => {
    setDataChart();
    setVisibleModal(false);
  };

  const openForm = (idRender, deliverables) => {
    const { planning, ...rest } = deliverables;
    setVisible(idRender);
    setDataDeliverables({
      ...rest,
      planning: planning
        ? {
            ...planning,
            start_date: planning.start_date
              ? moment(planning.start_date)
              : undefined,
            due_date: moment(planning.due_date)
              ? moment(planning.due_date)
              : '',
            piloting_mark: planning.piloting_mark,
            gantt_mark: planning.gantt_mark,
            is_task: planning.is_task
          }
        : undefined
    });
  };
  const openLifeProject = () => {
    setVisibleLife(true);
    getProject();
  };
  const closeLifeProject = () => {
    setVisibleLife(false);
    getProject();
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  const getDataChart = (event) => {
    if (event && event.data && event.data._origin) {
      setDataChart(event.data._origin.id);
    }
  };
  const getColorForPercentage = (value) => {
    if (value === 0) return { color: 'red' };
    if (value < 50) return { color: 'var(--clientColor)' };
    if (value > 50) return { color: 'var(--addColor)' };
    return { color: 'var(--clientColor)' };
  };

  const formatDataToChart = () => {
    const chartData = [];
    data.deliverables.map((e) => {
      if (e.planning && e.planning.gantt_mark === true) {
        const { start_date, due_date, status, color } = e.planning;
        if ((start_date, due_date !== null)) {
          chartData.push({
            id: e._id,
            task: `${e._id + e.description}`,
            start_date:
              start_date !== undefined
                ? moment(start_date).format('YYYY-MM-DD')
                : moment(due_date).format('YYYY-MM-DD'),
            due_date: moment(due_date).format('YYYY-MM-DD'),
            status,
            description: e.description,
            comment: e.comment,
            color: color || '#000'
          });
        }
      }
      setGanttData(chartData);
      return true;
    });
  };
  useEffect(() => {
    if (data) {
      formatDataToChart();
    }
  }, [data]);

  const displayPlaningHeaderItem = (
    description,
    start_date,
    due_date,
    status,
    percentage,
    deleted_to_upper_version
  ) => {
    return (
      <Descriptions column={6} size="small">
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.description`)}
          span={2}
        >
          {description}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.start_date`)}
        >
          <div>
            {start_date ? (
              <Tag>{moment(start_date).format('DD-MM-YYYY')}</Tag>
            ) : (
              <Tag color="red">
                {t(`projects.form.items.utility.deliverables.nodata`)}
              </Tag>
            )}
            {deleted_to_upper_version ? (
              <p style={{ marginTop: 5, color: 'orange' }}>
                {t(
                  `projects.form.items.utility.deliverables.deleted_to_upper_version`
                )}
              </p>
            ) : (
              []
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.due_date`)}
        >
          {due_date ? (
            <Tag>{moment(due_date).format('DD-MM-YYYY')}</Tag>
          ) : (
            <Tag>Aucune donnée</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.status`)}
        >
          <Tag>{status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.percentage`)}
        >
          <span style={getColorForPercentage(percentage)}>{percentage}</span>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const displayUtilityHeaderItem = (
    description,
    use_for,
    coefficient,
    why,
    actor
  ) => {
    return (
      <Descriptions column={5} size="small">
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.description`)}
          span={2}
        >
          {description}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.use_for`)}
        >
          {`${use_for} -`}

          {actor &&
            actors.map(
              (a) =>
                a._id === actor[0] &&
                `${a.group || ''} ${a.first_name || ''} ${a.last_name || ''}`
            )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.coefficient`)}
        >
          {coefficient}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.why`)}
        >
          {why}
        </Descriptions.Item>
      </Descriptions>
    );
  };
  const setHeaderRender = (type, value) => {
    if (type === 'Planning' && value.planning) {
      return displayPlaningHeaderItem(
        value?.description,
        value.planning?.start_date || null,
        value.planning?.due_date || null,
        value.planning?.status || null,
        value.planning?.percentage || null,
        value.planning?.deleted_to_upper_version || null
      );
    }
    if (type === 'Utilité' && value.utility) {
      return displayUtilityHeaderItem(
        value?.description,
        value.utility?.use_for || null,
        value.utility?.coefficient || null,
        value.utility?.why || null,
        value.utility?.beneficiaries || null
      );
    }
    return null;
  };

  return (
    <>
      {visibleLife && project?.status !== 'close' ? (
        generateForm(
          'edit',
          'manage_planning',
          data,
          closeLifeProject,
          'lifeProject',
          configLifeProject
        )
      ) : (
        <Card
          bordered={false}
          onClick={() => (role ? openLifeProject() : null)}
        >
          <Descriptions column={3} size="small">
            <Descriptions.Item
              style={{ color: '#00000' }}
              label="Début de projet"
            >
              {data.start_project ? (
                moment(data.start_project).format('DD-MM-YYYY')
              ) : (
                <Tag>Aucune date définie</Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Fin de projet">
              {data.end_project ? (
                moment(data.end_project).format('DD-MM-YYYY')
              ) : (
                <Tag>Aucune date définie</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Tabs
        animated={{ inkBar: true }}
        id="blue-tab-planning"
        defaultActiveKey="Gantt"
      >
        {Object.entries(typeOfPlaning).map(([key, value]) => {
          return (
            <TabPane tab={value} key={key} span={2}>
              {data.deliverables
                .filter((deli) => deli[key])
                .map((e) => {
                  const { _id, planning, utility } = e;
                  // eslint-disable-next-line no-nested-ternary

                  return visible === _id && dataDeliverables ? (
                    generateForm(
                      'edit',
                      'manage_planning',
                      data,
                      closeForm,
                      'deliverables',
                      config
                    )
                  ) : (
                    <>
                      <Collapse key={_id}>
                        <Panel
                          style={{ marginBottom: 12 }}
                          extra={
                            role ? (
                              <Row justify="center">
                                <Button
                                  disabled={project?.status === 'close'}
                                  onClick={() => openForm(_id, e)}
                                  size="small"
                                  type="link"
                                  style={{
                                    color: 'var(--secondaryColor)',
                                    top: 2
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <ButtonDeleteAll
                                  purpose="matrice"
                                  projectId={id}
                                  title="manage_planning"
                                  titleId={data._id}
                                  data={data}
                                  matriceName="deliverables"
                                  matriceId={_id}
                                  handleCancel={closeForm}
                                />
                              </Row>
                            ) : null
                          }
                          header={setHeaderRender(value, e)}
                        >
                          <Descriptions
                            column={2}
                            size="small"
                            style={{ padding: 12 }}
                          >
                            <Descriptions.Item
                              label={
                                value === 'Planning'
                                  ? t(`projects.form.deliverables.actor`)
                                  : t(
                                      `projects.form.deliverables.beneficiaries`
                                    )
                              }
                            >
                              {value === 'Planning'
                                ? actors.map((a) =>
                                    a._id === planning.actor
                                      ? `${a.group || ''} ${a.first_name ||
                                          ''} ${a.last_name || ''}`
                                      : null
                                  )
                                : actors.map((a) =>
                                    a._id === (utility && utility.beneficiaries)
                                      ? `${a.group || ''} ${a.first_name ||
                                          ''} ${a.last_name || ''}`
                                      : null
                                  )}
                            </Descriptions.Item>
                          </Descriptions>
                        </Panel>
                      </Collapse>
                    </>
                  );
                })}
            </TabPane>
          );
        })}
        <TabPane tab="Gantt" span={2}>
          <Card
            style={{
              margin: '20px',
              overflowY: 'hidden',
              width: '100%',
              overflowX: 'auto',
              minHeight: 'fit-content'
            }}
          >
            <Row justify="space-between">
              <Col xl={24} sm={24}>
                {dataChart
                  ? data.deliverables.map((e) =>
                      dataChart === e._id ? (
                        <Card
                          style={{
                            zIndex: 5,
                            marginLeft: '60%',
                            marginTop: '5%',
                            width: 500,
                            border: '0.4px solid',
                            position: 'absolute'
                          }}
                          extra={
                            <Row justify="space-between">
                              {role ? (
                                <Button
                                  disabled={project?.status === 'close'}
                                  size="small"
                                  type="link"
                                  style={{
                                    color: 'var(--secondaryColor)',
                                    top: -2
                                  }}
                                  onClick={() => openFormGantt(e._id, e)}
                                >
                                  <EditIcon />
                                </Button>
                              ) : null}

                              <Button
                                onClick={() => closeFormGantt()}
                                style={{
                                  color: 'var(--errorColor)',
                                  background: 'transparent',
                                  border: 'none',
                                  marginLeft: 5,
                                  boxShadow: 'none'
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </Row>
                          }
                          key={e._id}
                          title={e.description}
                        >
                          <Divider />
                          <Descriptions column={2} size="small">
                            {e?.planning?.start_date !== undefined ? (
                              <Descriptions.Item
                                label={t(
                                  `projects.form.items.utility.deliverables.start_date`
                                )}
                              >
                                {moment(e?.planning?.start_date).format(
                                  'DD-MM-YYYY'
                                ) !== undefined
                                  ? moment(e?.planning?.start_date).format(
                                      'DD-MM-YYYY'
                                    )
                                  : ''}
                              </Descriptions.Item>
                            ) : null}

                            <Descriptions.Item
                              label={t(
                                'projects.form.items.utility.deliverables.due_date'
                              )}
                            >
                              {moment(e?.planning?.due_date).format(
                                'dddd DD-MM-YYYY'
                              ) !== null
                                ? moment(e?.planning?.due_date).format(
                                    'dddd DD-MM-YYYY'
                                  )
                                : ''}
                            </Descriptions.Item>
                            <Descriptions.Item
                              span={2}
                              label={t(
                                'projects.form.items.utility.deliverables.description'
                              )}
                            >
                              {e.description}
                            </Descriptions.Item>
                            <Descriptions.Item
                              span={2}
                              label={t(
                                'projects.form.items.utility.deliverables.actor'
                              )}
                            >
                              {actors !== null
                                ? actors.map((a) =>
                                    a._id === e.planning.actor
                                      ? `${a.group || ''} ${a.first_name ||
                                          ''} ${a.last_name || ''}`
                                      : null
                                  )
                                : null}
                            </Descriptions.Item>
                            <Descriptions.Item
                              span={2}
                              style={{ margin: 50 }}
                              label={t(
                                'projects.form.items.utility.deliverables.status'
                              )}
                            >
                              {e?.planning?.status}
                            </Descriptions.Item>
                            <Descriptions.Item
                              span={2}
                              style={{ margin: 50 }}
                              label={t(
                                'projects.form.items.utility.deliverables.comment'
                              )}
                            >
                              {e.comment}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                      ) : null
                    )
                  : null}
                <Gantt
                  key="planning"
                  range={[data.start_project, data.end_project]}
                  data={ganttData}
                  purpose="planning"
                  width={1200}
                  onClick={getDataChart}
                />
              </Col>
            </Row>
          </Card>
        </TabPane>
      </Tabs>
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          destroyOnClose
          width={1000}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            'manage_planning',
            data,
            closeFormModal,
            'deliverables',
            ganttConfig ? config : null
          )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => {
            setGanttConfig(false);
            openFormModal();
          }}
        >
          <CreateIcon />
          {t('projects.form.items.planing.deliverables.addPlaning')}
        </Button>
      ) : null}
    </>
  );
};

PlaningDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  actors: PropTypes.oneOfType([PropTypes.array]),
  role: PropTypes.bool
};

PlaningDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  actors: '',
  getProject: null,
  role: false
};

export default PlaningDisplay;
