import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Divider,
  Popconfirm
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import useColumns from './Columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fieldsItems/itemsFields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const iconSize = 18;

const Projects = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const fields = useFields('event', null);
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [getRole, setGetRole] = useState();
  const [dataToUpdate, setDataToUpdate] = useState();
  const [purpose, setPurpose] = useState('create');
  const [refresh, setRefresh] = useState(false);
  const generateFields = useGenerateFormItem();

  const deleteProject = async (id) => {
    setRefresh(false);
    try {
      await dispatchAPI('DELETE', {
        url: `/projects/${id}`
      });
      message.success('Votre projet a été supprimé');
      setRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `/projects/projects/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {record.status !== 'V0' || user.type !== 'COMMERCIAL' ? (
            <>
              <Divider type="vertical" />

              <EditOutlined
                style={{ fontSize: iconSize }}
                onClick={() => {
                  setDataToUpdate(record);
                  setPurpose('edit');
                  setVisible(!visible);
                }}
              />

              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => {
                  setRefresh(!refresh);
                  deleteProject(record.key);
                  setRefresh(!refresh);
                }}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
      fixed: 'rigth'
    }
  ];
  const columns = useColumns(actionColumn);
  const getUserRole = () => {
    if (user.role === 'admins:ADMIN') {
      setGetRole('');
    }

    if (user.role === 'commercials:COMMERCIAL') {
      setGetRole(`created_by=${user._id}`);
    }

    if (user.role === 'managers:PROJECT_MANAGER') {
      setGetRole(`status=V&status=V0&status=V1&status=VX&assignee=${user._id}`);
    }
  };

  useEffect(() => {
    getUserRole();
  }, [getRole]);

  const headers = [
    {
      label: 'libellé',
      key: 'name'
    },
    {
      label: 'assignee_to_first_name',
      key: 'assignee.first_name'
    },
    {
      label: 'assignee_to_last_name',
      key: 'assignee.last_name'
    }
  ];

  const showModal = () => {
    setPurpose('create');
    setVisible(true);
  };
  const handleCancel = () => {
    setDataToUpdate();
    form.resetFields();
    setVisible(false);
  };

  const createProject = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: 'projects',
        body
      });
      message.success('Votre projet a été créé');
      setRefresh(!refresh);
      history.push(`/projects/projects/show/${response.data._id}`);
    } catch (e) {
      if (e.response?.data?.description) {
        message.warn(e.response.data.description);
      } else if (e.response) {
        message.warn(e.response.status);
      }
    }
  };

  const updateProject = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${dataToUpdate.key}`,
        body
      });
      message.success('Votre projet a été modifié');
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (dataToUpdate) {
      form.setFieldsValue(dataToUpdate);
    }
  }, [dataToUpdate, visible]);

  const handleSubmit = async (values) => {
    if (purpose === 'create') await createProject(values);
    if (purpose === 'edit') await updateProject(values);
    setVisible(false);
  };

  const addOrUpdateButton = (
    <Col>
      <Row align="middle">
        <Button type="primary" onClick={showModal}>
          {t('buttons.create_project')}
        </Button>
      </Row>
      <Modal
        destroyOnClose
        visible={visible}
        title={t(`projects.form.title.${purpose}`)}
        centered
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...formItemLayout}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name={['name']}
            label={t('projects.form.project_name')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['code_project']}
            label={t('projects.form.code_project')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['customer']}
            label={t('projects.form.customer')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {purpose === 'create' && (
            <>
              <Divider />
              {fields.map((field) => generateFields('projects', field))}
              <Divider />
            </>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Row justify="center">
              <Button
                style={{ margin: '0 10px' }}
                type="link"
                danger
                onClick={handleCancel}
              >
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </Col>
  );

  return getRole || user.role === 'admins:ADMIN' ? (
    <SimpleResourceLandingLayout
      columns={columns}
      extraButtons={addOrUpdateButton}
      resourceName="projects"
      withSubRoutes
      extraQuery={getRole}
      populate="assignee"
      headers={headers}
      resourceModelName="Project"
      customActionColumn
      withUploadButton={false}
      forceRefresh={refresh}
    />
  ) : null;
};

export default Projects;
