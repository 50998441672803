import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';

const ShowUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=permission`
      });
      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          >
            {/* <Avatar size={64} src={user ? user.photo : ''}/> */}
            <Avatar
              size={64}
              src={user && user.photo ? user.photo : ``}
              style={{ marginBottom: 30, marginLeft: 16 }}
            />
          </Skeleton>
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent(user || {}, t)} translate />
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowUser;
