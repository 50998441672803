import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Progress, Tooltip, Modal, Row, Button, Statistic } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Federation from './ChartIndicators/Federation';
import useHandleResize from '../../utils/HandleResize';

/**
 * Component for rendering federation-related statistics.
 *
 * @component
 * @param {Object} project - Project data.
 * @param {function} openModal - Function to open a modal.
 * @returns {JSX.Element} JSX element containing the federation-related statistics.
 */
const FederationComponent = ({ project, openModal }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [variation, setVariation] = useState(0);
  const [federation, setFederation] = useState(0);
  const { width } = useHandleResize();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancelBizchart = () => {
    setVisible(false);
  };

  const getFederation = (data) => {
    const getFederationValue = (event, impact) =>
      event.federation * (impact || 1);

    const pushValues = (event, impact, federationArray, impactArray) => {
      if (event.federation !== undefined && event.federation !== null) {
        federationArray.push(getFederationValue(event, impact));
        impactArray.push(impact || 1);
      }
    };

    const getFederationMean = (version) => {
      const federationArray = [];
      const impactArray = [];
      version.deliverables_plan_com.forEach((event) => {
        if (event.repeat)
          event.duplicates.forEach((duplicate) => {
            pushValues(duplicate, event.impact, federationArray, impactArray);
          });
        else pushValues(event, event.impact, federationArray, impactArray);
      });
      return federationArray.length !== 0
        ? Math.round(
            federationArray.reduce((a, b) => a + b, 0) /
              impactArray.reduce((a, b) => a + b, 0)
          )
        : 0;
    };

    const lastVersion = data.plan_com[data.plan_com.length - 1];
    if (lastVersion?.deliverables_plan_com)
      setFederation(getFederationMean(lastVersion));

    const penultimateVersion = data.plan_com[data.plan_com.length - 2];
    if (penultimateVersion?.deliverables_plan_com)
      setVariation(getFederationMean(penultimateVersion));
  };

  useEffect(() => {
    if (project) getFederation(project);
  }, [project]);

  return (
    <>
      <Col
        xl={6}
        md={8}
        sm={9}
        xs={18}
        style={{
          height: '100%',
          margin: width > 1200 ? '0 24px' : '16px 24px 8px'
        }}
      >
        <Row style={{ marginBottom: '8px' }} align="middle">
          <Col>
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.membership`)}
            </span>
          </Col>
          <Col>
            <Tooltip title={t(`projects.details.update_federation`)}>
              <Button
                disabled={project?.status === 'close'}
                onClick={() =>
                  openModal(
                    project,
                    'membership',
                    null,
                    'createFederation',
                    'membership'
                  )
                }
                size="middle"
                icon={<EditOutlined />}
                type="link"
                className="light"
                style={{
                  padding: '0 8px',
                  marginLeft: 8,
                  height: 'fit-content'
                }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col
            span={12}
            style={{ textAlign: 'center' }}
            onClick={() => showModal('membership', project)}
          >
            <Tooltip
              title={t('projects.details.federation')}
              style={{ color: '#FFFFFF' }}
            >
              <Progress
                id="white-progress"
                percent={federation}
                strokeColor={{
                  '50%': '#ffff00',
                  '51%': '#29ea12'
                }}
                width={80}
                type="circle"
              />
            </Tooltip>
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            <Tooltip title={t('projects.details.fluctuation_federation')}>
              <Statistic
                style={{ marginTop: 30 }}
                id="white-statistic"
                precision={0}
                value={federation - variation}
                valueStyle={
                  federation - variation >= 0
                    ? { color: 'var(--addColor)', fontSize: '17px' }
                    : { color: '#ff0000', fontSize: '17px' }
                }
                suffix="%"
                prefix={
                  variation - federation > 0 ? (
                    <ArrowDownOutlined />
                  ) : (
                    <ArrowUpOutlined />
                  )
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Col>

      <Modal
        title={t(`projects.show.indicators.membership.title`)}
        visible={visible}
        footer={false}
        onCancel={handleCancelBizchart}
        className="federation-graph-modal"
      >
        <Federation
          data={project}
          visible={visible}
          purpose="membership"
          mean={federation}
        />
      </Modal>
    </>
  );
};

FederationComponent.propTypes = {
  project: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  openModal: PropTypes.func
};

FederationComponent.defaultProps = {
  project: null,
  openModal: null
};

export default FederationComponent;
