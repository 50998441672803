import React, { useEffect, useState, useRef } from 'react';
import { Row, Button, Tabs, Layout, Col, Divider, Modal, Spin } from 'antd';
import { LoadingOutlined, LineChartOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useProjectContext from '../../../contexts/ProjectContext';
import CreateItemContainer from './utilsProject/CreateItemContainer';
import CreateUpdateForm from './utilsProject/CreateUpdateForm';
import ShowIndicators from '../../home/ShowIndicators';
import ButtonManageStatus from './utilsProject/ButtonManageStatus';
import ManageFederation from './utilsProject/ManageFederation';
import PlanComForm from './utilsProject/PlanComForm';
import setIconStatus from '../../../utils/constants/setIconStatus';
import ManagePlanning from './utilsProject/ManagePlanning/ManagePlanning';
import MascotteRouge from '../../../assets/images/PersoRouge.png';
import MascotteOrange from '../../../assets/images/PersoOrange.png';
import MascotteVert from '../../../assets/images/PersoVert.png';
import ShowModalFilesByItem from './utilsProject/ShowModalFilesByItem';
import useHandleResize from '../../../utils/HandleResize';
import ModalConfirm from './utilsProject/ModalConfirm';

const { Content } = Layout;
const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ShowProject = ({ location }) => {
  const { id } = useParams();
  const {
    getProject,
    project,
    isLoading,
    itemKeysInit,
    checkRole,
    visibleRole,
    handleMascotteClick,
    title,
    setTitle,
    matriceFiles,
    purpose,
    setPurpose,
    visible,
    setVisible,
    tempValue,
    createResource,
    updateResource
  } = useProjectContext();
  const { t } = useTranslation();

  const [modalConfig, setModalConfig] = useState();
  const [index, setIndex] = useState();
  const [fieldsName, setFieldName] = useState();
  const [config, setConfig] = useState();
  const checkManagePlanning = ['planing', 'utility'];
  const { width } = useHandleResize();
  const modalRef = useRef();
  const checkField = ['event', 'note', 'lifeProject'];

  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];

  const handleCancel = () => {
    setVisible(false);
    setPurpose(null);
    getProject(id);
  };

  const openModal = (
    dataT,
    titleModal,
    indexItem,
    action,
    fields,
    configArg
  ) => {
    setModalConfig(dataT);
    setTitle(titleModal);
    setIndex(indexItem);
    setPurpose(action);
    setConfig(configArg);
    setFieldName(fields);
    setVisible(true);
  };

  const handleSubmit = (values, data, titleItem, field, funcCancel) => {
    if (purpose === 'createFederation') {
      const federationList = { federation: data.federation };
      federationList.federation.push(values.federation);
      createResource(federationList);
    } else if (project.status === 'V0' || project.status === 'V1') {
      if (purpose === 'create') {
        createResource(values, titleItem, null);
      }
      if (field === 'lifeProject') {
        updateResource(values, data, titleItem, null, funcCancel);
      }
      if (field === 'event' && purpose === 'event') {
        createResource(values, null, null);
      } else
        updateResource(
          values,
          data,
          titleItem,
          checkField.includes(field) ? null : field,
          funcCancel
        );
    } else if (purpose === 'create') {
      const fieldValue = Object.keys(tempValue)[0];
      if (Array.isArray(data[fieldValue])) {
        const { event, ...fieldsRest } = data;
        const result = {
          ...values,
          ...fieldsRest,
          [fieldValue]: data[fieldValue].map((e) => {
            if (e._id === tempValue[fieldValue]._id) {
              return { ...tempValue[fieldValue] };
            }
            return { ...e };
          })
        };
        if (tempValue[fieldValue]._id === undefined) {
          result[fieldValue].push({ ...tempValue[fieldValue] });
        }
        createResource(result, titleItem, data, null, funcCancel);
      } else {
        const result = { ...values, ...tempValue };
        createResource(result, titleItem, data, null, funcCancel);
      }
    } else
      modalRef.current.toggleModal(values, data, titleItem, field, funcCancel);
  };

  const generateForm = (
    purposeArg,
    titleArg,
    dataArg,
    funcItem,
    fields,
    configData,
    submitFunction
  ) => {
    if (titleArg === 'manage_planning' && fields !== 'lifeProject') {
      return (
        <ManagePlanning
          key={dataArg._id}
          dataItems={dataArg}
          title={titleArg}
          projectId={project?._id}
          handleCancel={funcItem}
          handleSubmit={submitFunction || handleSubmit}
          config={configData}
          purposeArg={purposeArg}
        />
      );
    }
    if (titleArg === 'plan_com') {
      return (
        <PlanComForm
          key={dataArg._id}
          dataItems={dataArg}
          title={titleArg}
          projectId={project?._id}
          handleCancel={funcItem}
          handleSubmit={submitFunction || handleSubmit}
          config={configData}
        />
      );
    }
    return (
      <CreateUpdateForm
        key={dataArg._id}
        purpose={purposeArg}
        resource="projects"
        title={titleArg}
        projectId={project?._id}
        handleCancel={funcItem}
        data={dataArg}
        config={configData}
        fieldsName={fields}
        handleSubmit={submitFunction || handleSubmit}
      />
    );
  };

  useEffect(() => {
    (async () => {
      await getProject(id);
    })();
  }, [getProject]);

  useEffect(() => {
    if (project) checkRole();
    if (location.state && location.item === 'plan_com') {
      const { state } = location;
      openModal(state.purpose, state.title, state.data, null, state.field);
    }
  }, [project]);

  return (
    <>
      {project ? (
        <>
          <div style={{ fontSize: '1.5em' }}>{project.code_project}</div>
          <Content
            style={{
              margin: '10px 0px 16px 0',
              padding: '10px',
              background: 'var(--clientColor)',
              borderRadius: '5px',
              color: 'var(--textColorSecondary)',
              boxShadow: '0 2px 6px rgb(0 0 0 / 30%)'
            }}
          >
            <Row align="middle">
              <Col span={2} style={{ marginLeft: 16, minWidth: 38 }}>
                {setIconStatus(null, project.status, false, 19)}
                <Divider type="vertical" />
              </Col>
              <Col sm={10} md={7} lg={4}>
                {project.name}
              </Col>
              <Col sm={10} md={7} lg={4}>
                {project.customer}
              </Col>
              <Col sm={10} md={7} lg={4}>
                <ButtonManageStatus
                  project={project}
                  getProject={() => getProject(id)}
                  checkRole={checkRole}
                />
              </Col>

              <Col
                style={{ marginLeft: 50, minWidth: 38, textAlign: 'end' }}
                sm={10}
                md={7}
                lg={8}
              >
                <Link
                  to={{
                    pathname: `/dashboard/${id}`
                  }}
                >
                  <Button type="text" icon={<LineChartOutlined />}>
                    Dashboard
                  </Button>
                </Link>
              </Col>
            </Row>
          </Content>
          <Content>
            <Row>
              <Col
                xl={20}
                sm={24}
                xs={24}
                style={{
                  margin: width < 1200 ? '0 12px 0 0' : 0,
                  padding: '15px',
                  background: 'var(--clientColor)',
                  borderRadius: '5px',
                  color: 'var(--textColorSecondary)',
                  boxShadow: '0 2px 6px rgb(0 0 0 / 30%)'
                }}
              >
                <ShowIndicators openModal={openModal} project={project} />
              </Col>
              <Col
                xl={4}
                sm={0}
                xs={0}
                style={{
                  display: width < 1200 ? 'none' : 'flex',
                  justifySelf: 'end'
                }}
              >
                <Button
                  onClick={() => handleMascotteClick(project._id)}
                  style={{
                    background: '#D3D3D3',
                    borderRadius: 5,
                    height: '100%',
                    width: '100%',
                    marginLeft: 16,
                    boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    alt="Mascotte de CitizenCan"
                    src={mascotteArray[project?.mascot_state]}
                    height="150px"
                  />
                </Button>
              </Col>
            </Row>
          </Content>
          <Tabs
            defaultActiveKey={location?.item || undefined}
            size="large"
            style={{
              margin: '16px 0'
            }}
            className="show-project-tabs"
          >
            {Object.entries(itemKeysInit).map(([key, value]) => {
              return (
                <TabPane
                  tab={
                    <>
                      <Row
                        justify="center"
                        style={{ marginBottom: 7, textAlign: 'center' }}
                      >
                        {value}
                      </Row>
                      <Row
                        justify="center"
                        style={{ fontSize: 12, textAlign: 'center' }}
                      >
                        {t(`projects.form.items.${key}.title`)}
                      </Row>
                    </>
                  }
                  key={key}
                  span={2}
                >
                  <CreateItemContainer
                    key={key}
                    data={
                      checkManagePlanning.includes(key)
                        ? project.manage_planning
                        : project[key]
                    }
                    title={key}
                    projectId={project._id}
                    getProject={() => getProject(id)}
                    openModal={openModal}
                    visibleRole={visibleRole}
                    generateForm={generateForm}
                    managePlanning={
                      checkManagePlanning.includes(key)
                        ? 'manage_planning'
                        : null
                    }
                    loading={isLoading}
                    actors={
                      project.actors[project.actors.length - 1]?.actors_list
                    }
                  />
                </TabPane>
              );
            })}
          </Tabs>
          {title && purpose ? (
            <Modal
              title={t(`projects.form.items.${title}.title`)}
              visible={visible}
              footer={false}
              destroyOnClose
              onCancel={handleCancel}
              width={1200}
            >
              {title === 'membership' ? (
                <ManageFederation
                  dataItems={modalConfig}
                  title={title}
                  projectId={project?._id}
                  handleCancel={handleCancel}
                  config={config}
                />
              ) : (
                <CreateUpdateForm
                  purpose={purpose}
                  resource="projects"
                  title={title}
                  handleCancel={handleCancel}
                  projectId={project?._id}
                  data={modalConfig}
                  config={config || null}
                  index={index}
                  fieldsName={fieldsName}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              )}
            </Modal>
          ) : null}
          <ShowModalFilesByItem matriceData={matriceFiles} />
          <ModalConfirm destroyOnClose openModal={openModal} ref={modalRef} />
        </>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </>
  );
};
ShowProject.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object])
};

ShowProject.defaultProps = {
  location: null
};

export default ShowProject;
