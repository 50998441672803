/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Button, Collapse, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import { EditIcon, CreateIcon } from '../../../../utils/constants/customIcon';
import useProjectContext from '../../../../contexts/ProjectContext';

const { Panel } = Collapse;

const UtilityDisplay = ({
  data,
  title,
  id,
  getProject,
  generateForm,
  actors,
  role
}) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataDeliverables, setDataDeliverables] = useState();

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        deliverables: { ...dataDeliverables }
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, deliverables) => {
    const { planning, ...rest } = deliverables;
    setVisible(idRender);
    setDataDeliverables({
      ...rest,
      planning: planning
        ? {
            ...planning,
            start_date: planning.start_date
              ? moment(planning.start_date)
              : undefined,
            due_date: moment(planning.due_date)
              ? moment(planning.due_date)
              : '',
            piloting_mark: planning.piloting_mark,
            gantt_mark: planning.gantt_mark,
            is_task: planning.is_task
          }
        : undefined
    });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  return (
    <>
      {data.deliverables
        .filter((deli) => deli.utility)
        .map((e) => {
          const { _id, description, utility } = e;
          // eslint-disable-next-line no-nested-ternary
          return visible === e._id && dataDeliverables ? (
            generateForm(
              'edit',
              'manage_planning',
              data,
              closeForm,
              'deliverables',
              config
            )
          ) : (
            <Collapse collapsible={false} key={_id}>
              <Panel
                showArrow={false}
                style={{ marginBottom: 16 }}
                extra={
                  role ? (
                    <Row justify="center">
                      <Button
                        disabled={project?.status === 'close'}
                        onClick={() => openForm(_id, e)}
                        size="small"
                        type="link"
                        style={{
                          color: 'var(--secondaryColor)',
                          top: 2
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <ButtonDeleteAll
                        purpose="matrice"
                        projectId={id}
                        title="manage_planning"
                        titleId={data._id}
                        matriceName="deliverables"
                        matriceId={_id}
                        handleCancel={closeForm}
                      />
                    </Row>
                  ) : null
                }
                header={
                  <Descriptions column={5} size="small">
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.description`
                      )}
                    >
                      {description}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.use_for`
                      )}
                    >
                      {`${utility?.use_for} -`}

                      {utility?.beneficiaries &&
                        actors.map(
                          (a) =>
                            a._id === utility?.beneficiaries &&
                            `${a?.group || ''} ${a?.first_name ||
                              ''} ${a?.last_name || ''}`
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.coefficient`
                      )}
                    >
                      {utility?.coefficient}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={t(`projects.form.items.utility.deliverables.why`)}
                    >
                      {utility?.why}
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </Collapse>
          );
        })}
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={800}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            'manage_planning',
            data,
            closeFormModal,
            'deliverables'
          )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => openFormModal()}
        >
          <CreateIcon />
          {t('projects.form.items.utility.deliverables.addUtility')}
        </Button>
      ) : null}
    </>
  );
};

UtilityDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  actors: PropTypes.oneOfType([PropTypes.array]),
  role: PropTypes.bool
};

UtilityDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  actors: null,
  getProject: null,
  role: false
};

export default UtilityDisplay;
