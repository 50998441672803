import React, { createContext, useContext, useState, useCallback } from 'react';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from './AuthContext';

const HomeContext = createContext({});

export const HomeContextProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);

  const getUserRole = () => {
    if (user.role === 'admins:ADMIN') {
      return 'admin';
    }
    if (user.role === 'commercials:COMMERCIAL') {
      return `created_by=${user._id}&populate=assignee,created_by`;
    }
    if (user.role === 'managers:PROJECT_MANAGER') {
      return `status=V&status=V0&status=V1&status=VX&assignee=${user._id}&populate=assignee,created_by&sort=-updated_at`;
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?${getUserRole()}`
      });
      setProjects(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateResource = async (body, id, dataItem, titleItem, field, func) => {
    const setUrl = {
      url: `/projects/${id}/${titleItem}${
        dataItem?._id ? `/${dataItem._id}` : ''
      }${field ? `/${field}` : ''}${
        body[field]?._id ? `/${body[field]?._id}` : ''
      }`
    };
    try {
      await dispatchAPI('PATCH', {
        url: setUrl.url,
        body: field ? body[field] : { ...dataItem, ...body }
      });
      func();
      getProjects();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <HomeContext.Provider
      value={{
        updateResource,
        getProjects,
        projects,
        loading,
        setLoading,
        setProjects
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default () => useContext(HomeContext);
