import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Geom, Axis, Label, Tooltip, Coord } from 'bizcharts';
import { Col, Row } from 'antd';

const Time = ({ data, purpose, width }) => {
  const [dataChart, setDataChart] = useState();

  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };
  const getData = () => {
    let chartData = [];
    if (
      (data.status === 'VX' || data.status === 'V1') &&
      data.manage_planning[data.manage_planning.length - 1] !== null
    ) {
      const origin = moment(data.manage_planning[2]?.end_project).diff(
        moment(data.manage_planning[2]?.start_project),
        'days'
      );
      const { manage_planning } = data;
      manage_planning.slice(2).forEach((e, index) => {
        const { event, start_project, end_project } = e;
        chartData.push({
          id: e._id,
          version: `V${index + 1} (${moment(event?.date).format(
            'DD-MM-YYYY'
          )})`,
          description: `Event "${event?.name}" du ${moment(event?.date).format(
            'DD-MM-YYYY'
          )}`,
          comment: event?.comment,
          variation:
            moment(end_project).diff(moment(start_project), 'days') - origin
        });
      });
    } else {
      chartData = undefined;
    }

    setDataChart(chartData);
  };
  useEffect(() => {
    if (data) getData();
  }, [purpose, data]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart renderer="canvas" width={width} height={400} data={dataChart}>
            <span className="main-title" style={styles.mainTitle}>
              Variation de la durée du projet selon les versions
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col style={{ marginBottom: 10 }} span={24}>
                  <span>
                    {`Item Planning V1 du ${moment(
                      data.manage_planning[2]?.event?.date
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    {`Début de projet: ${moment(
                      data.manage_planning[2]?.start_project
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    {`Fin de projet: ${moment(
                      data.manage_planning[2]?.end_project
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>
                <Col span={24}>
                  <span>
                    {`Durée ${moment(data.manage_planning[2]?.end_project).diff(
                      moment(data.manage_planning[2]?.start_project),
                      'days'
                    )} jours`}
                  </span>
                </Col>
              </Row>
            </span>
            <Axis name="variation" />
            <Axis name="version" />
            <Coord transpose={false} />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />

            <Geom
              tooltip={[
                'version*variation*description*comment',
                (version, variation, description, comment) => {
                  return {
                    name: `${version} : ${variation +
                      moment(data.manage_planning[2]?.end_project).diff(
                        moment(data.manage_planning[2]?.start_project),
                        'days'
                      )} jours`,
                    title: `${description}`,
                    value: `Commentaire: ${comment}`
                  };
                }
              ]}
              type="interval"
              position="version*variation"
              color={[
                'variation',
                (variation) => {
                  if (variation > 0) return '#E66F33';
                  return '#24BF4D';
                }
              ]}
            >
              <Label
                content={[
                  'variation',
                  (variation) => {
                    return `${variation} jours`;
                  }
                ]}
              />
            </Geom>
          </Chart>
        </div>
      ) : (
        <span>
          Oups, la version du projet doit être en V+ ou les versions de
          l&apos;item Planning doivent être superieur à V1
        </span>
      )}
    </>
  );
};

Time.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  width: PropTypes.number
};

Time.defaultProps = {
  data: null,
  purpose: null,
  width: null
};

export default Time;
