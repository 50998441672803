/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { getIndexByVersionSelected } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';

const federation = (t, project, filter) => {
  const actorsList = project.actors[project.actors.length - 1].actors_list;
  const getActor = (actorId) => {
    if (actorId) {
      const result = actorsList.find(
        (actor) => String(actor._id) === String(actorId)
      );
      return result
        ? `${result.group || ''} ${result.first_name ||
            ''} ${result.last_name || ''}`
        : '';
    }
    return '';
  };
  const checkDataAndReturnSorting = (data) => {
    return [
      filter.version === 'VX' ? 'V+' : filter.version,
      data.name,
      moment(data.start_date).format('DD-MM-YYYY'),
      moment(data.due_date).format('DD-MM-YYYY'),
      data.number_frequency && data.type_frequency
        ? `${data.number_frequency} tous les ${data.type_frequency} jours`
        : '',
      data.type,
      data.channel,
      data.impact,
      getActor(data.in_charge_of),
      getActor(data.transmitter),
      getActor(data.target),
      data.federation,
      data.goal_description,
      data.message
    ];
  };
  const workbook = new Workbook();
  try {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.plan_com`)}`);
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(true);
    const header = newHeader.plan_com.map(({ label }) => {
      return t(`projects.form.${label}`);
    });

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    let row;
    let filterbeforeSetData =
      project.plan_com[
        getIndexByVersionSelected(filter.version, project.plan_com)
      ] !== null
        ? project.plan_com[
            getIndexByVersionSelected(filter.version, project.plan_com)
          ].deliverables_plan_com
        : [];
    if (filter.dates) {
      filterbeforeSetData = filterbeforeSetData.filter(
        (el) =>
          moment(el.start_date) >= moment(filter.dates[0]) &&
          moment(el.start_date) <= moment(filter.dates[1])
      );
    }
    if (filter.actor) {
      filterbeforeSetData = filterbeforeSetData.filter(
        (el) => el.transmitter.toString() === filter.actor.toString()
      );
    }
    filterbeforeSetData.forEach((planC) => {
      row = checkDataAndReturnSorting(planC);
      if (row) worksheet.addRow(row);
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default federation;
