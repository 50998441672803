import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  EyeOutlined,
  WarningOutlined,
  DeleteOutlined,
  UndoOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, message } from 'antd';
import useColumns from '../Projects/Projects/Columns';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;

const ShowHistory = () => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const activeProjectFromHistory = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${id}`,
        body: { status: 'VX' }
      });
      setForceRefresh(!forceRefresh);
      history.push(`/projects/projects/show/${id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteProject = async (id) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('DELETE', {
        url: `/projects/${id}`
      });
      message.success('Votre projet a été supprimé');
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Popconfirm
            title={t('datatable.column.action.active_project.title')}
            okText={t('datatable.column.action.active_project.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.active_project.cancel')}
            onConfirm={() => activeProjectFromHistory(record.key)}
            icon={<WarningOutlined />}
          >
            <UndoOutlined
              style={{ color: 'var(--secondaryColor)', fontSize: iconSize }}
            />
          </Popconfirm>

          <Divider type="vertical" />
          <Link
            to={{
              pathname: `/projects/projects/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {record.status !== 'V0' || user.type !== 'COMMERCIAL' ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteProject(record.key)}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </div>
      )
    }
  ];
  const columns = useColumns(actionColumn);
  const [getRole, setGetRole] = useState();

  const getUserRole = () => {
    if (user.role === 'admins:ADMIN') {
      setGetRole('admin');
    }

    if (user.role === 'commercials:COMMERCIAL') {
      setGetRole(`created_by=${user._id}`);
    }

    if (user.role === 'managers:PROJECT_MANAGER') {
      setGetRole(`status=close&assignee=${user._id}`);
    }
  };

  useEffect(() => {
    getUserRole();
  }, [getRole]);

  const headers = [
    {
      label: 'libellé',
      key: 'name'
    },
    {
      label: 'assignee_to_first_name',
      key: 'assignee.first_name'
    },
    {
      label: 'assignee_to_last_name',
      key: 'assignee.last_name'
    }
  ];

  return getRole || user.role === 'admins:ADMIN' ? (
    <SimpleResourceLandingLayout
      columns={columns}
      forceRefresh
      resourceName="projects"
      exportUrl="projects/projects"
      extraQuery={getRole}
      populate="assignee"
      headers={headers}
      withUploadButton={false}
      customActionColumn
      resourceModelName="Project"
    />
  ) : null;
};

export default ShowHistory;
