import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal as AntdModal, Row, Col, Button, Spin } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import useDashboardContext from '../../contexts/DashboardContext';

const ModalConfirm = forwardRef(({ openModal, ...rest }, ref) => {
  const {
    purpose,
    createResource,
    updateResource,
    setTempValue
  } = useDashboardContext();
  const [on, setOn] = useState(false);
  const [isSpinning, setIsSpinning] = useState(true);
  const [dataToCheckKindOfConfig, setDataToCheckFindConfig] = useState();
  const { t } = useTranslation();
  const checkField = ['event', 'note', 'lifeProject'];
  const toggleModal = (
    values,
    idProject,
    data,
    titleItem,
    field,
    funcCancel
  ) => {
    setDataToCheckFindConfig({
      values,
      idProject,
      data,
      titleItem,
      field,
      funcCancel
    });
    if (dataToCheckKindOfConfig) setIsSpinning(false);

    setOn(!on);
  };
  useImperativeHandle(ref, () => ({
    toggleModal
  }));
  return (
    <AntdModal
      {...rest}
      centered
      visible={on}
      onOk={() => {
        toggleModal();
      }}
      onCancel={toggleModal}
      footer={null}
    >
      {dataToCheckKindOfConfig ? (
        <Row justify="center" style={{ margin: -20 }}>
          <Col span={20} style={{ margin: 10, textAlign: 'center' }}>
            <ExclamationCircleOutlined
              style={{ color: '#008cb9', fontSize: 35, margin: 20 }}
            />
            <p style={{ fontSize: 20, marign: 15 }}>
              {t('projects.alert.what_action_for')}
            </p>
          </Col>
          <Col span={24} style={{ margin: 10 }}>
            <Row align="middle" justify="space-around">
              <Col style={{ textAlign: 'center' }} span={6}>
                <Button
                  style={{ color: '#24bf4d' }}
                  onClick={() => {
                    if (
                      checkField.includes(dataToCheckKindOfConfig.field) ||
                      purpose === 'event'
                    ) {
                      createResource(
                        dataToCheckKindOfConfig.values,
                        dataToCheckKindOfConfig.titleItem,
                        dataToCheckKindOfConfig.data,
                        null,
                        dataToCheckKindOfConfig.funcCancel
                      );
                    } else {
                      dataToCheckKindOfConfig.funcCancel();
                      setTempValue(dataToCheckKindOfConfig.values);
                      openModal(dataToCheckKindOfConfig);
                    }
                    toggleModal();
                  }}
                >
                  {t('projects.alert.create_version')}
                </Button>
              </Col>
              <Col style={{ textAlign: 'center' }} span={6}>
                <Button
                  disabled={!dataToCheckKindOfConfig}
                  onClick={async () => {
                    if (
                      checkField.includes(dataToCheckKindOfConfig.field) ||
                      purpose === 'event'
                    ) {
                      await createResource(
                        dataToCheckKindOfConfig.values,
                        dataToCheckKindOfConfig.titleItem,
                        dataToCheckKindOfConfig.data,
                        dataToCheckKindOfConfig.data._id,
                        dataToCheckKindOfConfig.funcCancel
                      );
                    } else
                      await updateResource(
                        dataToCheckKindOfConfig.values,
                        dataToCheckKindOfConfig.idProject,
                        dataToCheckKindOfConfig.data,
                        dataToCheckKindOfConfig.titleItem,
                        dataToCheckKindOfConfig.field,
                        dataToCheckKindOfConfig.funcCancel
                      );
                    toggleModal();
                  }}
                  type="primary"
                >
                  {t('projects.alert.in_current_version')}
                </Button>
              </Col>
              <Col style={{ textAlign: 'end', marginTop: 50 }} span={24}>
                <Button
                  type="link"
                  danger
                  icon={<CloseOutlined />}
                  onClick={toggleModal}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Spin spinning={isSpinning} />
      )}
    </AntdModal>
  );
});

ModalConfirm.propTypes = {
  openModal: PropTypes.func.isRequired
};

ModalConfirm.defaultProps = {};
export default ModalConfirm;
