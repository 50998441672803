import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Col,
  Row,
  Layout,
  Card,
  Skeleton,
  Divider,
  Button,
  Modal,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Actions from './ActionsComponent';
import IndicatorsDashboard from './IndicatorsDashboard';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import MascotteRouge from '../../assets/images/PersoRouge.png';
import MascotteOrange from '../../assets/images/PersoOrange.png';
import MascotteVert from '../../assets/images/PersoVert.png';
import useDashboardContext from '../../contexts/DashboardContext';
import useHandleResize from '../../utils/HandleResize';
import ManagePlanning from '../Projects/Projects/utilsProject/ManagePlanning/ManagePlanning';
import MemoComponent from './MemoComponent';
import RiskMatrixChart from '../../components/Widgets/RiskMatrixChart';
import EventHeadband from './EventHeadband';

const DashboardTab = ({ data, indicators, openModal, getProject }) => {
  const { t } = useTranslation();
  const { getCloseEvent, eventClose } = useDashboardContext();
  const { Content } = Layout;
  const { dispatchAPI } = useAuthContext();
  const [dataChart, setDataChart] = useState();
  const { message: errorMessage } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const { getProjects, updateResource } = useDashboardContext();
  const [isLoading, setIsLoading] = useState(false);
  const [visibleSelectEventCloseBefore, setVisibleEventCloseBefore] = useState(
    false
  );
  const [visibleSelectEventCloseAfter, setVisibleEventCloseAfter] = useState(
    false
  );
  const [dataToModal, setDataToModal] = useState();
  const configNote = {
    onGetResource: {
      setFields: (dataConfig) => ({
        note: dataConfig.note
      })
    }
  };

  const config = {
    onGetResource: {
      // eslint-disable-next-line no-shadow
      setFields: (dataT) => ({
        deliverables: {
          ...dataT,
          planning: dataT.planning
            ? {
                ...dataT.planning,
                start_date: dataT.planning.start_date
                  ? moment(dataT.planning?.start_date)
                  : undefined,
                due_date: dataT.planning.due_date
                  ? moment(dataT.planning?.due_date)
                  : undefined
              }
            : undefined
        }
      })
    }
  };
  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];
  const { width } = useHandleResize();
  const isReductScreen = width < 1301;

  const IndicatorWrapper = styled(Content)`
    padding: 15px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: var(--textColorSecondary);
  `;

  const handleMascotteClick = async () => {
    const mascot_state =
      data?.mascot_state === 2 ? 0 : parseInt(data?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${data?._id}`,
        body: { mascot_state }
      });
      await getProject();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getDataChart = () => {
    if (data.risks[data.risks.length - 1] !== null) {
      const itemRisks = [];
      data.risks[data.risks.length - 1].risk_list.forEach((e) => {
        const { criticality } = e;
        itemRisks.push({
          item: e.type_of_risk ? e.type_of_risk.toString() : 'N/A',
          id: e._id,
          type: 'Risques',
          value: Math.round(criticality)
        });
      });
      setDataChart(itemRisks);
    }

    return true;
  };
  useEffect(() => {
    if (data && data._id) {
      getCloseEvent(data._id);
      getDataChart();
    }
  }, [data]);

  const handleSubmitPlanning = (values) => {
    setIsLoading(true);
    updateResource(
      values,
      data._id,
      data.manage_planning[data.manage_planning.length - 1],
      'manage_planning',
      'deliverables',
      getProjects
    );
    setVisible(false);
    setIsLoading(false);
  };
  const getDataChartEvent = (event) => {
    if (event && event.data && event.data._origin) {
      const result = data.manage_planning[
        data.manage_planning.length - 1
      ].deliverables.filter((ele) => ele._id === event.data._origin.id)[0];
      setDataToModal(result);
      setVisible(true);
    }
  };

  useEffect(() => {}, [visible]);

  return data ? (
    <>
      <Row>
        <Col span={isReductScreen ? 24 : 20}>
          <IndicatorWrapper>{indicators}</IndicatorWrapper>
        </Col>
        <Col
          span={isReductScreen ? 0 : 4}
          style={{
            display: isReductScreen ? 'none' : 'flex',
            justifySelf: 'end'
          }}
        >
          <Button
            onClick={() => handleMascotteClick()}
            style={{
              background: '#D3D3D3',
              borderRadius: 5,
              height: '100%',
              width: '100%',
              marginLeft: 16,
              boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt="Mascotte de CitizenCan"
              src={mascotteArray[data?.mascot_state]}
              height="150px"
            />
          </Button>
        </Col>
      </Row>
      <EventHeadband
        t={t}
        eventClose={eventClose}
        getProjects={getProjects}
        updateResource={updateResource}
        visibleSelectEventCloseBefore={visibleSelectEventCloseBefore}
        setVisibleEventCloseBefore={setVisibleEventCloseBefore}
        visibleSelectEventCloseAfter={visibleSelectEventCloseAfter}
        setVisibleEventCloseAfter={setVisibleEventCloseAfter}
        configNote={configNote}
        data={data}
        openModal={openModal}
      />
      <Row justify="space-between" gutter={24}>
        <Col xl={12} sm={24} xs={24}>
          <Actions
            id={data._id}
            status={data.status}
            title="action_done"
            purpose="Finalisé"
            data={data.manage_planning[data.manage_planning.length - 1] || null}
            actors={data.actors[data.actors.length - 1]?.actors_list || []}
            version={data.manage_planning.length}
          />
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <Actions
            id={data._id}
            status={data.status}
            title="action_to_do"
            purpose="Planifié"
            data={data.manage_planning[data.manage_planning.length - 1] || null}
            actors={data.actors[data.actors.length - 1]?.actors_list || []}
            version={data.manage_planning.length}
          />
        </Col>
      </Row>
      <Col span={24}>
        <MemoComponent
          data={data.memos}
          idProject={data._id}
          getProject={getProject}
        />
      </Col>
      <Spin spinning={isLoading}>
        <Row justify="space-between">
          <Col xxl={17} xl={16} lg={20} md={24} sm={24} xs={24}>
            <IndicatorsDashboard
              getDataChartEvent={getDataChartEvent}
              data={data}
            />
            <Modal
              closable
              width={1000}
              destroyOnClose
              visible={visible}
              onOk={() => {
                setDataToModal();
                setVisible(false);
              }}
              onCancel={() => {
                setDataToModal();
                setVisible(false);
              }}
              footer={false}
            >
              {dataToModal && (
                <ManagePlanning
                  purposeArg="edit"
                  dataItems={dataToModal}
                  projectId={data._id}
                  config={config}
                  handleCancel={() => {
                    setVisible(false);
                  }}
                  handleSubmit={handleSubmitPlanning}
                />
              )}
            </Modal>
          </Col>
          <Col style={{ textAlign: 'center' }} xxl={6} xl={12} sm={24} xs={24}>
            <Card
              style={{ width: '100%', minHeight: 400, margin: '16px 0 24px' }}
              title={t('projects.risk_matrix')}
            >
              <Divider />
              {dataChart && dataChart.length > 0 ? (
                <Row justify="center">
                  <RiskMatrixChart dataChart={dataChart} />
                </Row>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  ) : (
    <Skeleton />
  );
};

DashboardTab.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  indicators: PropTypes.element,
  openModal: PropTypes.func,
  getProject: PropTypes.func
};

DashboardTab.defaultProps = {
  data: null,
  indicators: null,
  openModal: null,
  getProject: null
};

export default DashboardTab;
