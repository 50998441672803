import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Spin,
  Row,
  Col,
  Input,
  Divider,
  Radio,
  InputNumber
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { SwatchesPicker } from 'react-color';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import usePlanComFields from '../fieldsItems/planComFields';
import AddEventToFederation from './PlanComFederationUtils/AddEventToFederation';

const ManagePlanning = ({
  dataItems,
  projectId,
  config,
  handleCancel,
  handleSubmit
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldsLoading, setIsFieldsLoading] = useState();
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState();
  const [actors, setActors] = useState();
  const [ifDuplicate, setIfDuplicate] = useState();
  const modalAddEventToDuplicate = useRef();
  const [visibleChromeColor, setVisibleChromeColor] = useState(false);
  const [colorGantt, setColorGantt] = useState('#000');
  const {
    planComFieldsHeader,
    planComFields2,
    planComFields3,
    planComFields4,
    planComFields5
  } = usePlanComFields(
    projectId,
    enums,
    actors,
    isFieldsLoading,
    setVisibleChromeColor,
    visibleChromeColor,
    colorGantt,
    ifDuplicate
  );

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getActors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${projectId}?fields=actors.actors_list`
      });
      setActors(data.actors);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    setIsLoading(true);
    await getEnums();
    await getActors();
    setIsFieldsLoading(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const setValues = () => {
    form.setFieldsValue(
      config.onGetResource && config.onGetResource.setFields
        ? config.onGetResource.setFields(dataItems)
        : dataItems
    );
  };

  useEffect(() => {
    if (config !== null && form) setValues();
    const duplicate = config.onGetResource.setFields(dataItems);
    setIfDuplicate(duplicate?.deliverables_plan_com?.duplicate);
  }, []);

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });

  const handleChange = (_, values) => {
    const { number_frequency, type_frequency } = values;
    if (number_frequency && type_frequency) {
      form.setFieldsValue({
        deliverables_plan_com: { frequency: number_frequency * type_frequency }
      });
    }
  };

  const onChangeColor = (value) => {
    form.setFieldsValue({ deliverables_plan_com: { color: value.hex } });
    setVisibleChromeColor(!visibleChromeColor);
    setColorGantt(value.hex);
  };
  return (
    <>
      <Spin spinning={isLoading} />
      <Col span={24}>
        <Form
          style={{ margin: 50 }}
          name="plan_com"
          layout="vertical"
          onValuesChange={handleChange}
          onFinish={(values) => {
            setIsLoading(true);
            handleSubmit(
              values,
              dataItems,
              'plan_com',
              'deliverables_plan_com',
              handleCancel
            );
            setIfDuplicate();
          }}
          form={form}
        >
          <Form.Item noStyle hidden name={['deliverables_plan_com', '_id']}>
            <Input />
          </Form.Item>
          <Row justify="space-between">
            {planComFieldsHeader.map((e, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={`federation0${index}`} span={6}>
                {generateFields('projects', e)}
              </Col>
            ))}
            {!ifDuplicate || ifDuplicate.length === 0 ? (
              <Col style={{ marginTop: -50 }} offset={8} span={14}>
                <Row justify="space-between">
                  <Col span={10}>
                    <Row>
                      <Form.Item
                        rules={[{ required: true }]}
                        label={t(
                          'projects.form.deliverables_plan_com.repeat_text'
                        )}
                        name={['deliverables_plan_com', 'number_frequency']}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </Row>
                  </Col>

                  <Col span={14}>
                    <Row>
                      <Col span={10}>
                        <span>
                          {t('projects.form.deliverables_plan_com.les')}
                        </span>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          rules={[{ required: true }]}
                          name={['deliverables_plan_com', 'type_frequency']}
                        >
                          <Radio.Group>
                            <Row>
                              <Col span={24}>
                                <Radio style={{ fontWeight: 'bold' }} value={1}>
                                  {t('projects.form.deliverables_plan_com.day')}
                                </Radio>
                              </Col>
                              <Col span={24}>
                                <Radio style={{ fontWeight: 'bold' }} value={7}>
                                  {t(
                                    'projects.form.deliverables_plan_com.week'
                                  )}
                                </Radio>
                              </Col>
                              <Col span={24}>
                                <Radio
                                  style={{ fontWeight: 'bold' }}
                                  value={30}
                                >
                                  {t(
                                    'projects.form.deliverables_plan_com.month'
                                  )}
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Form.Item
                    noStyle
                    hidden
                    name={['deliverables_plan_com', 'frequency']}
                  >
                    <Input />
                  </Form.Item>
                </Row>
              </Col>
            ) : (
              <Col span={4}>
                <Row>
                  <span style={{ fontSize: 13, color: 'red' }}>
                    {t(
                      'projects.form.deliverables_plan_com.duplicate.duplicate_info'
                    )}
                  </span>
                  <Button
                    onClick={() =>
                      modalAddEventToDuplicate.current.toggleModal(
                        config.onGetResource.setFields(dataItems),
                        projectId
                      )
                    }
                  >
                    Ajouter un événement
                  </Button>
                </Row>

                <AddEventToFederation
                  destroyOnClose
                  ref={modalAddEventToDuplicate}
                />
              </Col>
            )}
          </Row>

          <Divider />
          <Row justify="space-around">
            {planComFields2.map((e) => generateFields('projects', e))}
          </Row>
          {planComFields3.map((e) => generateFields('projects', e))}
          <Row justify="space-around">
            {planComFields4.map((e, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={`federation1${index}`} span={10}>
                {generateFields('projects', e)}
              </Col>
            ))}
          </Row>
          <Row justify="space-between">
            {planComFields5.map((e, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={`federation2${index}`} span={4}>
                {generateFields('projects', e)}
              </Col>
            ))}
            <Col span={4}>
              {visibleChromeColor && (
                <div>
                  <SwatchesPicker onChange={(value) => onChangeColor(value)} />
                </div>
              )}
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 0 }}>
            <Row
              justify="end"
              wrap={false}
              style={{
                position: 'relative'
              }}
            >
              <Col>
                <Button
                  style={{ margin: '0 6px' }}
                  type="link"
                  danger
                  onClick={() => {
                    form.resetFields();
                    setIfDuplicate();
                    handleCancel();
                  }}
                >
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                  </span>
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="add" htmlType="submit">
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.save')} `}
                  </span>
                  <CheckOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

ManagePlanning.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func
};
ManagePlanning.defaultProps = {
  dataItems: null,
  config: null,
  handleCancel: null,
  handleSubmit: null
};

export default ManagePlanning;
