import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Input, InputNumber, Tooltip, Divider, Button } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const useActorFields = (enums, loading) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };
  const onChangeGroupValue = (event) => {
    if (event.target.value || event.target.value !== '') setIsRequired(true);
    else setIsRequired(false);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };

  const actorFields = [
    {
      name: ['actors_list', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['actors_list', 'group'],
      rules: [{ required: false }],
      extra: `${t('projects.form.actors_list.group_translation')}`,
      input: <Input onChange={onChangeGroupValue} row={4} />
    },
    {
      name: ['actors_list', 'effective'],
      rules: [{ required: isRequired }],
      input: <InputNumber />
    },
    {
      name: ['actors_list', 'first_name'],
      rules: [{ required: false }],
      input: <Input row={4} />
    },
    {
      name: ['actors_list', 'last_name'],
      rules: [{ required: false }],
      input: <Input row={4} />
    },
    {
      name: ['actors_list', 'code'],
      rules: [{ required: false }],
      input: <Input row={2} />
    },
    {
      name: ['actors_list', 'role'],
      input: enums ? (
        <Select
          showSearch
          loading={loading}
          style={{ width: 240 }}
          placeholder="Choisir un rôle"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()}>
                  <PlusOutlined />
                  {t(`projects.form.actors_list.title`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.roles_types.map((ele) => (
            <Option key={ele}>{ele}</Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['actors_list', 'gain'],
      input: (
        <Input
          row={5}
          suffix={
            <Tooltip title="Bénéfice du projet pour le groupe">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      )
    },
    {
      name: ['actors_list', 'coefficient'],
      input: (
        <Input
          type="number"
          min={0}
          max={100}
          suffix={
            <Tooltip title="Utilité du projet pour le groupe">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      )
    }
  ];

  return { actorFields };
};

export default useActorFields;
