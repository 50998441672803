import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Select, InputNumber, Input, Divider, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const useCostFields = (enums, loading) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);

  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };
  const costFields = [
    {
      name: ['costs', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['costs', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['costs', 'type'],
      input: enums ? (
        <Select
          showSearch
          loading={loading}
          style={{ width: 240 }}
          placeholder="Choisir un type"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()}>
                  <PlusOutlined />
                  {t(`projects.form.costs.type`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.cost_types.map((ele) => (
            <Option key={ele}>{ele}</Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['costs', 'charge'],
      rules: [{ required: true }],
      input: <InputNumber defaultValue={0} style={{ width: 150 }} min={0} />
    },
    {
      name: ['costs', 'cost_value'],
      rules: [{ required: true }],
      label: 'cost_in_euro',
      input: <InputNumber defaultValue={0} style={{ width: 200 }} min={0} />
    },
    {
      name: ['costs', 'quote'],
      extra: `${t('projects.form.costs.quote_translation')}`,
      input: <Input row={5} />
    },
    {
      name: ['costs', 'description'],
      input: <TextArea row={4} />
    }
  ];
  return { costFields };
};

export default useCostFields;
