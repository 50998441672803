/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Card } from 'antd';
import { useTranslation } from 'react-i18next';

const CostDisplay = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data.costs.map((e) => {
        const { _id, files, type, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            <Card>
              <Descriptions key={_id} column={4} title={type} size="small">
                {Object.entries(rest).map(([key, value]) => {
                  return (
                    <Descriptions.Item
                      key={key}
                      label={t(`projects.form.items.cost.${key}`)}
                    >
                      {value}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
};

CostDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

CostDisplay.defaultProps = {
  data: null
};

export default CostDisplay;
