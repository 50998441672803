import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from './AuthContext';
import {
  WhatIcon,
  WhyIcon,
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  UtilityIcon,
  PlanComIcon,
  RiskIcon
} from '../utils/constants/customIcon';

const DashboardContext = createContext({});

export const DashboardContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [visibleRole, setVisibleRole] = useState(false);
  const [eventClose, setEventClose] = useState();
  const [purpose, setPurpose] = useState();
  const [visible, setVisible] = useState(false);
  const [tempValue, setTempValue] = useState();

  const itemKeysInit = {
    what: <WhatIcon />,
    why: <WhyIcon />,
    prior: <PriorIcon />,
    actors: <ActorsIcon />,
    context: <ContextIcon />,
    cost: <CostIcon />,
    risks: <RiskIcon />,
    planing: <PlanningIcon />,
    utility: <UtilityIcon />,
    plan_com: <PlanComIcon />
  };

  const getUserRole = () => {
    if (user.role === 'admins:ADMIN') {
      return 'admin';
    }
    if (user.role === 'commercials:COMMERCIAL') {
      return `created_by=${user._id}`;
    }
    if (user.role === 'managers:PROJECT_MANAGER') {
      return `status=V&status=V0&status=V1&status=VX&assignee=${user._id}&populate=assignee,created_by&sort=-updated_at`;
    }
    return `created_by=${user._id}`;
  };

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?${getUserRole()}`
      });
      setProjects(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setLoading(false);
  }, []);

  const checkRole = () => {
    switch (user?.role) {
      case 'commercials:COMMERCIAL':
        if (project.status !== 'V0' && project.status !== 'V1')
          setVisibleRole(true);
        else setVisibleRole(false);
        break;
      case 'managers:PROJECT_MANAGER':
        setVisibleRole(true);
        break;
      case 'admins:ADMIN':
        setVisibleRole(true);
        break;
      default:
        setVisibleRole(false);
    }
  };
  const getProject = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?populate=assignee, created_by`
      });
      setProject(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  const handleMascotteClick = async (id) => {
    setIsLoading(true);
    const mascot_state =
      project?.mascot_state === 2 ? 0 : parseInt(project?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project?._id}`,
        body: { mascot_state }
      });
      await getProject(id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCloseEvent = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/manage-projects/get-close-event/${id}`
      });
      setEventClose(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const updateResource = async (body, id, dataItem, titleItem, field, func) => {
    const setUrl = {
      url: `/projects/${id}${titleItem ? `/${titleItem}` : ''}${
        dataItem?._id ? `/${dataItem._id}` : ''
      }${field ? `/${field}` : ''}${
        body[field]?._id ? `/${body[field]?._id}` : ''
      }`
    };
    try {
      await dispatchAPI('PATCH', {
        url: setUrl.url,
        body: field ? body[field] : { ...dataItem, ...body }
      });
      func();
      getProjects();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setPurpose(null);
    getProjects();
  };
  const createResource = async (
    body,
    idProject,
    titleItem,
    idIt,
    funcCancel
  ) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${idProject}${titleItem ? `/${titleItem}` : ''}${
          idIt ? `/${idIt}` : ''
        }`,
        body: { ...body }
      });
      setIsLoading(false);
      setPurpose(null);
      setTempValue(null);
      if (funcCancel) funcCancel();
      handleCancel();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        getProject,
        projects,
        setProjects,
        loading,
        setLoading,
        project,
        isLoading,
        setIsLoading,
        itemKeysInit,
        checkRole,
        visibleRole,
        setVisibleRole,
        handleMascotteClick,
        getProjects,
        getCloseEvent,
        eventClose,
        setEventClose,
        t,
        updateResource,
        createResource,
        purpose,
        setPurpose,
        visible,
        setVisible,
        tempValue,
        setTempValue
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(DashboardContext);
