import React, { useCallback, useEffect, useState } from 'react';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import AccountingTable from './AccountingTable';

const AccountingSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [settings, setSettings] = useState([]);

  const fetchSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/settings' });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const updateSetting = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/settings/${id}`, body });
      await fetchSettings();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = useCallback(async () => {
    await fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    getSettings();
  }, []);

  return <AccountingTable settings={settings} editSetting={updateSetting} />;
};

export default AccountingSettings;
