import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal as AntdModal,
  Row,
  Col,
  Button,
  Spin,
  Form,
  DatePicker,
  Input
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import useProjectContext from '../../../../../contexts/ProjectContext';

const AddEventToFederation = forwardRef((props, ref) => {
  const { adddEventToDuplicateFederation, getProject } = useProjectContext();
  const [form] = Form.useForm();
  const [on, setOn] = useState(false);
  const [isSpinning, setIsSpinning] = useState(true);
  const [dataToCheckKindOfConfig, setDataToCheckFindConfig] = useState();
  const { t } = useTranslation();
  const toggleModal = (values, id_project) => {
    setDataToCheckFindConfig({
      values,
      id_project
    });
    if (dataToCheckKindOfConfig) setIsSpinning(false);

    setOn(!on);
  };
  useImperativeHandle(ref, () => ({
    toggleModal
  }));
  const handleSubmit = (values) => {
    const result = {
      ...values,
      task: dataToCheckKindOfConfig?.values?.deliverables_plan_com?.name,
      due_date: moment(values.start_date).add(1, 'days')
    };
    adddEventToDuplicateFederation(
      result,
      dataToCheckKindOfConfig.id_project,
      dataToCheckKindOfConfig?.values?._id,
      `${dataToCheckKindOfConfig?.values?.deliverables_plan_com._id}/duplicate`
    );
    getProject();
    toggleModal();
  };

  return (
    <AntdModal
      {...props}
      centered
      title={`${t(
        `projects.form.deliverables_plan_com.duplicate.title_add`
      )} " ${dataToCheckKindOfConfig?.values?.deliverables_plan_com?.name} "`}
      visible={on}
      onOk={() => {
        toggleModal();
      }}
      onCancel={toggleModal}
      footer={null}
    >
      {dataToCheckKindOfConfig ? (
        <Row justify="space-around" style={{}}>
          <Col span={24} style={{ margin: 10, textAlign: 'center' }}>
            <Row justify="start">
              <ExclamationCircleOutlined
                style={{ color: '#008cb9', fontSize: 25, marginRight: 20 }}
              />
              <p style={{ fontSize: 15 }}>
                {`${t(
                  'projects.form.deliverables_plan_com.duplicate.number_of_event'
                )} ${
                  dataToCheckKindOfConfig?.values?.deliverables_plan_com
                    ?.duplicate.length
                }`}
              </p>
            </Row>
          </Col>
          <Col span={24} style={{ margin: 20 }}>
            <Form
              style={{ margin: 50 }}
              layout="vertical"
              onFinish={handleSubmit}
              form={form}
            >
              <Form.Item
                label={t('projects.form.deliverables_plan_com.start_date')}
                name={['start_date']}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label={t('projects.form.deliverables_plan_com.description')}
                name={['description']}
              >
                <Input />
              </Form.Item>
              <Row align="middle" justify="space-around">
                <Col style={{ textAlign: 'center' }} span={6}>
                  <Button
                    type="danger"
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </Col>
                <Col style={{ textAlign: 'center' }} span={6}>
                  <Button
                    disabled={!dataToCheckKindOfConfig}
                    type="add"
                    htmlType="submit"
                  >
                    {t('buttons.add')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Spin spinning={isSpinning} />
      )}
    </AntdModal>
  );
});

AddEventToFederation.propTypes = {
  openModal: PropTypes.func.isRequired
};

AddEventToFederation.defaultProps = {};
export default AddEventToFederation;
