/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tag, Collapse, Divider, Tabs, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import useHandleResize from '../../../../utils/HandleResize';
import Gantt from '../../../../components/Widgets/Gantt';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const PlanComDisplay = ({ data, actors }) => {
  const { t } = useTranslation();
  const { width } = useHandleResize();

  const [ganttData, setGanttData] = useState();

  const formatDataToChart = () => {
    let chartData = [];
    if (data.deliverables_plan_com !== undefined) {
      data.deliverables_plan_com.forEach((e) => {
        const {
          start_date,
          due_date,
          type_frequency,
          number_frequency,
          color
        } = e;
        if (
          (start_date, due_date !== null) &&
          type_frequency &&
          number_frequency &&
          e.duplicate.length
        ) {
          const result = e.duplicate.map((event) => ({
            description: e.name,
            ...event,
            task: `${e._id + e.name}`,
            start_date:
              start_date !== null &&
              moment(event.start_date).format('YYYY-MM-DD'),
            due_date: moment(event.due_date).format('YYYY-MM-DD'),
            color: color || '#000000',
            idEvent: e._id
          }));
          chartData = [...chartData, ...result];
        } else {
          const result = [
            {
              description: e.name,
              ...e,
              task: `${e._id + e.name}`,
              start_date:
                start_date !== null &&
                moment(e.start_date).format('YYYY-MM-DD'),
              due_date: moment(e.due_date).format('YYYY-MM-DD'),
              color: color || '#000000',
              idEvent: e._id
            }
          ];
          chartData = [...chartData, ...result];
        }
      });
    } else {
      chartData = undefined;
    }
    setGanttData(chartData);
  };

  useEffect(() => {
    if (data) {
      formatDataToChart();
    }
  }, [data]);
  return (
    <>
      <Tabs>
        <TabPane
          key={t('projects.form.items.plan_com.event')}
          tab={t('projects.form.items.plan_com.event')}
        >
          {data.deliverables_plan_com.map((e) => {
            const { _id } = e;
            return (
              <React.Fragment key={_id}>
                <Collapse key={e._id}>
                  <Panel
                    style={{ marginBottom: 16 }}
                    header={
                      <Descriptions
                        extra={
                          <>
                            <span>
                              {e.federation ? (
                                <>
                                  <span style={{ marginRight: 10 }}>
                                    {`${t('projects.form.federation')}: `}
                                  </span>
                                  <span
                                    style={{
                                      color: 'var(--secondaryColor)',
                                      marginRight: 10,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {e.federation}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span style={{ marginRight: 10 }}>
                                    {`${t('projects.form.federation')}: `}
                                  </span>
                                  <Tag color="var(--primaryColor)">
                                    {t(
                                      'projects.form.federation.no_federation'
                                    )}
                                  </Tag>
                                </>
                              )}
                            </span>
                            <Divider type="vertical" />
                          </>
                        }
                        column={4}
                        title={e.name}
                        size="small"
                      >
                        <Descriptions.Item
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.target`
                          )}
                        >
                          {actors.map((a) => {
                            if (a._id === e.target)
                              return `${
                                a.group ? `[${a.group}] -` : ''
                              } ${a?.first_name || ''} ${a?.last_name ||
                                ''}  ${a?.role || ''}`;
                            return '';
                          })}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.transmitter`
                          )}
                        >
                          {actors.map((a) => {
                            if (a._id === e.transmitter)
                              return `${
                                a.group ? `[${a.group}] -` : ''
                              } ${a?.first_name || ''} ${a?.last_name ||
                                ''}  ${a?.role || ''}`;
                            return '';
                          })}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.channel`
                          )}
                        >
                          {e.channel}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={4}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.goal_action`
                          )}
                        >
                          {e.goal_action}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={4}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.goal_description`
                          )}
                        >
                          {e.goal_description}
                        </Descriptions.Item>
                      </Descriptions>
                    }
                  >
                    <Descriptions>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.in_charge_of`
                        )}
                      >
                        {actors.map((a) => {
                          if (a._id === e.in_charge_of)
                            return `${
                              a.group ? `[${a.group}] -` : ''
                            } ${a?.first_name || ''} ${a?.last_name ||
                              ''}  ${a?.role || ''}`;
                          return '';
                        })}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.type`
                        )}
                      >
                        {e.type}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.frequency`
                        )}
                      >
                        {!Number.isNaN(e?.type_frequency * e?.number_frequency)
                          ? `  Tous les ${e?.type_frequency *
                              e?.number_frequency} jours`
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.start_date`
                        )}
                      >
                        {e.start_date ? (
                          <Tag>{moment(e.start_date).format('DD-MM-YYYY')}</Tag>
                        ) : (
                          <Tag>Aucune donnée</Tag>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.due_date`
                        )}
                      >
                        {e.due_date ? (
                          <Tag>{moment(e.due_date).format('DD-MM-YYYY')}</Tag>
                        ) : (
                          <Tag>Aucune donnée</Tag>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </React.Fragment>
            );
          })}
        </TabPane>
        <TabPane
          key={t('projects.form.items.plan_com.gantt')}
          tab={t('projects.form.items.plan_com.gantt')}
        >
          <Card style={{ margin: '20px' }} className="gantt-card">
            <Gantt
              key="federation"
              data={ganttData}
              purpose="federation"
              height={300}
              width={width > 1600 ? 1500 : 800}
              lengthForFederation={data.deliverables_plan_com.length}
            />
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

PlanComDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actors: PropTypes.oneOfType([PropTypes.array])
};

PlanComDisplay.defaultProps = {
  data: null,
  actors: null
};

export default PlanComDisplay;
