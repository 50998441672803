/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Collapse, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IndexItem from '../Projects/Projects/utilsProject/IndexItem';
import WhatDisplay from './widgetsReporting/displayItems/WhatDisplay';
import WhyDisplay from './widgetsReporting/displayItems/WhyDisplay';
import PriorDisplay from './widgetsReporting/displayItems/PriorDisplay';
import ContextDisplay from './widgetsReporting/displayItems/ContextDisplay';
import UtilityDisplay from './widgetsReporting/displayItems/UtilityDisplay';
import CostDisplay from './widgetsReporting/displayItems/CostDisplay';
import ActorsDisplay from './widgetsReporting/displayItems/ActorsDisplay';
import PlaningDisplay from './widgetsReporting/displayItems/PlaningDisplay';
import PlanComDisplay from './widgetsReporting/displayItems/PlanComDisplay';
import RiskDisplay from './widgetsReporting/displayItems/RiskDisplay';
import setVersionItem from '../../utils/constants/setVersionItem';

const ItemContainer = ({ title, data, actors, version }) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const ItemCheckList = {
    what: WhatDisplay,
    why: WhyDisplay,
    prior: PriorDisplay,
    context: ContextDisplay,
    utility: UtilityDisplay,
    cost: CostDisplay,
    actors: ActorsDisplay,
    planing: PlaningDisplay,
    plan_com: PlanComDisplay,
    risks: RiskDisplay
  };

  return data !== null ? (
    <>
      <Collapse
        collapsible="header"
        style={{
          backgroundColor: '#FFF',
          borderRadius: '5px'
        }}
      >
        <Panel
          showArrow={false}
          header={
            data ? (
              <>
                <Row justify="center">
                  <DownOutlined />
                  <IndexItem
                    key={data?._id || null}
                    data={data || null}
                    title={title}
                    version={version || null}
                  />
                </Row>
              </>
            ) : (
              <>
                <Descriptions title={t(`projects.form.${title}`).toUpperCase()}>
                  <Descriptions.Item label={t('projects.details.last_version')}>
                    <Tag color="#108ee9">
                      Aucune version disponible
                      {data.length}
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
              </>
            )
          }
        >
          {data ? (
            <>
              {Object.entries(ItemCheckList).map(([key, Value]) => {
                return title === key ? (
                  <Value key={data._id} data={data} actors={actors || null} />
                ) : null;
              })}
            </>
          ) : (
            <Descriptions>
              <Descriptions.Item>
                <Tag color="red">Aucune donnée dans cette version Item</Tag>
              </Descriptions.Item>
            </Descriptions>
          )}
        </Panel>
      </Collapse>
    </>
  ) : (
    <>
      <Collapse collapsible="disabled">
        <Panel
          showArrow={false}
          header={
            <Descriptions
              extra={setVersionItem(version)}
              title={t(`projects.form.${title}`).toUpperCase()}
            >
              <Descriptions.Item>
                <Tag color="magenta">
                  Aucune version disponible dans cette item
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </Collapse>
    </>
  );
};
ItemContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  actors: PropTypes.oneOfType([PropTypes.array]),
  version: PropTypes.number
};

ItemContainer.defaultProps = {
  data: null,
  title: null,
  actors: null,
  version: null
};

export default ItemContainer;
