import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Button, Row, Col, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import useFields from '../fieldsItems/itemsFields';

const CreateUpdateForm = ({
  purpose,
  config,
  title,
  data,
  projectId,
  resource,
  fieldsName,
  handleCancel,
  handleSubmit,
  isLoading
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const fields = useFields(fieldsName || title, projectId || null);

  const setValues = () => {
    if (data && purpose !== 'createItem' && config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(data)
          : data
      );
    }
  };

  useEffect(() => {
    if (config !== null || purpose !== 'createMatrice' || purpose === 'edit') {
      setValues();
    }
  }, []);

  const handleChange = (_, values) => {
    if (values?.federation) {
      const {
        understanding,
        contest,
        information,
        membership
      } = values.federation;
      if ((understanding, contest, information, membership)) {
        const result = (understanding + contest + information + membership) / 4;
        form.setFieldsValue({
          federation: { federation_number: result }
        });
      }
    } else if (values?.actors_list) {
      const tokenCode = Math.floor(Math.random() * (100 - 10) + 10);
      const { first_name, last_name } = values.actors_list;
      if ((first_name, last_name)) {
        const codeResult = `${first_name.charAt(0)}${last_name.charAt(
          0
        )}${tokenCode}`;
        form.setFieldsValue({
          actors_list: { code: codeResult }
        });
      }
    } else if (values?.risk_list) {
      const { probality, detection, impact } = values.risk_list;
      if (probality && detection && impact) {
        form.setFieldsValue({
          risk_list: { criticality: probality * detection * impact }
        });
      }
    }
  };

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...formItemLayout}
          onFinish={(values) => {
            handleSubmit(values, data, title, fieldsName, handleCancel);
          }}
          onValuesChange={handleChange}
          name={title}
          // layout="inline"
          form={form}
        >
          {fields.map((field) => generateFields(resource, field))}
          <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.06)' }} />
          <Form.Item {...tailFormItemLayout}>
            <Row justify="center" wrap={false}>
              <Col>
                <Button
                  style={{ margin: '0 6px' }}
                  type="link"
                  danger
                  onClick={handleCancel}
                >
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                  </span>
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="add" htmlType="submit">
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.save')} `}
                  </span>
                  <CheckOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

CreateUpdateForm.propTypes = {
  purpose: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  projectId: PropTypes.string,
  fieldsName: PropTypes.string,
  resource: PropTypes.string.isRequired,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

CreateUpdateForm.defaultProps = {
  data: null,
  purpose: null,
  config: null,
  title: null,
  handleCancel: null,
  fields: null,
  projectId: null,
  fieldsName: null,
  handleSubmit: null,
  isLoading: null
};

export default CreateUpdateForm;
