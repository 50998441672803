import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row, Card, Skeleton, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import Gantt from '../../components/Widgets/Gantt';

const IndicatorsDashboard = ({ data, getDataChartEvent }) => {
  const { t } = useTranslation();
  const [dataGantt, setDataGantt] = useState();

  const getDataChart = () => {
    if (data.plan_com[data.plan_com.length - 1] !== null) {
      const previousPlanCom = [];
      const { deliverables_plan_com } = data.plan_com[data.plan_com.length - 1];
      deliverables_plan_com.forEach((p) => {
        if (p.start_date) {
          const days = moment(Date.now()).diff(moment(p.start_date), 'days');
          previousPlanCom.push({
            date: p?.start_date,
            daysDiff: days,
            actor: p?.in_charge_of
          });
        }
      });
    }
    if (data.manage_planning[data.manage_planning.length - 1] !== null) {
      const chartData = [];
      data.manage_planning[data.manage_planning.length - 1].deliverables.map(
        (e) => {
          if (e.planning && e.planning.gantt_mark === true) {
            const { start_date, due_date, status, color } = e.planning;
            if ((start_date, due_date !== null)) {
              chartData.push({
                id: e._id,
                task: `${e._id + e.description}`,
                start_date:
                  start_date !== undefined
                    ? moment(start_date).format('YYYY-MM-DD')
                    : moment(due_date).format('YYYY-MM-DD'),
                due_date: moment(due_date).format('YYYY-MM-DD'),
                status,
                description: e.description,
                comment: e.comment,
                color: color || '#000'
              });
            }
          }
          setDataGantt(chartData);
          return true;
        }
      );
    }
    return true;
  };

  useEffect(() => {
    getDataChart();
  }, [data]);

  return data ? (
    <>
      <Row justify="space-between" gutter={16}>
        <Col xxl={24} sm={24} xs={24}>
          {data.manage_planning[data.manage_planning.length - 1] ? (
            <Card
              title={t('projects.gantt')}
              style={{ width: '100%', height: 800, margin: '16px 0 24px' }}
            >
              <Divider />
              <div
                style={{
                  maxWidth: '100%',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  maxHeight: 750
                }}
              >
                <Gantt
                  onClick={getDataChartEvent}
                  data={dataGantt}
                  purpose="dashboard"
                />
              </div>
            </Card>
          ) : (
            <Card style={{ width: 600 }} title={t('projects.gantt')}>
              <Divider />
              {null}
            </Card>
          )}
        </Col>
      </Row>
    </>
  ) : (
    <Skeleton />
  );
};

IndicatorsDashboard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  getDataChartEvent: PropTypes.func
};

IndicatorsDashboard.defaultProps = {
  data: null,
  getDataChartEvent: null
};

export default IndicatorsDashboard;
