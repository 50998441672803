import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Button, Tabs, Modal, Collapse, Row, Col } from 'antd';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import { EditIcon, CreateIcon } from '../../../../utils/constants/customIcon';
import RiskMatrixChart from '../../../../components/Widgets/RiskMatrixChart';
import useProjectContext from '../../../../contexts/ProjectContext';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const RiskDisplay = ({ data, title, id, getProject, generateForm, role }) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataRisk_list, setDataRisk_list] = useState();
  const itemRisks = [];

  const dataChart = () => {
    data.risk_list.forEach((e) => {
      const { criticality } = e;
      itemRisks.push({
        item: e.type_of_risk ? e.type_of_risk.toString() : 'N/A',
        id: e._id,
        type: 'Risques',
        value: Math.round(criticality)
      });
    });
    return itemRisks;
  };

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        risk_list: { ...dataRisk_list }
      })
    }
  };
  const configNew = {
    onGetResource: {
      setFields: () => ({
        risk_list: { number: data?.risk_list.length + 1 }
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
  };

  const openForm = (idRender, risks) => {
    const { date, ...rest } = risks;
    setVisible(idRender);
    setDataRisk_list({
      ...rest,
      date: moment(date),
      decision_date: moment(rest.decision_date)
    });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };
  return (
    <>
      <Tabs centered id="blue-tab-risks">
        <TabPane tab="Risques" key={1} span={2}>
          {data.risk_list.map((e) => {
            const {
              _id,
              files,
              type_of_risk,
              number,
              potential_risk,
              impact_effect,
              decision_date,
              decision,
              measure_description,
              criticality,
              ...rest
            } = e;
            return visible === _id && dataRisk_list ? (
              generateForm('edit', title, data, closeForm, 'risk_list', config)
            ) : (
              <Collapse key={_id}>
                <Panel
                  style={{ marginBottom: 16 }}
                  extra={
                    role ? (
                      <Row justify="center">
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() => openForm(_id, e)}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: 2
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <ButtonDeleteAll
                          purpose="matrice"
                          projectId={id}
                          title={title}
                          titleId={data._id}
                          matriceName="risk_list"
                          matriceId={_id}
                          handleCancel={closeForm}
                        />
                      </Row>
                    ) : null
                  }
                  header={
                    <Descriptions
                      column={6}
                      title={`${t(
                        `projects.form.items.risks.number`
                      )} : ${number}`}
                      size="small"
                      extra={`${t(`projects.form.items.risks.date`)} : ${moment(
                        decision_date
                      ).format('DD-MM-YYYY')} `}
                    >
                      <Descriptions.Item
                        span={2}
                        label={t(`projects.form.items.risks.potential_risk`)}
                      >
                        {potential_risk}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(`projects.form.items.risks.type_of_risk`)}
                      >
                        {type_of_risk}
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={2}
                        label={t(`projects.form.items.risks.impact_effect`)}
                      >
                        {impact_effect}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={t(`projects.form.items.risks.criticality`)}
                      >
                        {criticality}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                >
                  <Descriptions column={3} size="small" style={{ padding: 12 }}>
                    {Object.entries(rest).map(([key, value]) => {
                      return (
                        <Descriptions.Item
                          key={key}
                          label={t(`projects.form.items.risks.${key}`)}
                        >
                          {value}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                  <Descriptions column={4} size="small" style={{ padding: 12 }}>
                    <Descriptions.Item
                      label={t(`projects.form.items.risks.decision`)}
                    >
                      {decision}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={t(`projects.form.items.risks.measure_description`)}
                    >
                      {measure_description}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            );
          })}
        </TabPane>
        );
        <TabPane tab="Matrice" span={2}>
          <Row
            justify="center"
            style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%' }}
          >
            <Col>
              <RiskMatrixChart
                dataChart={dataChart()}
                width={700}
                height={450}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={800}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            title,
            data,
            closeFormModal,
            'risk_list',
            configNew
          )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => openFormModal()}
        >
          <CreateIcon />
          {t('projects.form.items.risks.addRisk')}
        </Button>
      ) : null}
    </>
  );
};

RiskDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool
};

RiskDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false
};

export default RiskDisplay;
