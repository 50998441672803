/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Button, Card, Modal, Row, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import { CreateIcon, EditIcon } from '../../../../utils/constants/customIcon';
import useProjectContext from '../../../../contexts/ProjectContext';

const ActorsDisplay = ({ data, title, id, getProject, generateForm, role }) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataActor, setDataActor] = useState();
  const { project } = useProjectContext();

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        actors_list: { ...dataActor }
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, actor) => {
    setVisible(idRender);
    setDataActor({ ...actor });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  const setSpan = (arg) => {
    if (arg === 'gain') {
      return 2;
    }
    return 1;
  };

  return (
    <>
      {data.actors_list.map((e) => {
        const { _id, group, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            {visible === _id && dataActor ? (
              generateForm(
                'edit',
                title,
                data,
                closeForm,
                'actors_list',
                config
              )
            ) : (
              <Card>
                <Descriptions
                  extra={
                    role ? (
                      <Row justify="space-around">
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() => (role ? openForm(_id, e) : null)}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: 2
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Divider
                          type="vertical"
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            height: 30
                          }}
                        />
                        <ButtonDeleteAll
                          purpose="matrice"
                          projectId={id}
                          title={title}
                          titleId={data._id}
                          matriceName="actors_list"
                          matriceId={_id}
                          handleCancel={closeForm}
                        />
                      </Row>
                    ) : null
                  }
                  key={_id}
                  column={4}
                  title={group}
                  size="small"
                >
                  {Object.entries(rest).map(([key, value]) => {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={t(`projects.form.items.actors.${key}`)}
                        span={setSpan(key)}
                      >
                        {value}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            )}
          </React.Fragment>
        );
      })}
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          width={700}
          closable
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            title,
            data,
            closeFormModal,
            'actors_list'
          )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => openFormModal()}
        >
          <CreateIcon />
          {t('projects.form.items.actors.addActor')}
        </Button>
      ) : null}
    </>
  );
};

ActorsDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool
};

ActorsDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false
};

export default ActorsDisplay;
