import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Row, Button, Col, Checkbox, Card } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { SwatchesPicker } from 'react-color';
import { formItemLayout } from '../../../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../../../utils/GenerateFormItem';
import useFieldsManagePlanning from './fieldsManagePlanning';
import EventsTableForPlanning from './EventsTableForPlanning';

const ManagePlanning = ({
  // eslint-disable-next-line no-unused-vars
  purposeArg,
  dataItems,
  projectId,
  config,
  handleCancel,
  handleSubmit
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [visibleChromeColor, setVisibleChromeColor] = useState(false);
  const [colorGantt, setColorGantt] = useState('#000');
  const [visibleEvents, setVisibleEvents] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [configPlanningUtility, setConfigPlanningUtility] = useState({
    planning: false,
    utility: false
  });
  const {
    commonFields,
    planningFields,
    utilityFields
  } = useFieldsManagePlanning(
    projectId,
    isFieldsLoading,
    setIsFieldsLoading,
    isTask,
    setVisibleChromeColor,
    visibleChromeColor,
    form,
    colorGantt
  );
  const listItemsManage = { planning: planningFields, utility: utilityFields };

  const setValues = useCallback(() => {
    if (config !== null) {
      const dataToEdit = config.onGetResource.setFields(dataItems);
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(dataItems)
          : dataItems
      );
      setConfigPlanningUtility({
        utility: dataToEdit.deliverables.utility !== undefined,
        planning: dataToEdit.deliverables.planning !== undefined
      });
      if (
        dataToEdit.deliverables.planning &&
        dataToEdit.deliverables.planning.start_date === undefined
      ) {
        setColorGantt(dataToEdit.deliverables.planning.color || '#000');
        setIsTask(true);
      }
    }
  }, [dataItems]);

  const setDefaultValueIfPlanning = () => {
    if (configPlanningUtility.planning && config === null) {
      form.setFieldsValue({
        deliverables: {
          planning: {
            percentage: 0,
            color: '#000',
            piloting_mark: true,
            gantt_mark: true
          }
        }
      });
    }
    if (configPlanningUtility.utility && config === null) {
      form.setFieldsValue({
        deliverables: {
          utility: {
            coefficient: 50
          }
        }
      });
    }
  };

  useEffect(() => {
    if (config !== null) {
      (async () => {
        await setValues();
      })();
    } else {
      form.setFieldsValue({
        deliverables: { global_type: 'Informations' }
      });
    }
    setDefaultValueIfPlanning();
  }, []);

  useEffect(() => {
    setDefaultValueIfPlanning();
  }, [configPlanningUtility]);

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });

  const manageSubmit = (values) => {
    handleSubmit(
      values,
      dataItems,
      'manage_planning',
      'deliverables',
      handleCancel
    );
  };

  const handleChange = (_, values) => {
    if (values.deliverables.planning) {
      if (values.deliverables.planning.status === 'Finalisé') {
        form.setFieldsValue({
          deliverables: { planning: { percentage: 100 } }
        });
      } else if (
        values.deliverables.planning.status === 'En cours' &&
        values.deliverables.planning.percentage === 100
      ) {
        form.setFieldsValue({ deliverables: { planning: { percentage: 99 } } });
      }
      setIsTask(values.deliverables.planning.is_task);
    }
  };

  const onChangeColor = (value) => {
    form.setFieldsValue({ deliverables: { planning: { color: value.hex } } });
    setVisibleChromeColor(!visibleChromeColor);
    setColorGantt(value.hex);
  };
  return (
    <>
      <Spin spinning={isFieldsLoading}>
        <Row justify="center">
          <Col span={22}>
            <Form
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemLayout}
              onValuesChange={handleChange}
              onFinish={(values) => manageSubmit(values)}
              name={t('projects.form.items.manage_planning.title')}
              form={form}
            >
              <>
                <Card
                  headStyle={{
                    boxShadow: 0,
                    borderRadius: 10,
                    backgroundColor: 'var(--clientColor'
                  }}
                  title={
                    <span style={{ color: '#fff' }}>
                      {' '}
                      {t('projects.form.common_fields')}
                    </span>
                  }
                >
                  {commonFields.map((field) =>
                    generateFields('projects', field)
                  )}
                </Card>

                <Row>
                  {Object.entries(listItemsManage).map(([key, value]) => (
                    <Col
                      key={key}
                      xxl={12}
                      sm={24}
                      style={{ marginBottom: 20 }}
                    >
                      <div
                        style={{
                          marginRight: 20,
                          boxShadow: 0,
                          borderRadius: 10,
                          backgroundColor: 'var(--clientColor',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          height: 50,
                          marginBottom: 20
                        }}
                      >
                        <span style={{ color: '#fff' }}>
                          {t(`projects.form.${key}`)}
                        </span>
                        <Checkbox
                          checked={configPlanningUtility[key]}
                          onChange={() =>
                            setConfigPlanningUtility({
                              ...configPlanningUtility,
                              [key]: !configPlanningUtility[key]
                            })
                          }
                        />
                      </div>
                      {configPlanningUtility[key] &&
                        value.map((field) => generateFields('projects', field))}
                    </Col>
                  ))}
                  {visibleChromeColor && (
                    <div
                      style={{
                        marginTop: '-25%',
                        marginLeft: '40%'
                      }}
                    >
                      <SwatchesPicker
                        onChange={(value) => onChangeColor(value)}
                      />
                    </div>
                  )}
                </Row>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Row
                    justify="end"
                    wrap={false}
                    style={{
                      position: 'relative',
                      right: isReductScreen ? '-50%' : '-90%',
                      marginBottom: 50
                    }}
                  >
                    <Col>
                      <Button
                        style={{ margin: '0 6px' }}
                        type="link"
                        danger
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        <span
                          style={{
                            display: isReductScreen ? 'none' : 'inline-block'
                          }}
                        >
                          {`${t('buttons.cancel')} `}
                        </span>
                        <CloseOutlined />
                      </Button>
                    </Col>
                    <Col>
                      <Button type="add" htmlType="submit">
                        <span
                          style={{
                            display: isReductScreen ? 'none' : 'inline-block'
                          }}
                        >
                          {`${t('buttons.save')} `}
                        </span>
                        <CheckOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            </Form>
          </Col>
          {dataItems && dataItems.events && (
            <Col span={2}>
              <Button
                style={{ color: visibleEvents ? 'var(--primaryColor)' : null }}
                onClick={() => setVisibleEvents(!visibleEvents)}
                icon={<ClockCircleOutlined />}
              />
              {visibleEvents && (
                <Card
                  style={{
                    padding: 0,
                    position: 'absolute',
                    maxHeight: 1000,
                    border: '0.1px solid',
                    marginTop: 5
                  }}
                >
                  <EventsTableForPlanning
                    dataSource={(dataItems && dataItems.events) || []}
                  />
                </Card>
              )}
            </Col>
          )}
        </Row>
      </Spin>
    </>
  );
};

ManagePlanning.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  fieldsName: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  purposeArg: PropTypes.string
};
ManagePlanning.defaultProps = {
  dataItems: null,
  title: null,
  config: null,
  fieldsName: null,
  handleCancel: null,
  handleSubmit: null,
  purposeArg: null
};

export default ManagePlanning;
