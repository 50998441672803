import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Row, Card, Tag, Select } from 'antd';

const EventHeadband = ({
  t,
  eventClose,
  getProjects,
  updateResource,
  visibleSelectEventCloseAfter,
  visibleSelectEventCloseBefore,
  setVisibleEventCloseAfter,
  setVisibleEventCloseBefore,
  configNote,
  data,
  openModal
}) => {
  return (
    <Row justify="space-between" gutter={24}>
      <Col
        xxl={4}
        xl={12}
        sm={24}
        xs={24}
        style={{
          margin: '16px 0 24px 0'
        }}
      >
        <Col
          style={{
            textAlign: 'center',
            marginBottom: 5,
            borderRadius: 5
          }}
          span={24}
        >
          <Tag className="dashboard-event-tag">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <span>{t('dashboard.last_event')}</span>
              <EditOutlined
                onClick={() =>
                  setVisibleEventCloseBefore(!visibleSelectEventCloseBefore)
                }
              />
            </div>
          </Tag>
        </Col>
        {visibleSelectEventCloseBefore ? (
          <Select
            style={{
              width: '100%'
            }}
            showSearch
            onChange={(_, option) => {
              updateResource(
                {
                  previous_event: option.option
                },
                data._id
              );
              setVisibleEventCloseBefore(!visibleSelectEventCloseBefore);
              getProjects();
            }}
            options={eventClose?.beforeDate.map((ele) => ({
              option: {
                start_date: ele.start_date,
                due_date: ele.due_date,
                task: ele.task,
                description: ele.description
              },
              value: ele._id,
              key: ele._id,
              label: `${ele.task} : ${moment(ele.start_date).format(
                'DD-MM-YYYY'
              )}`
            }))}
          />
        ) : (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <p>
              {data.previous_event ? `Nom : ${data.previous_event?.task}` : '-'}
            </p>
            <p>
              {`${
                data.previous_event
                  ? moment(data.previous_event?.start_date).format(
                      'ddd DD-MM-YYYY'
                    )
                  : '-'
              }`}
            </p>
          </div>
        )}
      </Col>
      <Col xxl={16} xl={16} sm={24} xs={24}>
        <Card
          onClick={() =>
            openModal(data, 'note', null, 'note', null, configNote)
          }
          style={{
            background: 'var(--itemHoverBackground)',
            boxShadow: 'none',
            color: 'var(--textColor)',
            fontSize: '1.1em',
            padding: 0,
            margin: '16px 0 24px 0',
            borderRadius: 50
          }}
        >
          <InfoCircleOutlined
            style={{
              margin: '0 12px',
              fontSize: '1.5em',
              position: 'relative',
              top: 2
            }}
          />
          {data.note !== null ? `${data?.note.substr(0, 80)} [...]` : null}
        </Card>
      </Col>
      <Col
        xxl={4}
        xl={12}
        sm={24}
        xs={24}
        style={{
          margin: '16px 0 24px 0'
        }}
      >
        <Col
          style={{
            textAlign: 'center',
            marginBottom: 5,
            borderRadius: 5
          }}
          span={24}
        >
          <Tag className="dashboard-event-tag">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <span>{t('dashboard.next_event')}</span>
              <EditOutlined
                onClick={() =>
                  setVisibleEventCloseAfter(!visibleSelectEventCloseAfter)
                }
              />
            </div>
          </Tag>
        </Col>
        {visibleSelectEventCloseAfter ? (
          <Select
            style={{
              width: '100%'
            }}
            showSearch
            onChange={(_, option) => {
              updateResource(
                {
                  next_event: option.option
                },
                data._id
              );
              setVisibleEventCloseAfter(!visibleSelectEventCloseAfter);
              getProjects();
            }}
            options={eventClose?.afterDate.map((ele) => ({
              option: {
                start_date: ele.start_date,
                due_date: ele.due_date,
                task: ele.task,
                description: ele.description
              },
              value: ele._id,
              key: ele._id,
              label: `${ele.task} : ${moment(ele.start_date).format(
                'DD-MM-YYYY'
              )}`
            }))}
          />
        ) : (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <p>{data.next_event ? `Nom : ${data.next_event?.task}` : '-'}</p>
            <p>
              {`${
                data.next_event
                  ? moment(data.next_event?.start_date).format('ddd DD-MM-YYYY')
                  : '-'
              }`}
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
};

EventHeadband.propTypes = {
  t: PropTypes.func.isRequired,
  eventClose: PropTypes.oneOfType([PropTypes.array]),
  getProjects: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
  setVisibleEventCloseAfter: PropTypes.func.isRequired,
  setVisibleEventCloseBefore: PropTypes.func.isRequired,
  visibleSelectEventCloseAfter: PropTypes.bool.isRequired,
  visibleSelectEventCloseBefore: PropTypes.bool.isRequired,
  configNote: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  openModal: PropTypes.func.isRequired
};

EventHeadband.defaultProps = {
  data: null,
  eventClose: null
};

export default EventHeadband;
