import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Select, DatePicker, Input, Divider, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const usePriorFields = (enums, loading) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);
  const dateFormat = 'DD-MM-YYYY';
  const today = moment();

  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };

  const priorFields = [
    {
      name: ['studies', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['studies', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['studies', 'type'],
      input: enums ? (
        <Select
          showSearch
          loading={loading}
          style={{ width: 240 }}
          placeholder="Choisir un type"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()}>
                  <PlusOutlined />
                  {t(`projects.form.studies.title`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.prior_types.map((ele) => (
            <Option key={ele}>{ele}</Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['studies', 'creation_date'],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['studies', 'description'],
      input: <TextArea style={{ whiteSpace: 'pre-line' }} row={4} />
    }
  ];

  return { priorFields };
};

export default usePriorFields;
