import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Input, Divider, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const useContextFields = (enums, loading) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);
  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };
  const contextFields = [
    {
      name: ['factors', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['factors', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['factors', 'type'],
      input: enums ? (
        <Select
          showSearch
          loading={loading}
          style={{ width: 240 }}
          placeholder="Choisir un type"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()}>
                  <PlusOutlined />
                  {t(`projects.form.factors.title`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.context_types.map((ele) => (
            <Option key={ele}>{ele}</Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['factors', 'impact'],
      input: <Input row={6} />
    },
    {
      name: ['factors', 'description'],
      input: <TextArea row={6} />
    }
  ];
  return { contextFields };
};

export default useContextFields;
