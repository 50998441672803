import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { DeleteIcon } from '../../../../utils/constants/customIcon';
import ModalToConfirmDeleteWithVersionning from './ModalToConfirmDeleteWithVersionning';
import useProjectContext from '../../../../contexts/ProjectContext';
import CreateUpdateForm from './CreateUpdateForm';

const ButtonDeleteAll = ({
  purpose,
  title,
  titleId,
  projectId,
  matriceName,
  matriceId,
  handleCancel,
  data
}) => {
  const { confirm } = Modal;
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const {
    project,
    loading,
    updateResource,
    createResource
  } = useProjectContext();
  const [visibleEvent, setVisibleEvent] = useState(false);
  const { t } = useTranslation();
  const modalRef = useRef();
  const [objectData, setObjectdata] = useState();

  const deleteResource = async () => {
    const setUrl = {
      url: `projects/delete/${projectId}/${title}${
        titleId ? `/${titleId}` : ''
      }${matriceName ? `/${matriceName}` : ''}${
        matriceId ? `/${matriceId}` : ''
      }`
    };
    try {
      await dispatchAPI('PATCH', {
        url: setUrl.url
      });
      handleCancel();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteProject = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/projects/${projectId}` });
      await handleCancel();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const showConfirm = (titleConfirm, cancelTex) => {
    confirm({
      title: titleConfirm,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      okText: 'Oui',
      cancelText: cancelTex,
      onOk() {
        if (purpose === 'project') deleteProject();
        else deleteResource();
      }
    });
  };
  const openModal = (
    dataT,
    titleModal,
    indexItem,
    action,
    fields,
    configArg
  ) => {
    setObjectdata({
      data_to_send: dataT,
      title: titleModal,
      index: indexItem,
      purpose: action,
      config: configArg,
      field_name: fields,
      visible: true
    });
    setVisibleEvent(true);
  };

  const deleteSubmit = () => {
    if (purpose === 'version') {
      const titleConfirm = 'Voulez-vous supprmier cette version ?';
      const cancelText = 'Garder cette version';
      showConfirm(titleConfirm, cancelText);
    }
    if (purpose === 'matrice') {
      modalRef.current.toggleModal(
        projectId,
        title,
        titleId,
        matriceName,
        matriceId
      );
    }
    if (purpose === 'project') {
      const titleConfirm = t(`buttons.delete_project`);
      const cancelText = 'Annuler';
      showConfirm(titleConfirm, cancelText);
    }
  };
  const handleSubmitEvent = async (values) => {
    const resultToSend = project[title]
      .filter((item) => item && item._id === titleId)[0]
      [matriceName].filter((matrice) => matrice && matrice._id !== matriceId);
    const body = { ...values, [matriceName]: resultToSend };
    await createResource(
      body,
      title,
      null,
      null,
      setVisibleEvent(!visibleEvent)
    );
    const planningToUpdate = project[title]
      .filter((item) => item && item._id === titleId)[0]
      [matriceName].filter((matrice) => matrice && matrice._id === matriceId);
    planningToUpdate[0].planning.deleted_to_upper_version = true;
    await updateResource(
      {
        [matriceName]: planningToUpdate[0]
      },
      data,
      title,
      matriceName
    );
  };

  return (
    <>
      <Tooltip title={t(`buttons.delete`)}>
        <Button
          disabled={project?.status === 'close'}
          style={{
            color: purpose === 'project' ? '#fff' : 'var(--errorColor)',
            background: 'transparent',
            border: 'none',
            padding: 4,
            boxShadow: 'none'
          }}
          onClick={() => deleteSubmit()}
        >
          <DeleteIcon />
        </Button>
      </Tooltip>
      <ModalToConfirmDeleteWithVersionning
        destroyOnClose
        openModal={openModal}
        ref={modalRef}
      />
      <Modal
        title={t(`projects.form.items.${title}.title`)}
        visible={visibleEvent}
        footer={false}
        destroyOnClose
        onCancel={() => {
          setVisibleEvent(false);
          handleCancel();
        }}
        width={1200}
      >
        <CreateUpdateForm
          purpose={purpose}
          resource="projects"
          title={objectData?.title}
          handleCancel={handleCancel}
          projectId={project?._id}
          data={objectData?.data_to_send}
          config={objectData?.config || null}
          index={null}
          fieldsName={objectData?.field_name}
          handleSubmit={handleSubmitEvent}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

ButtonDeleteAll.propTypes = {
  purpose: PropTypes.string.isRequired,
  title: PropTypes.string,
  projectId: PropTypes.string,
  matriceName: PropTypes.string,
  handleCancel: PropTypes.func,
  titleId: PropTypes.string,
  matriceId: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ButtonDeleteAll.defaultProps = {
  title: null,
  projectId: null,
  matriceName: null,
  handleCancel: null,
  titleId: null,
  matriceId: null,
  data: null
};

export default ButtonDeleteAll;
