import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Descriptions, Card, Tag, Row, Col } from 'antd';

const PriorDisplay = ({ data }) => {
  const { t } = useTranslation();

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }
    return 1;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {data.studies.map((e) => {
            const { _id, files, type, creation_date, ...rest } = e;
            return (
              <React.Fragment key={_id}>
                <Card>
                  <Descriptions column={4} title={type} size="small">
                    {Object.entries(rest).map(([key, value]) => {
                      return (
                        <Descriptions.Item
                          style={{ whiteSpace: 'pre-line' }}
                          key={key}
                          label={t(`projects.form.items.prior.studies.${key}`)}
                          span={setSpan(key)}
                        >
                          {value}
                        </Descriptions.Item>
                      );
                    })}
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.prior.studies.creation_date`
                      )}
                    >
                      <Tag>{moment(creation_date).format('DD-MM-YYYY')}</Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </React.Fragment>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

PriorDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

PriorDisplay.defaultProps = {
  data: null
};

export default PriorDisplay;
