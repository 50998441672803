import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Descriptions,
  Card,
  Tag,
  Button,
  Modal,
  Row,
  Col,
  Badge,
  Divider
} from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import { EditIcon, CreateIcon } from '../../../../utils/constants/customIcon';
import useProjectContext from '../../../../contexts/ProjectContext';

const PriorDisplay = ({ data, title, id, getProject, generateForm, role }) => {
  const { t } = useTranslation();
  const { setDataToModalFiles, getFilesByItem, project } = useProjectContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataStudie, setDataStudie] = useState();

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        studies: { ...dataStudie }
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, studie) => {
    const { creation_date, ...rest } = studie;
    setVisible(idRender);
    setDataStudie({ ...rest, creation_date: moment(creation_date) });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }
    return 1;
  };

  useEffect(() => {
    if (data.files) getFilesByItem(data.files);
  }, [project]);

  return (
    <>
      <Row>
        <Col span={24}>
          {data.studies.map((e) => {
            const { _id, files, type, creation_date, ...rest } = e;
            return (
              <React.Fragment key={_id}>
                {visible === _id && dataStudie ? (
                  generateForm(
                    'edit',
                    title,
                    data,
                    closeForm,
                    'studies',
                    config
                  )
                ) : (
                  <Card>
                    <Descriptions
                      extra={
                        role ? (
                          <Row justify="space-around">
                            <Button
                              disabled={project?.status === 'close'}
                              onClick={() => (role ? openForm(_id, e) : null)}
                              size="small"
                              type="link"
                              style={{
                                color: 'var(--secondaryColor)',
                                top: 2
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Divider
                              type="vertical"
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                height: 30
                              }}
                            />
                            <Badge count={files?.length}>
                              <Button
                                disabled={project?.status === 'close'}
                                size="small"
                                style={{ bottom: -5 }}
                                onClick={() =>
                                  setDataToModalFiles(
                                    files || [],
                                    title,
                                    data._id,
                                    'studies',
                                    _id
                                  )
                                }
                                type="primary"
                                icon={<CloudServerOutlined size={20} />}
                              />
                            </Badge>
                            <Divider
                              type="vertical"
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                height: 30
                              }}
                            />
                            <ButtonDeleteAll
                              purpose="matrice"
                              projectId={id}
                              title={title}
                              titleId={data._id}
                              matriceName="studies"
                              matriceId={_id}
                              handleCancel={closeForm}
                            />
                          </Row>
                        ) : null
                      }
                      column={4}
                      title={type}
                      size="small"
                    >
                      {Object.entries(rest).map(([key, value]) => {
                        return (
                          <Descriptions.Item
                            style={{ whiteSpace: 'pre-line' }}
                            key={key}
                            label={t(
                              `projects.form.items.prior.studies.${key}`
                            )}
                            span={setSpan(key)}
                          >
                            {value}
                          </Descriptions.Item>
                        );
                      })}
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.prior.studies.creation_date`
                        )}
                      >
                        <Tag>{moment(creation_date).format('DD-MM-YYYY')}</Tag>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                )}
              </React.Fragment>
            );
          })}
          {visibleModal ? (
            <Modal
              title={t(`projects.form.items.${title}.title`)}
              closable
              width={800}
              visible={visibleModal}
              footer={false}
              onCancel={closeFormModal}
            >
              {generateForm(
                'createMatrice',
                title,
                data,
                closeFormModal,
                'studies'
              )}
            </Modal>
          ) : null}
          {role ? (
            <Button
              disabled={project?.status === 'close'}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '24px auto',
                padding: '22px 16px'
              }}
              type="primary"
              onClick={() => openFormModal()}
            >
              <CreateIcon />
              {t('projects.form.items.prior.studies.addStudie')}
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

PriorDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool
};

PriorDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false
};

export default PriorDisplay;
