import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';
import { Col, Row } from 'antd';

const Costs = ({ data, purpose }) => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState({});
  const scaleCost = {
    costs: {
      min: 0
    },
    charges: {
      min: 0
    }
  };
  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };

  const getData = () => {
    const chartCost = [];
    const { cost } = data;
    const origin = cost.slice(2);
    origin.forEach((e, index) => {
      const costsArray = [];
      const chargesArray = [];
      if (e !== null) {
        e.costs.forEach((f) => {
          chargesArray.push(f.charge);
          costsArray.push(f.cost_value);
        });
        if (chargesArray.length && costsArray.length) {
          chartCost.push({
            time: `V${index + 1} du ${moment(e.event.date).format(
              'DD-MM-YYYY'
            )}`,
            description: `V${index + 1} du ${moment(e?.event?.date).format(
              'DD-MM-YYYY'
            )}`,
            comment: e?.event?.comment,
            costs: costsArray.reduce((a, value) => a + value, 0),
            charges: chargesArray.reduce((a, value) => a + value, 0)
          });
        } else {
          chartCost.push({
            time: moment(data.created_at).format('DD-MM-YYYY'),
            costs: 0,
            charges: 0
          });
        }
      }
    });

    setDataChart(chartCost);
  };

  useEffect(() => {
    if (data) getData();
  }, [purpose, data]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart
            theme="light"
            padding="auto"
            height={200}
            data={dataChart}
            autoFit
            scale={scaleCost}
          >
            <span className="main-title" style={styles.mainTitle}>
              {t('home.chart.description')}
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col style={{ marginBottom: 10 }} span={24}>
                  <span>
                    {`Item Coût V1 du ${moment(
                      data.manage_planning[2]?.event?.date
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>

                <Col span={24}>
                  <Row justiy="space-around">
                    <Col span={12}>
                      <span>{`Coût Total ${dataChart[0].costs} €`}</span>
                    </Col>
                    <Col span={12}>
                      <span>{`ChargesTotal ${dataChart[0].charges} J/H`}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </span>
            <Axis />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />
            <Legend
              custom
              allowAllCanceled
              items={[
                {
                  value: 'coût (en €)',
                  name: 'costs'
                },
                {
                  value: 'charges (en J/H)',
                  name: 'charges'
                }
              ]}
            />
            <Axis
              name="costs"
              grid={null}
              label={{
                textStyle: {
                  fill: '#113c4f'
                }
              }}
            />
            <Axis
              name="charges"
              grid={null}
              label={{
                textStyle: {
                  fill: '#fdae6b'
                }
              }}
            />
            <Axis
              name="time"
              min={0}
              label={{
                textStyle: {
                  fill: '#113c4f'
                }
              }}
            />
            <Geom
              tooltip={[
                'time*costs*description*comment',
                (time, costs, description, comment) => {
                  return {
                    name: `${costs} €`,
                    title: description,
                    value: `Commentaire: ${comment}`
                  };
                }
              ]}
              type="interval"
              position="time*costs"
              color="#113c4f"
            />
            <Geom
              tooltip={[
                'time*charges*description',
                (time, charges, description) => {
                  return {
                    name: `${charges} j/h`,
                    title: description
                  };
                }
              ]}
              type="line"
              position="time*charges"
              color="#fdae6b"
              size={3}
              shape="smooth"
            />
            <Geom
              tooltip={[
                'time*charges*description',
                (time, charges, description) => {
                  return {
                    name: `${charges} j/h`,
                    title: description
                  };
                }
              ]}
              type="point"
              position="time*charges"
              color="#fdae6b"
              size={3}
              shape="circle"
            />
          </Chart>
        </div>
      ) : (
        <>
          <span>
            Oups, la version du projet doit être en V+ ou les versions de
            l&apos;item Coût doivent être superieur à V1 et remplies
          </span>
        </>
      )}
    </>
  );
};

Costs.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string
};

Costs.defaultProps = {
  data: null,
  purpose: null
};

export default Costs;
