import React from 'react';
import { Select, Input, DatePicker, InputNumber, Button } from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

const usePlanComFields = (
  id,
  enums,
  actors,
  loading,
  setVisibleChromeColor,
  visibleChromeColor,
  colorGantt,
  duplicate
) => {
  const today = moment();
  const { t } = useTranslation();

  const planComFieldsHeader = [
    {
      name: ['deliverables_plan_com', 'name'],
      rules: [{ required: true }],
      input: <TextArea row={2} />
    },
    {
      name: ['deliverables_plan_com', 'start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={duplicate && duplicate?.length > 0}
          format="DD-MM-YYYY"
          initialValues={today}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['deliverables_plan_com', 'due_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={duplicate && duplicate?.length > 0}
          format="DD-MM-YYYY"
          initialValues={today}
          defaultPickerValue={moment()}
        />
      )
    }
  ];

  const planComFields2 = [
    {
      name: ['deliverables_plan_com', 'type'],
      rules: [{ required: false }],
      input: <TextArea style={{ width: 300 }} row={2} />
    },
    {
      name: ['deliverables_plan_com', 'target'],
      rules: [{ required: false }],
      input: actors ? (
        <Select showSearch loading={loading}>
          {actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option key={value._id} value={value._id}>
                    {`${
                      value.group ? `[${value.group}] -` : ''
                    } ${value?.first_name || ''} ${value?.last_name ||
                      ''}  ${value?.role || ''}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      ) : null
    }
  ];

  const planComFields3 = [
    {
      name: ['deliverables_plan_com', 'goal_description'],
      rules: [{ required: false }],
      input: <TextArea row={2} />
    },
    {
      name: ['deliverables_plan_com', 'goal_action'],
      rules: [{ required: false }],
      input: <TextArea row={2} />
    }
  ];
  const planComFields4 = [
    {
      name: ['deliverables_plan_com', 'in_charge_of'],
      rules: [{ required: false }],
      input: actors ? (
        <Select showSearch loading={loading}>
          {actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option key={value._id} value={value._id}>
                    {`${
                      value.group ? `[${value.group}] -` : ''
                    } ${value?.first_name || ''} ${value?.last_name ||
                      ''}  ${value?.role || ''}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      ) : null
    },
    {
      name: ['deliverables_plan_com', 'transmitter'],
      rules: [{ required: false }],
      input: actors ? (
        <Select showSearch loading={loading}>
          {actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option key={value._id} value={value._id}>
                    {`${
                      value.group ? `[${value.group}] -` : ''
                    } ${value?.first_name || ''} ${value?.last_name ||
                      ''}  ${value?.role || ''}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      ) : null
    }
  ];
  const planComFields5 = [
    {
      name: ['deliverables_plan_com', 'channel'],
      rules: [{ required: false }],
      input: <Input />
    },
    {
      name: ['deliverables_plan_com', 'impact'],
      rules: [{ required: false }],
      input: (
        <Select>
          <Option value={1}>{t('projects.form.minor')}</Option>
          <Option value={2}>{t('projects.form.medium')}</Option>
          <Option value={3}>{t('projects.form.major')}</Option>
        </Select>
      )
    },
    {
      name: ['deliverables_plan_com', 'federation'],
      rules: [{ required: false }],
      input: <InputNumber min={0} max={100} />
    },
    {
      name: ['deliverables_plan_com', 'color'],
      colon: false,
      priorityLabel: true,
      label: (
        <>
          <Button type="link" style={{ color: '#000' }}>
            Couleur
          </Button>
          <BgColorsOutlined
            style={{ fontSize: 25, color: colorGantt }}
            onClick={() => setVisibleChromeColor(!visibleChromeColor)}
          />
        </>
      ),
      input: <Input />
    }
  ];

  return {
    planComFieldsHeader,
    planComFields2,
    planComFields3,
    planComFields4,
    planComFields5
  };
};

export default usePlanComFields;
