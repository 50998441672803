import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Descriptions, Card } from 'antd';

const ContextDisplay = ({ data }) => {
  const { t } = useTranslation();

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }

    return 1;
  };

  return (
    <>
      {data.factors.map((e) => {
        const { _id, files, type, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            <Card>
              <Descriptions key={_id} column={4} title={type} size="small">
                {Object.entries(rest).map(([key, value]) => {
                  return (
                    <Descriptions.Item
                      key={key}
                      label={t(`projects.form.items.context.factors.${key}`)}
                      span={setSpan(key)}
                    >
                      {value}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
};

ContextDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ContextDisplay.defaultProps = {
  data: null
};

export default ContextDisplay;
