import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Modal, Divider, Table, Button, Badge, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ManagePlanning from '../Projects/Projects/utilsProject/ManagePlanning/ManagePlanning';
import useDashboardContext from '../../contexts/DashboardContext';
import ModalForEventActivity from './ModalForEventActivity';
import ModalConfirm from './ModalConfirm';
import CreateUpdateForm from '../Projects/Projects/utilsProject/CreateUpdateForm';

const Actions = ({ id, data, purpose, title, actors, version, status }) => {
  const { t } = useTranslation();
  const modalRef = useRef();
  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const [dataUpdate, setDataUpdate] = useState();
  const {
    getProjects,
    updateResource,
    createResource,
    setPurpose,
    isLoading
  } = useDashboardContext();
  const [eventConfigData, setEventConfigData] = useState({});
  const [purposeArg, setPurposeArg] = useState();
  const [visibleEventActivity, setVisibleEventActivity] = useState(false);

  const columns = [
    {
      title: `${t(`datatable.column.type`)}`,
      dataIndex: 'type',
      key: 'type',
      width: 20
    },
    {
      title: `${t(`datatable.column.entitled`)}`,
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <Space>
          <span>{text}</span>
          <Badge
            title={`Changements de dates: ${record.data.events &&
              record.data.events.length}`}
            count={record.data.events && record.data.events.length}
            size="small"
          />
        </Space>
      )
    },
    {
      title: `${t(`datatable.column.actor`)}`,
      key: 'actor',
      dataIndex: 'actor',
      render: (el) => el,
      sorter: (a, b) => a.actor.localeCompare(b.actor)
    },
    {
      title: `${t(`datatable.column.date`)}`,
      dataIndex: 'date',
      sorter: (a, b) => {
        return new Date(a?.date) - new Date(b?.date);
      },
      key: 'date',
      render: (date) => date && moment(date).format('DD-MM-YYYY'),
      width: 30
    },
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      width: 40
    }
  ];

  const handleCancel = () => {
    setVisibleForm(false);
    setPurpose(null);
    getProjects();
  };
  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        deliverables: {
          ...dataT,
          planning: dataT.planning
            ? {
                ...dataT.planning,
                start_date: dataT.planning.start_date
                  ? moment(dataT.planning?.start_date)
                  : undefined,
                due_date: dataT.planning.due_date
                  ? moment(dataT.planning?.due_date)
                  : undefined
              }
            : undefined
        }
      })
    }
  };

  const setColor = (arg) => {
    if (arg) {
      return (
        <span
          style={{
            color: 'var(--primaryColor)',
            fontWeight: 'bold'
          }}
        >
          L
        </span>
      );
    }
    return (
      <span style={{ color: 'var(--secondaryColor)', fontWeight: 'bold' }}>
        T
      </span>
    );
  };

  const displayedData = [];
  // eslint-disable-next-line no-unused-expressions
  data?.deliverables
    .filter((deli) => deli.planning !== undefined)
    .forEach((deliverable) => {
      const tmpActor = actors.filter(
        (item) => item._id === deliverable.planning.actor
      )[0];
      if (
        purpose === 'Planifié' &&
        deliverable.planning.percentage !== 100 &&
        deliverable.planning.piloting_mark &&
        (deliverable.planning.status === 'Finalisé' ||
          deliverable.planning.status === 'Planifié' ||
          deliverable.planning.status === 'En attente de validation' ||
          deliverable.planning.status === 'Suspendu' ||
          deliverable.planning.status === 'A planifier' ||
          deliverable.planning.status === 'En cours')
      ) {
        const tmp = {
          _id: deliverable._id,
          data: deliverable,
          type: setColor(deliverable.planning?.is_task),
          label: `${deliverable?.description.substr(0, 25)}${
            deliverable?.description.length > 25 ? '[...]' : ''
          }`,
          actor: `${tmpActor?.group || ''} ${tmpActor?.first_name ||
            ''} ${tmpActor?.last_name || ''}`,
          date: deliverable.planning?.due_date,
          percentage: deliverable.planning?.percentage
        };
        displayedData.push(tmp);
      }
      if (
        purpose === 'Finalisé' &&
        deliverable.planning.percentage === 100 &&
        deliverable.planning.piloting_mark
      ) {
        const tmp = {
          _id: deliverable._id,
          data: deliverable,
          type: setColor(deliverable.planning?.is_task),
          label: `${deliverable?.description.substr(0, 25)}${
            deliverable?.description.length > 25 ? '[...]' : ''
          }`,
          actor: `${tmpActor?.group || ''}${tmpActor?.first_name ||
            ''} ${tmpActor?.last_name || ''}`,
          date: deliverable.planning?.due_date,
          percentage: deliverable.planning?.percentage
        };
        displayedData.push(tmp);
      }
    });
  const openModal = (eventConfigFromModal) => {
    setEventConfigData({ ...eventConfigFromModal, event: true });
    setPurposeArg('event');
    setVisibleForm(true);
  };
  const handleCancelForNewPlanning = () => {
    setEventConfigData();
    setPurposeArg();
    setVisibleForm(false);
    setDataToModal();
    setVisible(false);
    setPurpose();
    getProjects();
  };
  const handleSubmitNewPlanning = (values) => {
    if (status === 'VX') {
      if (purposeArg !== 'event') {
        modalRef.current.toggleModal(
          values,
          id,
          data,
          'manage_planning',
          'deliverables',
          handleCancelForNewPlanning
        );
      } else {
        const { event } = values;
        const result = {
          start_project: data.start_project,
          due_project: data.due_project,
          event,
          deliverables: [
            ...data.deliverables,
            eventConfigData.values.deliverables
          ]
        };
        createResource(
          result,
          id,
          eventConfigData.titleItem,
          null,
          handleCancelForNewPlanning
        );
      }
    } else {
      const resultDataToModalAndEvent = {
        deliverables: {
          ...values.deliverables
        }
      };
      updateResource(
        resultDataToModalAndEvent,
        id,
        data,
        'manage_planning',
        'deliverables',
        getProjects
      );
      setDataToModal();
      setVisible(false);
    }
  };

  const handleSubmitPlanning = (values) => {
    if (
      (dataToModal &&
        moment(dataToModal.planning.due_date).format('DD-MM-YYYY') !==
          values.deliverables.planning.due_date.format('DD-MM-YYYY')) ||
      (dataToModal &&
        dataToModal.planning.start_date &&
        moment(dataToModal.planning.start_date).format('DD-MM-YYYY') !==
          values.deliverables.planning.start_date.format('DD-MM-YYYY'))
    ) {
      setDataUpdate(values);
      setVisibleEventActivity(true);
    } else {
      const resultDataToModalAndEvent = {
        deliverables: {
          ...values.deliverables,
          events: dataToModal ? dataToModal.events : null
        }
      };
      updateResource(
        resultDataToModalAndEvent,
        id,
        data,
        'manage_planning',
        'deliverables',
        getProjects
      );
      setDataToModal();
      setVisible(false);
    }
  };
  return data ? (
    <Card
      style={{
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0'
      }}
      title={t(`projects.details.${title}`)}
      extra={
        purpose === 'Planifié' && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setPurposeArg('create');
              setVisible(true);
            }}
          >
            Action
          </Button>
        )
      }
    >
      <Divider />
      <Table
        rowKey="_id"
        onRow={(record) => ({
          onClick: () => {
            setPurposeArg('edit');
            setDataToModal(record.data);
            setVisible(true);
          }
        })}
        size="small"
        columns={columns}
        dataSource={displayedData}
        scroll={{ x: 500 }}
      />
      <ModalForEventActivity
        visible={visibleEventActivity}
        setVisible={setVisibleEventActivity}
        data={dataToModal}
        dataUpdate={dataUpdate}
        idProject={id}
        project={data}
        version={version}
        setDataToModal={setDataToModal}
        setVisibleForm={setVisible}
      />
      <Modal
        closable
        width={1600}
        destroyOnClose
        centered
        visible={visible}
        onOk={() => {
          setDataToModal();
          setVisible(false);
        }}
        onCancel={() => {
          setDataToModal();
          setVisible(false);
        }}
        footer={false}
      >
        <ManagePlanning
          purposeArg={purposeArg}
          dataItems={dataToModal}
          projectId={id}
          config={purposeArg === 'edit' ? config : null}
          handleCancel={() => {
            setVisible(false);
          }}
          handleSubmit={
            purposeArg === 'create'
              ? handleSubmitNewPlanning
              : handleSubmitPlanning
          }
        />
      </Modal>
      <ModalConfirm destroyOnClose openModal={openModal} ref={modalRef} />
      <Modal
        title={t(`projects.form.items.${eventConfigData?.titleItem}.title`)}
        visible={visibleForm}
        footer={false}
        destroyOnClose
        onCancel={handleCancel}
        width={1200}
      >
        <CreateUpdateForm
          purpose={purposeArg}
          resource="projects"
          title="event"
          handleCancel={handleCancel}
          projectId={id}
          data={eventConfigData?.data}
          config={null}
          index={null}
          fieldsName="event"
          handleSubmit={handleSubmitNewPlanning}
          isLoading={isLoading}
        />
      </Modal>
    </Card>
  ) : (
    <Card
      style={{
        background: 'var(--itemHoverBackground)',
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0',
        borderRadius: 50
      }}
      title={t(`projects.details.${title}`)}
    >
      <Divider />
    </Card>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  title: PropTypes.string,
  actors: PropTypes.oneOfType([PropTypes.array]),
  version: PropTypes.number,
  status: PropTypes.string
};

Actions.defaultProps = {
  id: null,
  data: null,
  title: null,
  purpose: null,
  actors: null,
  version: null,
  status: null
};

export default Actions;
