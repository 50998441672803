import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  Input,
  Divider,
  Button,
  DatePicker,
  InputNumber,
  Switch
} from 'antd';
import { PlusOutlined, BgColorsOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../../contexts/AuthContext';
import useErrorMessage from '../../../../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;
const useFieldsManagePlanning = (
  id,
  isFieldsLoading,
  setIsFieldsLoading,
  hiddenIfIsTask,
  setVisibleChromeColor,
  visibleChromeColor,
  form,
  colorGantt
) => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [newOption, setNewOption] = useState();
  const [dataList, seTDataList] = useState([]);
  const [enums, setEnums] = useState();
  const [actors, setActors] = useState();
  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    seTDataList([...dataList, newOption]);
  };

  const commonFields = [
    {
      name: ['deliverables', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'events'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'description'],
      rules: [{ required: true }],
      input: <Input row={6} />
    },
    {
      name: ['deliverables', 'global_type'],
      rules: [{ required: true }],
      input: enums ? (
        <Select
          showSearch
          loading={isFieldsLoading}
          style={{ width: 240 }}
          placeholder="Choisir un rôle"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={newOption}
                  onChange={onNameChange}
                />
                <Button onClick={() => addItem()}>
                  <PlusOutlined />
                  {t(`projects.form.deliverables.title`)}
                </Button>
              </div>
            </div>
          )}
        >
          {enums?.deliverables_natures.map((element) => (
            <Option key={element} value={element}>
              {element}
            </Option>
          ))}
          {dataList.map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['deliverables', 'comment'],
      input: <TextArea row={6} />
    }
  ];

  const planningFields = [
    ...(!hiddenIfIsTask
      ? [
          {
            name: ['deliverables', 'planning', 'start_date'],
            input: <DatePicker format={dateFormat} />
          }
        ]
      : []),
    {
      name: ['deliverables', 'planning', 'due_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} />
    },
    {
      name: ['deliverables', 'planning', 'actor'],
      input: (
        <Select showSearch style={{ width: 250 }}>
          {actors && actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option
                    style={{ width: 300 }}
                    key={value._id}
                    value={value._id}
                  >
                    {`${
                      value.group ? `[${value.group}] -` : ''
                    } ${value?.first_name || ''} ${value?.last_name ||
                      ''}  ${value?.role || ''}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'status'],
      input: (
        <Select showSearch>
          {enums &&
            enums.deliverables_status.map((value) => (
              <Option key={value} value={value}>
                {`${value}`}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'percentage'],
      input: <InputNumber min={0} max={100} />
    },
    {
      name: ['deliverables', 'planning', 'color'],
      colon: false,
      priorityLabel: true,
      label: (
        <>
          <Button type="link" style={{ color: '#000' }}>
            Couleur
          </Button>
          <BgColorsOutlined
            style={{ fontSize: 25, color: colorGantt }}
            onClick={() => setVisibleChromeColor(!visibleChromeColor)}
          />
        </>
      ),
      input: <Input />
    },
    {
      name: ['deliverables', 'planning', 'piloting_mark'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={form.getFieldValue([
            'deliverables',
            'planning',
            'piloting_mark'
          ])}
        />
      )
    },
    {
      name: ['deliverables', 'planning', 'gantt_mark'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={form.getFieldValue([
            'deliverables',
            'planning',
            'gantt_mark'
          ])}
        />
      )
    },
    {
      name: ['deliverables', 'planning', 'is_task'],
      valuePropName: 'checked',
      input: (
        <Switch
          defaultChecked={form.getFieldValue([
            'deliverables',
            'planning',
            'is_task'
          ])}
          checkedChildren="Livrable"
          unCheckedChildren="Tâche"
        />
      )
    }
  ];

  const utilityFields = [
    {
      name: ['deliverables', 'utility', 'use_for'],
      input: (
        <Select showSearch>
          {enums &&
            enums.deliverables_for.map((value) => (
              <Option key={value} value={value}>
                {`${value}`}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'utility', 'beneficiaries'],
      input: (
        <Select showSearch style={{ width: 250 }}>
          {actors && actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option
                    style={{ width: 300 }}
                    key={value._id}
                    value={value._id}
                  >
                    {`${
                      value.group ? `[${value.group}] -` : ''
                    } ${value?.first_name || ''} ${value?.last_name ||
                      ''}  ${value?.role || ''}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      )
    },
    {
      name: ['deliverables', 'utility', 'why'],
      input: <TextArea style={{ width: 250, maxWidth: 250 }} />
    },
    {
      name: ['deliverables', 'utility', 'coefficient'],
      input: <InputNumber min={1} max={100} />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getActors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?fields=actors.actors_list`
      });
      setActors(data.actors);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    if (id) await getActors();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { commonFields, planningFields, utilityFields };
};

export default useFieldsManagePlanning;
