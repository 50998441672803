/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Descriptions,
  Tag,
  Button,
  Collapse,
  Modal,
  Divider,
  Tabs,
  Card,
  Row
} from 'antd';
import { WechatOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useHandleResize from '../../../../utils/HandleResize';
import { EditIcon } from '../../../../utils/constants/customIcon';
import UpdateOnlyFederation from '../utilsProject/UpdateOnlyFederation';
import ButtonDeleteAll from '../utilsProject/ButtonDeleteAll';
import Gantt from '../../../../components/Widgets/Gantt';
import UpdateDuplicate from '../utilsProject/UpdateDuplicate';
import useProjectContext from '../../../../contexts/ProjectContext';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const PlanComDisplay = ({
  data,
  title,
  id,
  getProject,
  generateForm,
  actors,
  role
}) => {
  const { t } = useTranslation();
  const { width } = useHandleResize();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [visibleFederation, setVisibleFederation] = useState(false);
  const [dataDeliverables, setDataDeliverables] = useState();
  const [dataFederation, setDataFederation] = useState();
  const [dataChart, setDataChart] = useState();
  const [visibleDuplication, setVisibleDuplication] = useState(false);
  const [ganttData, setGanttData] = useState();
  const { project } = useProjectContext();
  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        deliverables_plan_com: {
          ...dataDeliverables
        }
      })
    }
  };
  const configFederation = {
    onGetResource: {
      setFields: (dataFede) => ({
        ...dataFede
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const getDataChart = (event) => {
    if (event && event.data && event.data._origin) {
      setDataChart({
        ...event.data._origin,
        task: event.data._origin.task.substring(24, 40)
      });
    }
  };
  const closeForm = () => {
    setDataDeliverables();
    setVisible(false);
    getProject();
  };
  const closeFormGantt = () => {
    setDataChart();
    setVisibleModal(false);
  };
  const openForm = (idRender, com) => {
    setVisible(idRender);
    setDataDeliverables({
      ...com,
      start_date: moment(com.start_date),
      due_date: moment(com.due_date)
    });
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };
  const setDataToFederation = (dataFede) => {
    setDataFederation(dataFede);
    setVisibleFederation(true);
  };

  const formatDataToChart = () => {
    let chartData = [];
    if (data.deliverables_plan_com !== undefined) {
      data.deliverables_plan_com.forEach((e) => {
        const {
          start_date,
          due_date,
          type_frequency,
          number_frequency,
          color
        } = e;
        if (
          (start_date, due_date !== null) &&
          type_frequency &&
          number_frequency &&
          e.duplicate.length
        ) {
          const result = e.duplicate.map((event) => ({
            ...event,
            description: e.name,
            status: e.name,
            task: `${e._id + e.name}`,
            start_date:
              start_date !== null &&
              moment(event.start_date).format('YYYY-MM-DD'),
            due_date: moment(event.due_date).format('YYYY-MM-DD'),
            init_start: moment(start_date).format('YYYY-MM-DD'),
            init_due: moment(due_date).format('YYYY-MM-DD'),
            color: color || '#000000',
            active: event.active,
            idEvent: e._id
          }));
          chartData = [...chartData, ...result];
        } else {
          const result = [
            {
              description: e.name,
              ...e,
              task: `${e._id + e.name}`,
              start_date:
                start_date !== null && moment(start_date).format('YYYY-MM-DD'),
              due_date: moment(due_date).format('YYYY-MM-DD'),
              color: color || '#000000',
              idEvent: e._id
            }
          ];
          chartData = [...chartData, ...result];
        }
      });
    } else {
      chartData = undefined;
    }
    setGanttData(chartData);
  };

  useEffect(() => {
    if (data) {
      formatDataToChart();
    }
  }, [data]);
  return (
    <>
      <Tabs>
        <TabPane
          key={t('projects.form.items.plan_com.event')}
          tab={t('projects.form.items.plan_com.event')}
        >
          {data.deliverables_plan_com.map((e) => {
            const { _id } = e;
            return visible === _id && dataDeliverables ? (
              generateForm(
                'edit',
                title,
                data,
                closeForm,
                'deliverables_plan_com',
                config
              )
            ) : (
              <React.Fragment key={_id}>
                <Collapse key={e._id}>
                  <Panel
                    style={{ marginBottom: 16 }}
                    header={
                      <Descriptions
                        extra={
                          <>
                            <span>
                              {e.federation ? (
                                <>
                                  <span style={{ marginRight: 10 }}>
                                    {`${t('projects.form.federation')}: `}
                                  </span>
                                  <span
                                    style={{
                                      color: 'var(--secondaryColor)',
                                      marginRight: 10,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {e.federation}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span style={{ marginRight: 10 }}>
                                    {`${t('projects.form.federation')}: `}
                                  </span>
                                  <Tag color="var(--primaryColor)">
                                    {t(
                                      'projects.form.federation.no_federation'
                                    )}
                                  </Tag>
                                </>
                              )}
                            </span>
                            <Button
                              disabled={project?.status === 'close'}
                              style={{ margin: 10 }}
                              type="primary"
                              icon={<WechatOutlined />}
                              onClick={() => setDataToFederation(e)}
                            />
                            <Divider type="vertical" />
                            {role ? (
                              <>
                                <ButtonDeleteAll
                                  purpose="matrice"
                                  projectId={id}
                                  title={title}
                                  titleId={data._id}
                                  matriceName="deliverables_plan_com"
                                  matriceId={_id}
                                  handleCancel={closeForm}
                                />
                                <Button
                                  disabled={project?.status === 'close'}
                                  onClick={() => openForm(_id, e)}
                                  size="small"
                                  type="link"
                                  style={{
                                    color: 'var(--secondaryColor)',
                                    top: -2
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                              </>
                            ) : null}
                          </>
                        }
                        column={4}
                        title={e.name}
                        size="small"
                      >
                        <Descriptions.Item
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.target`
                          )}
                        >
                          {actors.map((a) => {
                            if (a._id === e.target)
                              return `${
                                a.group ? `[${a.group}] -` : ''
                              } ${a?.first_name || ''} ${a?.last_name ||
                                ''}  ${a?.role || ''}`;
                            return '';
                          })}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.transmitter`
                          )}
                        >
                          {actors.map((a) => {
                            if (a._id === e.transmitter)
                              return `${
                                a.group ? `[${a.group}] -` : ''
                              } ${a?.first_name || ''} ${a?.last_name ||
                                ''}  ${a?.role || ''}`;
                            return '';
                          })}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.channel`
                          )}
                        >
                          {e.channel}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={4}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.goal_action`
                          )}
                        >
                          {e.goal_action}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={4}
                          label={t(
                            `projects.form.items.plan_com.deliverables_plan_com.goal_description`
                          )}
                        >
                          {e.goal_description}
                        </Descriptions.Item>
                      </Descriptions>
                    }
                  >
                    <Descriptions>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.in_charge_of`
                        )}
                      >
                        {actors.map((a) => {
                          if (a._id === e.in_charge_of)
                            return `${
                              a.group ? `[${a.group}] -` : ''
                            } ${a?.first_name || ''} ${a?.last_name ||
                              ''}  ${a?.role || ''}`;
                          return '';
                        })}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.type`
                        )}
                      >
                        {e.type}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.frequency`
                        )}
                      >
                        {!Number.isNaN(e?.type_frequency * e?.number_frequency)
                          ? `  Tous les ${e?.type_frequency *
                              e?.number_frequency} jours`
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.start_date`
                        )}
                      >
                        {e.start_date ? (
                          <Tag>{moment(e.start_date).format('DD-MM-YYYY')}</Tag>
                        ) : (
                          <Tag>Aucune donnée</Tag>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(
                          `projects.form.items.plan_com.deliverables_plan_com.due_date`
                        )}
                      >
                        {e.due_date ? (
                          <Tag>{moment(e.due_date).format('DD-MM-YYYY')}</Tag>
                        ) : (
                          <Tag>Aucune donnée</Tag>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>

                {dataFederation && (
                  <UpdateOnlyFederation
                    dataItems={dataFederation}
                    idVersion={data._id}
                    idMatrice={_id}
                    projectId={id}
                    config={configFederation}
                    visible={visibleFederation}
                    setVisible={setVisibleFederation}
                    getProject={getProject}
                  />
                )}
              </React.Fragment>
            );
          })}
        </TabPane>
        <TabPane
          key={t('projects.form.items.plan_com.gantt')}
          tab={t('projects.form.items.plan_com.gantt')}
        >
          <Card style={{ margin: '20px' }} className="gantt-card">
            <Gantt
              key="federation"
              data={ganttData}
              purpose="federation"
              height={300}
              width={width > 1600 ? 1500 : 800}
              onClick={getDataChart}
              lengthForFederation={data.deliverables_plan_com.length}
            />

            {dataChart ? (
              <Card
                style={{
                  width: 500,
                  height: 250,
                  position: 'absolute',
                  right: 0,
                  top: 0
                }}
                extra={
                  <Row justify="space-between">
                    <Button
                      onClick={() => closeFormGantt()}
                      style={{
                        color: 'var(--errorColor)',
                        background: 'transparent',
                        border: 'none',
                        marginLeft: 5,
                        boxShadow: 'none'
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </Row>
                }
                key={dataChart._id}
                title={dataChart.task}
              >
                <Divider />
                {visibleDuplication && (
                  <>
                    <UpdateDuplicate
                      setData={setDataChart}
                      visible={visibleDuplication}
                      setVisible={setVisibleDuplication}
                      data={dataChart}
                      idPlanCom={data?._id}
                    />
                    <Divider />
                  </>
                )}
                <Descriptions column={2} size="small">
                  {dataChart?.start_date !== null ? (
                    <Descriptions.Item
                      span={2}
                      label={t(
                        `projects.form.items.utility.deliverables.start_date`
                      )}
                    >
                      <Row justify="space-between">
                        <span>
                          {moment(dataChart.start_date).format(
                            'dddd DD-MM-YYYY'
                          ) !== null
                            ? `${moment(dataChart.start_date).format(
                                'dddd DD-MM-YYYY'
                              )}`
                            : ''}
                        </span>
                        <Button
                          disabled={project?.status === 'close'}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: -2,
                            marginLeft: 50
                          }}
                          onClick={() =>
                            setVisibleDuplication(!visibleDuplication)
                          }
                        >
                          <EditIcon />
                        </Button>
                      </Row>
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item
                    span={2}
                    label={t(
                      'projects.form.items.utility.deliverables.description'
                    )}
                  >
                    {dataChart.description}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={2}
                    label={t(
                      'projects.form.items.plan_com.deliverables_plan_com.in_charge_of'
                    )}
                  >
                    {actors !== null
                      ? actors.map((a) =>
                          a._id ===
                          data.deliverables_plan_com?.filter(
                            (event) => event._id === dataChart.idEvent
                          )[0]?.in_charge_of
                            ? `${a.first_name} ${a.last_name}`
                            : null
                        )
                      : null}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ) : null}
          </Card>
        </TabPane>
      </Tabs>

      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={1000}
          visible={visibleModal}
          footer={false}
          forceRender
          onCancel={closeFormModal}
        >
          {visibleModal &&
            generateForm(
              'edit',
              title,
              data,
              closeFormModal,
              'deliverables_plan_com',
              config || null
            )}
        </Modal>
      ) : null}
      {role ? (
        <Button
          disabled={project?.status === 'close'}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '24px auto',
            padding: '22px 16px'
          }}
          type="primary"
          onClick={() => openFormModal()}
        >
          <EditIcon />
          {t('projects.form.items.plan_com.addPlanCom')}
        </Button>
      ) : null}
    </>
  );
};

PlanComDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  actors: PropTypes.oneOfType([PropTypes.array]),
  role: PropTypes.bool
};

PlanComDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  actors: null,
  getProject: null,
  role: false
};

export default PlanComDisplay;
