import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from './AuthContext';
import {
  WhatIcon,
  WhyIcon,
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  UtilityIcon,
  PlanComIcon,
  RiskIcon
} from '../utils/constants/customIcon';

const ProjectContext = createContext({});

export const ProjectContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, token, user } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [visibleToShowFiles, setVisibleToShowFiles] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [visibleRole, setVisibleRole] = useState(false);
  const [title, setTitle] = useState();
  const [idItem, setIdItem] = useState();
  const [matriceFiles, setMatriceFiles] = useState();
  const [purpose, setPurpose] = useState();
  const [visible, setVisible] = useState(false);
  const [tempValue, setTempValue] = useState();

  const itemKeysInit = {
    what: <WhatIcon />,
    why: <WhyIcon />,
    prior: <PriorIcon />,
    actors: <ActorsIcon />,
    context: <ContextIcon />,
    cost: <CostIcon />,
    risks: <RiskIcon />,
    planing: <PlanningIcon />,
    utility: <UtilityIcon />,
    plan_com: <PlanComIcon />
  };

  const checkRole = () => {
    switch (user?.role) {
      case 'commercials:COMMERCIAL':
        if (project.status !== 'V0' && project.status !== 'V1')
          setVisibleRole(true);
        else setVisibleRole(false);
        break;
      case 'managers:PROJECT_MANAGER':
        setVisibleRole(true);
        break;
      case 'admins:ADMIN':
        setVisibleRole(true);
        break;
      default:
        setVisibleRole(false);
    }
  };
  const getProject = useCallback(async (id) => {
    setIsLoading(true);
    setLoadingMedia(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?populate=assignee, created_by`
      });
      setProject(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setLoadingMedia(false);
    setIsLoading(false);
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setPurpose(null);
    getProject(project._id);
  };

  const patchDuplicateEvent = async (duplicate, idVersion) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/manage-projects/duplicate/${project._id}/${idVersion}/${duplicate?.idEvent}/${duplicate?._id}`,
        body: duplicate
      });

      await getProject(project?._id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteDuplicateEvent = async (duplicate, idVersion) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/manage-projects/duplicate/${project._id}/${idVersion}/${duplicate?.idEvent}/${duplicate?._id}/delete`,
        body: duplicate
      });

      await getProject(project?._id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const handleMascotteClick = async (id) => {
    setIsLoading(true);
    const mascot_state =
      project?.mascot_state === 2 ? 0 : parseInt(project?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project?._id}`,
        body: { mascot_state }
      });
      await getProject(id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onChangeImg = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const deleteFiles = async (
    idFile,
    id,
    titleItem,
    idIt,
    matrice,
    idMatrice
  ) => {
    setLoadingMedia(true);
    try {
      await dispatchAPI('GET', {
        url: `/files/project/${id}/${titleItem}/${idIt}${
          matrice && idMatrice ? `/${matrice}/${idMatrice}` : ''
        }/${idFile}`
      });
      setVisibleToShowFiles(false);
      getProject(id);
      setLoadingMedia(false);
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) message(e.response.status);
    }
  };

  const getFilesByItem = async (body) => {
    if (body.length > 0) {
      setLoadingMedia(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/files?_id=${[...body]}`
        });
        setDataFiles(data);
        setLoadingMedia(false);
      } catch (e) {
        setLoadingMedia(false);
        if (e.response) message(e.response.status);
      }
    } else setDataFiles([]);
  };
  const setDataToModalFiles = (data, titlteFile, idIt, matrice, idMatrice) => {
    getFilesByItem(data);
    setIdItem(idIt);
    setTitle(titlteFile);
    if (matrice && idMatrice) {
      setMatriceFiles({ id: idMatrice, matrice });
    } else setMatriceFiles(undefined);
    setVisibleToShowFiles(true);
  };

  const displayFileMedia = async (url, type) => {
    setLoadingMedia(true);
    try {
      const response = await dispatchAPI('GET', {
        url,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type
      });
      setLoadingMedia(false);
      const urlModal = window.URL.createObjectURL(blob);
      window.open(urlModal);
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body, dataItem, titleItem, field, func) => {
    setIsLoading(true);
    const setUrl = {
      url: `/projects/${project?._id}/${titleItem}${
        dataItem?._id ? `/${dataItem._id}` : ''
      }${field ? `/${field}` : ''}${
        body[field]?._id ? `/${body[field]?._id}` : ''
      }`
    };
    try {
      await dispatchAPI('PATCH', {
        url: setUrl.url,
        body: field ? body[field] : { ...dataItem, ...body }
      });
      setIsLoading(false);
      func();
      handleCancel();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (
    body,
    titleItem,
    newValue,
    idIt,
    funcCancel
  ) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project?._id}${titleItem ? `/${titleItem}` : ''}${
          idIt ? `/${idIt}` : ''
        }`,
        body: { ...newValue, ...body }
      });
      setIsLoading(false);
      setPurpose(null);
      setTempValue(null);
      if (funcCancel) funcCancel();
      handleCancel();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const adddEventToDuplicateFederation = async (
    body,
    idProject,
    idFederationVersion,
    extraUrl
  ) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${idProject}/federation/${idFederationVersion}/federation/${extraUrl}`,
        body
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const deleteResourceFromItemOrMatrice = async (
    projectId,
    item,
    itemId,
    matriceName,
    matriceId
  ) => {
    const setUrl = {
      url: `projects/delete/${projectId}/${item}${itemId ? `/${itemId}` : ''}${
        matriceName ? `/${matriceName}` : ''
      }${matriceId ? `/${matriceId}` : ''}`
    };
    try {
      await dispatchAPI('PATCH', {
        url: setUrl.url
      });
      getProject(projectId);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        getProject,
        projects,
        setProjects,
        loading,
        setLoading,
        project,
        isLoading,
        setIsLoading,
        itemKeysInit,
        fileList,
        setFileList,
        onPreview,
        onChangeImg,
        deleteFiles,
        token,
        visibleToShowFiles,
        setVisibleToShowFiles,
        getFilesByItem,
        dataFiles,
        setDataFiles,
        displayFileMedia,
        loadingMedia,
        setLoadingMedia,
        checkRole,
        visibleRole,
        setVisibleRole,
        handleMascotteClick,
        title,
        setTitle,
        setDataToModalFiles,
        idItem,
        setIdItem,
        matriceFiles,
        setMatriceFiles,
        patchDuplicateEvent,
        t,
        purpose,
        setPurpose,
        createResource,
        updateResource,
        handleCancel,
        visible,
        setVisible,
        tempValue,
        setTempValue,
        adddEventToDuplicateFederation,
        deleteDuplicateEvent,
        deleteResourceFromItemOrMatrice
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(ProjectContext);
