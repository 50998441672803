import React from 'react';
import { Row, Col, Tag } from 'antd';
import moment from 'moment';

const useTime = (data, t) => {
  if (data.status !== 'V0' && data.status !== 'V' && data.manage_planning[2]) {
    const diff = moment(
      data.manage_planning[data.manage_planning.length - 1].end_project
    ).diff(
      moment(
        data.manage_planning[data.manage_planning.length - 1].start_project
      ),
      'days'
    );

    return (
      <Row style={{ marginBottom: 8 }} justify="start">
        <Col span={24}>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.10em',
              fontSize: 10,
              color: '#8c8c8c'
            }}
          >
            {t(`projects.details.time`)}
          </span>
        </Col>
        <Col span={24}>{`${diff} j`}</Col>
      </Row>
    );
  }
  return (
    <>
      <Col>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {t(`projects.details.time`)}
        </span>
      </Col>
      <Col>
        <Tag color="orange" style={{ marginRight: 16 }}>
          Non saisi
        </Tag>
      </Col>
    </>
  );
};

export default useTime;
