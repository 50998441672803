/* eslint-disable react/jsx-indent */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Col,
  Statistic,
  Progress,
  Modal,
  Row,
  Divider,
  Tooltip,
  Tag
} from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FederationComponent from './FederationComponent';
import Time from './ChartIndicators/Time';
import Cost from './ChartIndicators/Cost';
import Utility from './ChartIndicators/Utility';
import Federation from './ChartIndicators/Federation';
import useHandleResize from '../../utils/HandleResize';

const ShowIndicators = ({ project, openModal }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [purpose, setPurpose] = useState();
  const { width } = useHandleResize();

  const showModal = (key) => {
    setVisible(true);
    setPurpose(key);
  };

  const handleCancelBizchart = () => {
    setVisible(false);
  };
  const getTime = (data, status) => {
    if ((status === 'V1' || status === 'VX') && data[2]) {
      const diff = moment(
        data[data.length - 1].end_project !== null
          ? data[data.length - 1].end_project
          : 0
      ).diff(
        moment(
          data[data.length - 1].start_project !== null
            ? data[data.length - 1].start_project
            : 0
        ),
        'days'
      );

      const diffInit = moment(
        data[2].end_project !== null ? data[2].end_project : 0
      ).diff(
        moment(data[2].start_project !== null ? data[2].start_project : 0),
        'days'
      );
      const realize =
        (moment(Date.now()).diff(
          moment(
            data[data.length - 1].start_project !== null
              ? data[data.length - 1].start_project
              : 0
          ),
          'days'
        ) /
          diff) *
        100;
      const value = diff - diffInit;

      return (
        <Col
          xl={6}
          sm={12}
          xs={20}
          style={{
            height: '100%',
            minWidth: 120,
            margin: width > 1200 ? '0 24px' : '0 8px 16px'
          }}
          onClick={() => showModal('time')}
        >
          <Tooltip title={t(`projects.details.evolution_project`)}>
            <Row justify="space-between" align="top">
              <Col span={24}>
                <span
                  style={{
                    textTransform: 'uppercase',
                    letterSpacing: '.15em',
                    fontSize: 13
                  }}
                >
                  {t(`projects.details.time`)}
                </span>
              </Col>
              <Col span={12}>{` ${diff} ${t('dashboard.days')}`}</Col>
              <Col span={12}>
                <Statistic
                  value={value < 0 ? -value : value}
                  precision={0}
                  valueStyle={
                    value <= 0
                      ? { color: 'var(--addColor)', fontSize: 14 }
                      : { color: '#ff0000', fontSize: 15 }
                  }
                  prefix={
                    value < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />
                  }
                  suffix={t('dashboard.days')}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} justify="start">
              <Col span={24}>{t(`projects.details.realize`)}</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Progress
                  id="white-progress"
                  status="normal"
                  precision={0}
                  style={{ width: '200px' }}
                  percent={Math.round(realize) > 0 ? Math.round(realize) : 0}
                  strokeWidth={10}
                  type="line"
                />
              </Col>
              <Col span={24}>
                <span>
                  {`(${moment(Date.now()).diff(
                    moment(
                      data[data.length - 1].start_project !== null
                        ? data[data.length - 1].start_project
                        : 0
                    ),
                    'days'
                  )} ${t('dashboard.daysince')})`}
                </span>
              </Col>
            </Row>
          </Tooltip>
        </Col>
      );
    }
    return (
      <Col
        xl={6}
        sm={12}
        xs={20}
        style={{
          height: '100%',
          minWidth: 120,
          margin: width > 1200 ? '0 24px' : '0 8px 16px'
        }}
        onClick={() => showModal('time')}
      >
        <Tooltip title="Evolution de la durée du projet entre la V1 et Vn">
          <Row justify="space-between" align="top">
            <Col>
              <span
                style={{
                  textTransform: 'uppercase',
                  letterSpacing: '.15em',
                  fontSize: 13
                }}
              >
                {t(`projects.details.time`)}
              </span>
            </Col>
            <Col>0{t('dashboard.days')}</Col>
          </Row>
          <Row style={{ marginTop: 10 }} justify="start">
            <Col span={12}>{t(`projects.details.realize`)}</Col>
          </Row>
          <Row>
            <Col>
              <Progress
                id="white-progress"
                status="normal"
                precision={0}
                style={{ width: '200px' }}
                percent={0}
                strokeWidth={10}
                type="line"
              />
            </Col>
          </Row>
        </Tooltip>
      </Col>
    );
  };

  const getCost = (data, status) => {
    if (status === 'VX' || status === 'V1') {
      let charges = 0;
      let costs = 0;
      let chargesInit = 0;
      let costsInit = 0;
      if (data[2] !== null && data[2] !== undefined) {
        data[2].costs.forEach((p) => {
          if (p !== null) {
            chargesInit += p.charge;
            costsInit += p.cost_value;
          }
        });
      }
      if (data[data.length - 1] !== null) {
        data[data.length - 1].costs.forEach((e) => {
          charges += e.charge;
          costs += e.cost_value;
        });
      }

      return (
        <Col
          xl={4}
          md={6}
          sm={8}
          xs={20}
          style={{
            height: '100%',
            minWidth: 120,
            margin: width > 1200 ? '0 24px' : '0 12px'
          }}
          onClick={() => showModal('cost')}
        >
          <Row justify="start" style={{ marginBottom: 8 }}>
            <Col>
              <span
                style={{
                  textTransform: 'uppercase',
                  letterSpacing: '.15em',
                  fontSize: 13
                }}
              >
                {t(`projects.details.cost`)}
              </span>
            </Col>
          </Row>

          <Row justify="space-between">
            <Statistic
              id="white-statistic"
              title={`${costs} €`}
              value={costs - costsInit}
              precision={0}
              valueStyle={
                costs - costsInit <= 0
                  ? { color: 'var(--addColor)', fontSize: 14 }
                  : { color: '#ff0000', fontSize: 15 }
              }
              prefix={
                costs - costsInit > 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              suffix="€"
            />

            <Statistic
              id="white-statistic"
              title={`${charges}  ${t('dashboard.m_d')}`}
              value={charges - chargesInit}
              precision={0}
              valueStyle={
                charges - chargesInit <= 0
                  ? { color: 'var(--addColor)', fontSize: 14 }
                  : { color: '#ff0000', fontSize: 15 }
              }
              prefix={
                charges - chargesInit > 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              suffix={t('dashboard.m_d')}
            />
          </Row>
        </Col>
      );
    }
    return (
      <Col
        xl={4}
        md={6}
        sm={8}
        xs={20}
        style={{
          height: '100%',
          minWidth: 120,
          margin: width > 1200 ? '0 24px' : '0 12px'
        }}
        onClick={() => showModal('cost')}
      >
        <Row justify="start" style={{ marginBottom: 8 }}>
          <Col>
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.cost`)}
            </span>
          </Col>
        </Row>

        <Row justify="space-between">
          <Statistic
            id="white-statistic"
            title="0 €"
            precision={0}
            valueStyle={{ color: 'var(--addColor)', fontSize: '15px' }}
            suffix="€"
          />

          <Statistic
            id="white-statistic"
            title={t('dashboard.m_d')}
            // value={charges - chargesInit}
            precision={0}
            valueStyle={{ color: 'var(--addColor)', fontSize: '15px' }}
            prefix={<ArrowUpOutlined />}
            suffix={t('dashboard.m_d')}
          />
        </Row>
      </Col>
    );
  };

  const getUtility = (data) => {
    if (data !== null) {
      const allUtility = [];
      const variations = [];
      if (data.length !== 0 && data[data.length - 1] !== null) {
        data[data.length - 1].deliverables
          .filter((deli) => deli.utility !== undefined)
          .forEach((e) => {
            allUtility.push(e.utility?.coefficient);
          });
        if (data[data.length - 2] && data[data.length - 2] !== null) {
          data[data.length - 2].deliverables
            .filter((deli) => deli.utility)
            .forEach((e) => {
              variations.push(e.utility?.coefficient);
            });
        }
        const utility =
          allUtility.length !== 0
            ? allUtility.reduce((curr, prev) => curr + prev, 0) /
              allUtility.length
            : 50;
        const variation =
          variations.length !== 0
            ? variations.reduce((curr, prev) => curr + prev, 0) /
              variations.length
            : utility;
        return (
          <Col
            xl={4}
            md={8}
            sm={9}
            xs={18}
            style={{
              height: '100%',
              minWidth: 120,
              margin: width > 1200 ? '0 24px' : '16px 24px 8px'
            }}
            onClick={() => {
              showModal('utility');
            }}
          >
            <Row style={{ marginBottom: 8 }} justify="start">
              <span
                style={{
                  textTransform: 'uppercase',
                  letterSpacing: '.15em',
                  fontSize: 13
                }}
              >
                {t(`projects.details.utility`)}
              </span>
            </Row>
            <Row justify="space-around">
              <Col span={8}>
                <Progress
                  strokeColor={{
                    '50%': '#ffff00',
                    '51%': '#29ea12'
                  }}
                  id="white-progress"
                  percent={
                    allUtility.length > 0
                      ? Math.round(
                          allUtility.reduce((a, b) => a + b, 0) /
                            allUtility.length
                        )
                      : 0
                  }
                  width={80}
                  type="circle"
                />
              </Col>
              <Col span={6}>
                <Tooltip title={t('projects.details.fluctuation_utility')}>
                  <Statistic
                    style={{ marginTop: 30, right: 20 }}
                    id="white-statistic"
                    precision={0}
                    value={utility - variation}
                    valueStyle={
                      utility - variation >= 0
                        ? { color: 'var(--addColor)', fontSize: '17px' }
                        : { color: '#ff0000', fontSize: '17px' }
                    }
                    suffix="%"
                    prefix={
                      variation - utility > 0 ? (
                        <ArrowDownOutlined />
                      ) : (
                        <ArrowUpOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        );
      }
      return (
        <>
          <Col
            xl={4}
            md={8}
            sm={9}
            xs={20}
            style={{
              height: '100%',
              minWidth: 120,
              margin: width > 1200 ? '0 24px' : '16px 24px 8px'
            }}
            onClick={() => showModal('utility')}
          >
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.utility`)}
            </span>
            <Tag color="orange" style={{ marginRight: 16 }}>
              Aucune utilité saisie
            </Tag>
          </Col>
        </>
      );
    }
    return (
      <Col
        xl={4}
        md={8}
        sm={9}
        xs={18}
        style={{
          height: '100%',
          minWidth: 120,
          margin: width > 1200 ? '0 24px' : '16px 24px 8px'
        }}
        onClick={() => showModal('utility')}
      >
        <Row style={{ marginBottom: 8 }} justify="start">
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.15em',
              fontSize: 13
            }}
          >
            {t(`projects.details.utility`)}
          </span>
        </Row>
        <Row justify="center">
          <Progress
            strokeColor={{
              '0%': '#ffffff',
              '51%': '#ffffff'
            }}
            id="white-progress"
            percent={0}
            width={80}
            type="circle"
          />
        </Row>
      </Col>
    );
  };

  const listIndicators = {
    time: getTime(project.manage_planning, project.status),
    cost: getCost(project.cost, project.status),
    utility: getUtility(project.manage_planning)
  };

  const getChartIndicators = {
    time: <Time data={project} purpose={purpose} width={700} />,
    cost: <Cost data={project} purpose={purpose} />,
    utility: <Utility data={project} purpose={purpose} />,
    membership: <Federation data={project} purpose={purpose} />
  };
  return project.status === 'V1' ||
    project.status === 'VX' ||
    project.status === 'V0' ? (
    <Row
      align="top"
      style={{
        flexFlow: width > 1200 ? 'row nowrap' : 'row wrap',
        padding: '8px 0',
        minHeight: 100
      }}
    >
      {Object.entries(listIndicators).map(([key, value]) => {
        return project ? (
          <Fragment key={key}>
            {value}

            {key !== 'membership' ? (
              <Divider
                type="vertical"
                style={{
                  height: 80,

                  borderLeft: '8px solid var(--itemHoverBackground)',
                  margin: 0,
                  alignSelf: 'center'
                }}
              />
            ) : null}
          </Fragment>
        ) : (
          <>
            {t(`projects.details.${key}`)}
            <Tag>Item non saisi</Tag>
          </>
        );
      })}
      <>
        <FederationComponent openModal={openModal} project={project} />
      </>
      <Modal
        title={t(`projects.show.indicators.${purpose}`)}
        visible={visible}
        footer={false}
        width="1000px"
        onCancel={handleCancelBizchart}
      >
        <Row justify="center">{getChartIndicators[purpose]}</Row>
      </Modal>
    </Row>
  ) : (
    <Row gutter={16}>
      <Col span={19}>
        <Federation data={project} purpose="membership" />
      </Col>
      <Col span={5}>
        <FederationComponent openModal={openModal} project={project} />
      </Col>
    </Row>
  );
};

ShowIndicators.propTypes = {
  project: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  openModal: PropTypes.func
};

ShowIndicators.defaultProps = {
  project: null,
  openModal: null
};

export default ShowIndicators;
