import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal as AntdModal, Row, Col, Button, Spin } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import useProjectContext from '../../../../contexts/ProjectContext';

const ModalToConfirmDeleteWithVersionning = forwardRef(
  ({ openModal, ...rest }, ref) => {
    const { deleteResourceFromItemOrMatrice } = useProjectContext();
    const [on, setOn] = useState(false);
    const [isSpinning, setIsSpinning] = useState(true);
    const [dataToCheckKindOfConfig, setDataToCheckFindConfig] = useState();
    const { t } = useTranslation();
    const toggleModal = (
      projectId,
      item,
      itemId,
      matriceName,
      matriceId,
      dataTosend
    ) => {
      setDataToCheckFindConfig({
        projectId,
        item,
        itemId,
        matriceName,
        matriceId,
        dataTosend
      });
      if (dataToCheckKindOfConfig) setIsSpinning(false);

      setOn(!on);
    };
    useImperativeHandle(ref, () => ({
      toggleModal
    }));
    return (
      <AntdModal
        {...rest}
        centered
        visible={on}
        onOk={() => {
          toggleModal();
        }}
        onCancel={toggleModal}
        footer={null}
      >
        {dataToCheckKindOfConfig ? (
          <Row justify="center" style={{ margin: -20 }}>
            <Col span={20} style={{ margin: 10, textAlign: 'center' }}>
              <ExclamationCircleOutlined
                style={{ color: '#008cb9', fontSize: 35, margin: 20 }}
              />
              <p style={{ fontSize: 20, marign: 15 }}>
                {t('projects.alert.what_action_for_delete')}
              </p>
            </Col>
            <Col span={24} style={{ margin: 10 }}>
              <Row align="middle" justify="space-around">
                <Col style={{ textAlign: 'center' }} span={6}>
                  <Button
                    style={{ color: '#24bf4d' }}
                    onClick={() => {
                      openModal(
                        dataToCheckKindOfConfig.dataToSend,
                        dataToCheckKindOfConfig.titleItem,
                        null,
                        'create',
                        'event'
                      );
                      toggleModal();
                    }}
                  >
                    {t('projects.alert.create_version_from_delete')}
                  </Button>
                </Col>
                <Col style={{ textAlign: 'center' }} span={6}>
                  <Button
                    disabled={!dataToCheckKindOfConfig}
                    onClick={async () => {
                      await deleteResourceFromItemOrMatrice(
                        dataToCheckKindOfConfig.projectId,
                        dataToCheckKindOfConfig.item,
                        dataToCheckKindOfConfig.itemId,
                        dataToCheckKindOfConfig.matriceName,
                        dataToCheckKindOfConfig.matriceId
                      );
                      toggleModal();
                    }}
                    type="primary"
                  >
                    {t('projects.alert.in_current_version_delete')}
                  </Button>
                </Col>
                <Col style={{ textAlign: 'end', marginTop: 50 }} span={24}>
                  <Button
                    type="link"
                    danger
                    icon={<CloseOutlined />}
                    onClick={toggleModal}
                  >
                    Annuler
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Spin spinning={isSpinning} />
        )}
      </AntdModal>
    );
  }
);

ModalToConfirmDeleteWithVersionning.propTypes = {
  openModal: PropTypes.func.isRequired
};

ModalToConfirmDeleteWithVersionning.defaultProps = {};
export default ModalToConfirmDeleteWithVersionning;
