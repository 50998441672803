export const userRoles = {
  ACTOR: 'green',
  MANAGER: 'blue',
  'PROJECT_MANAGER': 'geekblue',
  ADMIN: 'red',
  'COMMERCIAL': 'magenta'
};

export const invoiceTypes = {
  PAY: 'blue',
  DEPOSIT: 'green',
  CREDIT: 'magenta'
};

export const ticketStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const ticketsPriorities = ['NORMAL', 'HIGH', 'URGENT'];

export const ticketsPrioritiesColor = {
  LOW: 'blue',
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsTypes = ['PICKUP', 'THEFT', 'LOSS', 'DAMAGED'];

export const ticketsTypesColor = {
  PICKUP: 'green',
  THEFT: 'volcano',
  LOSS: 'cyan',
  DAMAGED: 'red'
};

export const tasksStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  CLOSED: 'red'
};

export const tasksPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const projectsStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const companiesTypes = {
  CUSTOMERS: 'blue',
  SUPPLIERS: 'green'
};

export const userPermissions = {
  devTeam: 'yellow'
};
