import React, { useState } from 'react';
import { Col, Button, Form, Input, Select, Upload, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import useScanContext from './ScanContext';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};

const { Dragger } = Upload;

const ScanForm = ({ loading, onChange, onFileUpload }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    enums,
    setEnums,
    fields,
    setFile,
    setBase64,
    setZoningField
  } = useScanContext();
  const [disabled, setDisabled] = useState(true);
  const [input, setInput] = useState('');

  const props = {
    name: 'file',
    multiple: false,
    accept: '.pdf',
    showUploadList: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    async onChange(info) {
      // Fake upload: just to get File instance
      const { status, originFileObj } = info.file;
      if (status === 'done' || status === 'error') {
        setFile(originFileObj);
        const base64 = await toBase64(originFileObj);
        setBase64(base64);
        const type = Object.keys(fields)[0];
        setZoningField(Object.keys(fields[type])[0]);
        onFileUpload();
      }
    }
  };

  const handleAddOption = (key) => {
    setEnums({ ...enums, [key]: [...enums[key], input] });
    setInput('');
  };

  const handleSelectChange = () => {
    const type = form.getFieldValue('type');
    const supplier = form.getFieldValue('supplier');

    if (fields && type && supplier) {
      setDisabled(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Form form={form} {...layout} onValuesChange={onChange}>
      <Form.Item
        name="type"
        label={t('scan.document.form.type')}
        rules={[{ required: true }]}
      >
        <Select
          options={
            enums.types &&
            enums.types.map((type) => ({
              label: type,
              value: type
            }))
          }
          loading={loading}
          onChange={handleSelectChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={input}
                  onChange={(event) => setInput(event.target.value)}
                />
                <Button
                  type="link"
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleAddOption('types')}
                >
                  <PlusOutlined /> Add item
                </Button>
              </div>
            </div>
          )}
        />
      </Form.Item>
      <Form.Item
        name="supplier"
        label={t('scan.document.form.supplier')}
        rules={[{ required: true }]}
      >
        <Select
          options={
            enums.suppliers &&
            enums.suppliers.map((supplier) => ({
              label: supplier,
              value: supplier
            }))
          }
          loading={loading}
          onChange={handleSelectChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto' }}
                  value={input}
                  onChange={(event) => setInput(event.target.value)}
                />
                <Button
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleAddOption('suppliers')}
                >
                  <PlusOutlined /> Add item
                </Button>
              </div>
            </div>
          )}
        />
      </Form.Item>

      <Col span={12} offset={6} style={{ marginBottom: 10 }}>
        <Dragger {...props} disabled={disabled}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: 'lightgrey' }} />
          </p>
          <Button type="primary" disabled={disabled}>
            {t('buttons.upload')}
          </Button>
        </Dragger>
      </Col>
    </Form>
  );
};

export default ScanForm;
