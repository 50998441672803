import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Row,
  Spin,
  Tabs,
  Modal,
  Tooltip,
  Typography
} from 'antd';
import HomeResourceTab from './HomeResourceTab';
import useAuthContext from '../../contexts/AuthContext';
import useHomeContext from '../../contexts/HomeContext';
import ButtonCreateProject from '../Projects/Projects/utilsProject/ButtonCreateProject';
import ButtonDeleteAll from '../Projects/Projects/utilsProject/ButtonDeleteAll';
import { FilterIcon, SettingsIcon } from '../../utils/constants/customIcon';
import setIconStatus from '../../utils/constants/setIconStatus';
import useErrorMessage from '../../utils/ErrorMessage';
import ShowIndicators from './ShowIndicators';
import CreateUpdateForm from '../Projects/Projects/utilsProject/CreateUpdateForm';
import ManageFederation from '../Projects/Projects/utilsProject/ManageFederation';

const { Title } = Typography;

const Home = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const {
    getProjects,
    projects,
    setLoading,
    loading,
    setProjects
  } = useHomeContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [visibleFederation, setVisibleFederation] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  const [title, setTitle] = useState();
  const [purpose, setPurpose] = useState();
  const [fieldsName, setFieldName] = useState();
  const [KeyTab, setKeyTab] = useState();
  const [config, setConfig] = useState();
  const { TabPane } = Tabs;

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProjects();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (projects) setKeyTab(projects[0]?._id);
  }, [projects]);

  const updateFederation = async (id, values) => {
    setLoading(false);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${id}`,
        body: values
      });
      getProjects();
      setLoading(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const { Search } = Input;

  const Header = () => {
    return (
      <Row
        justify="space-around"
        align="middle"
        gutter={[16, 48]}
        style={{ paddingRight: '16px' }}
      >
        <Col style={{ marginRight: 8 }}>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Col>
          <Tooltip title={t('breadcrumbs.filter')}>
            <Button type="link" style={{ padding: 0 }}>
              <FilterIcon />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={t('profile.settings.title')}>
            <Button type="link" style={{ padding: 0 }}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Col>
        <Col style={{ marginLeft: 16 }}>
          <ButtonCreateProject />
        </Col>
        <Col>
          <ButtonDeleteAll
            purpose="project"
            projectId={KeyTab}
            handleCancel={getProjects}
            style={{ color: 'var(--textColorSecondary)' }}
          />
        </Col>
      </Row>
    );
  };
  const getKeyTab = (activekey) => {
    const resultFilter = projects.filter((ele) => ele._id !== activekey);
    resultFilter.unshift(projects.filter((el) => el._id === activekey)[0]);
    setProjects(resultFilter);
    setKeyTab(activekey);
  };

  const handleCancelFederation = () => {
    setVisibleFederation(false);
    getProjects();
  };
  const openModal = (dataT, titleModal, index, action, fields, configArg) => {
    setModalConfig(dataT);
    setTitle(titleModal);
    setPurpose(action);
    setFieldName(fields);
    setConfig(configArg);
    setVisibleFederation(true);
  };

  const handleSubmit = (values, dataArg) => {
    if (title === 'note') {
      handleCancelFederation();
      updateFederation(dataArg._id, values);
    }
  };

  return projects ? (
    <>
      <Modal
        title={t(`projects.form.items.${title}.title`)}
        visible={visibleFederation}
        width={1000}
        footer={false}
        onCancel={handleCancelFederation}
      >
        {title === 'membership' ? (
          <ManageFederation
            dataItems={modalConfig}
            projectId={modalConfig?._id}
            config={config}
            handleCancel={handleCancelFederation}
          />
        ) : (
          <CreateUpdateForm
            purpose={purpose}
            resource="projects"
            title={title}
            handleCancel={handleCancelFederation}
            projectId={modalConfig?._id}
            data={modalConfig}
            config={config}
            fieldsName={fieldsName}
            handleSubmit={handleSubmit}
            isLoading={loading}
          />
        )}
      </Modal>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`projects.title_chief`)}
      </div>

      <Tabs
        onChange={(activeKey) => getKeyTab(activeKey)}
        tabBarExtraContent={Header()}
      >
        {projects.length > 0 ? (
          projects.map((project) => {
            return (
              <TabPane
                tab={setIconStatus(project.name, project.status, true, 29)}
                key={project._id}
              >
                <HomeResourceTab
                  data={project || null}
                  getProject={getProjects}
                  indicators={
                    <ShowIndicators
                      openModal={openModal}
                      key={project._id}
                      project={project}
                    />
                  }
                  openModal={openModal}
                />
              </TabPane>
            );
          })
        ) : (
          <Row justify="center">
            <Title style={{ textAlign: 'center' }} level={2}>
              {t('errors.message.no_projects')}
            </Title>
          </Row>
        )}
      </Tabs>
    </>
  ) : (
    <Spin />
  );
};

export default Home;
