/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Layout, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Actions from './ActionsComponent';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import MascotteRouge from '../../assets/images/PersoRouge.png';
import MascotteOrange from '../../assets/images/PersoOrange.png';
import MascotteVert from '../../assets/images/PersoVert.png';

import {
  WhatIcon,
  WhyIcon,
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  UtilityIcon,
  PlanComIcon,
  RiskIcon
} from '../../utils/constants/customIcon';

const HomeResourceTab = ({ data, indicators, openModal, getProject }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { Content } = Layout;
  const listItem = {
    what: <WhatIcon />,
    why: <WhyIcon />,
    prior: <PriorIcon />,
    actors: <ActorsIcon />,
    context: <ContextIcon />,
    cost: <CostIcon />,
    risks: <RiskIcon />,
    planing: <PlanningIcon />,
    utility: <UtilityIcon />,
    plan_com: <PlanComIcon />
  };
  const configNote = {
    onGetResource: {
      // eslint-disable-next-line no-shadow
      setFields: (data) => ({
        note: data.note
      })
    }
  };

  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];

  const isReductScreen = useMediaQuery({ query: '(max-width: 1300px)' });

  const handleMascotteClick = async () => {
    const mascot_state =
      data?.mascot_state === 2 ? 0 : parseInt(data?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${data?._id}`,
        body: { mascot_state }
      });
      await getProject();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const StyledContent = styled(Content)`
    width: 100%;
    margin-top: 16px;
    padding: 10px 24px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

    a {
      color: var(--textColorSecondary);
      transition: color 0.3s;

      &:hover {
        color: var(--primaryColor);
      }
    }
  `;

  const IndicatorWrapper = styled(Content)`
    padding: 15px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: var(--textColorSecondary);
  `;

  return (
    <>
      <Row>
        <Col span={isReductScreen ? 24 : 20}>
          <IndicatorWrapper>{indicators}</IndicatorWrapper>
        </Col>
        <Col
          span={isReductScreen ? 0 : 4}
          style={{
            display: isReductScreen ? 'none' : 'flex',
            justifySelf: 'end'
          }}
        >
          <div
            onClick={() => handleMascotteClick()}
            style={{
              background: '#D3D3D3',
              borderRadius: 5,
              height: '100%',
              width: '100%',
              marginLeft: 16,
              boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt="Mascotte de CitizenCan"
              src={mascotteArray[data?.mascot_state]}
              height="150px"
            />
          </div>
        </Col>
      </Row>
      <StyledContent>
        <Row style={{ justifyContent: 'space-between' }} gutter={[4, 4]}>
          {Object.entries(listItem).map(([key, value]) => (
            <Col key={key}>
              <Link
                to={{
                  pathname: `projects/projects/show/${data._id}`,
                  item: key
                }}
                params={{ item: key }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Row style={{ marginBottom: 7 }}>{value}</Row>
                <Row style={{ fontSize: 12 }}>
                  {t(`projects.form.items.${key}.title`)}
                </Row>
              </Link>
            </Col>
          ))}
        </Row>
      </StyledContent>
      <Row justify="space-between" gutter={24}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Card
                onClick={() =>
                  openModal(data, 'note', null, 'note', null, configNote)
                }
                style={{
                  background: 'var(--itemHoverBackground)',
                  boxShadow: 'none',
                  color: 'var(--textColor)',
                  fontSize: '1.1em',
                  padding: 0,
                  margin: '16px 0 24px 0',
                  borderRadius: 50
                }}
              >
                <InfoCircleOutlined
                  style={{
                    margin: '0 12px',
                    fontSize: '1.5em',
                    position: 'relative',
                    top: 2
                  }}
                />
                {data.note !== null ? `${data.note.substr(0, 80)} [...]` : null}
              </Card>
            </Col>
          </Row>
          <Row justify="space-between" gutter={24} style={{ marginBottom: 4 }}>
            <Col xl={12} sm={24} xs={24}>
              <Actions
                id={data?._id}
                title="action_done"
                purpose="Finalisé"
                data={
                  data.manage_planning[data.manage_planning.length - 1] || null
                }
                actors={data.actors[data.actors.length - 1]?.actors_list || []}
              />
            </Col>
            <Col xl={12} sm={24} xs={24}>
              <Actions
                id={data?._id}
                title="action_to_do"
                purpose="Planifié"
                data={
                  data.manage_planning[data.manage_planning.length - 1] || null
                }
                actors={data.actors[data.actors.length - 1]?.actors_list || []}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

HomeResourceTab.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  indicators: PropTypes.element,
  openModal: PropTypes.func,
  getProject: PropTypes.func
};

HomeResourceTab.defaultProps = {
  data: null,
  indicators: null,
  openModal: null,
  getProject: null
};

export default HomeResourceTab;
