import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row, Button, Col, Modal, Select, InputNumber, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';
import useProjectContext from '../../../../contexts/ProjectContext';
import { formItemLayout } from '../../../../utils/constants/formLayout';

const { Option } = Select;

const ManageFederation = ({
  dataItems,
  projectId,
  idVersion,
  config,
  visible,
  setVisible
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(true);
  const { dispatchAPI } = useAuthContext();
  const { getProject } = useProjectContext();

  const updateFederation = async (values) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${projectId}/plan_com/${idVersion}/deliverables_plan_com/${dataItems._id}`,
        body: { ...values }
      });
      setIsLoading(false);
      form.resetFields();
      //   handleCancel();
      //   setDataNull();
      getProject(projectId);
      setVisible(!visible);
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const setValues = () => {
    if (config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(dataItems)
          : dataItems
      );
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (config !== null && dataItems !== null) {
      setValues();
    }
    setIsLoading(false);
  }, [dataItems]);

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });
  const handleSubmit = (values) => {
    updateFederation({ ...dataItems, ...values });
  };

  return (
    <Modal
      destroyOnClose
      title={t('projects.form.plan_com')}
      visible={visible}
      onCancel={() => setVisible(!visible)}
      footer={null}
    >
      <Form
        {...formItemLayout}
        onFinish={(values) => handleSubmit(values)}
        name={t('projects.form.items.membership.title')}
        form={form}
      >
        <Spin spinning={isLoading}>
          <Form.Item label="impact" name={['impact']}>
            <Select>
              <Option value={1}>{t('projects.form.minor')}</Option>
              <Option value={2}>{t('projects.form.medium')}</Option>
              <Option value={3}>{t('projects.form.major')}</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Federation" name={['federation']}>
            <InputNumber min={0} max={100} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Row
              justify="end"
              wrap={false}
              style={{
                position: 'relative',
                right: isReductScreen ? '-140%' : '-200%'
              }}
            >
              <Col offset={12}>
                <Button
                  style={{ margin: '0 6px' }}
                  type="link"
                  danger
                  onClick={() => setVisible(!visible)}
                >
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                  </span>
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="add" htmlType="submit">
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.save')} `}
                  </span>
                  <CheckOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};

ManageFederation.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  fieldsName: PropTypes.string,
  idVersion: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  idMatrice: PropTypes.string,
  setVisible: PropTypes.func.isRequired
};
ManageFederation.defaultProps = {
  dataItems: null,
  title: null,
  config: null,
  fieldsName: null,
  idVersion: null,
  idMatrice: null
};

export default ManageFederation;
