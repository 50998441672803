import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Modal, Form, Row, Input, Col, Tooltip, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useFields from '../fieldsItems/itemsFields';
import { CreateIcon } from '../../../../utils/constants/customIcon';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';

const ButtonCreateProject = () => {
  const fields = useFields('event', null);
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { message, success } = useErrorMessage();
  const [form] = Form.useForm();

  const createResource = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: 'projects',
        body
      });
      success('Votre projet à été crée');
      history.push(`/projects/projects/show/${response.data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    await createResource(values);
    setVisible(false);
  };

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <Row justify="space-around" gutter={[48, 48]}>
      <Col>
        <Row align="middle">
          <Tooltip title={t(`breadcrumbs.create`)}>
            <Button
              type="link"
              style={{
                padding: '4px 0',
                top: -1
              }}
              onClick={showModal}
            >
              <CreateIcon />
            </Button>
          </Tooltip>
        </Row>
        <Modal
          visible={visible}
          title="Créer un nouveau projet"
          width={800}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item
              name={['name']}
              label={t(`projects.form.project_name`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['code_project']}
              label={t(`projects.form.code_project`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['customer']}
              label={t(`projects.form.customer`)}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Divider />
            {fields.map((field) => generateFields('projects', field))}
            <Divider />
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end" wrap={false}>
                <Col>
                  <Button
                    style={{ margin: '0 6px' }}
                    type="link"
                    danger
                    onClick={handleCancel}
                  >
                    <span
                      style={{
                        display: isReductScreen ? 'none' : 'inline-block'
                      }}
                    >
                      {`${t('buttons.cancel')} `}
                    </span>
                    <CloseOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button type="add" htmlType="submit">
                    <span
                      style={{
                        display: isReductScreen ? 'none' : 'inline-block'
                      }}
                    >
                      {`${t('buttons.save')} `}
                    </span>
                    <CheckOutlined />
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  );
};

ButtonCreateProject.propTypes = {
  purpose: PropTypes.string,
  title: PropTypes.string,
  projectId: PropTypes.string,
  matriceName: PropTypes.string,
  handleCancel: PropTypes.func,
  titleId: PropTypes.string,
  matriceId: PropTypes.string
};

ButtonCreateProject.defaultProps = {
  title: null,
  purpose: null,
  projectId: null,
  matriceName: null,
  handleCancel: null,
  titleId: null,
  matriceId: null
};

export default ButtonCreateProject;
