import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Row, Button, Col } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from '../fieldsItems/itemsFields';

const ManageFederation = ({
  dataItems,
  projectId,
  config,
  handleCancel,
  getProject,
  setDataNull
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldsLoading, setIsFieldsLoading] = useState();
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState();
  const fieldsFederation = useFields(
    'membership',
    projectId,
    enums,
    isFieldsLoading
  );
  const updateFederation = async (values) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${projectId}/plan_com/${
          dataItems.plan_com[dataItems.plan_com.length - 1]?._id
        }/deliverables_plan_com`,
        body: { ...values }
      });
      setIsLoading(false);
      form.resetFields();
      handleCancel();
      setDataNull();
      getProject();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    setIsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const setValues = () => {
    if (config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(dataItems.federation)
          : dataItems.federation
      );
    }
  };

  useEffect(() => {
    if (config !== null && dataItems?.federation !== null) {
      setValues();
    }
  }, []);

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });
  const handleSubmit = (values) => {
    updateFederation(values);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        {...formItemLayout}
        onFinish={(values) => handleSubmit(values)}
        name={t('projects.form.items.membership.title')}
        form={form}
      >
        {enums ? (
          <>
            {fieldsFederation.map((field) => generateFields('projects', field))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Row
                justify="end"
                wrap={false}
                style={{
                  position: 'relative',
                  right: isReductScreen ? '-140%' : '-200%'
                }}
              >
                <Col offset={12}>
                  <Button
                    style={{ margin: '0 6px' }}
                    type="link"
                    danger
                    onClick={() => handleCancel()}
                  >
                    <span
                      style={{
                        display: isReductScreen ? 'none' : 'inline-block'
                      }}
                    >
                      {`${t('buttons.cancel')} `}
                    </span>
                    <CloseOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button type="add" htmlType="submit">
                    <span
                      style={{
                        display: isReductScreen ? 'none' : 'inline-block'
                      }}
                    >
                      {`${t('buttons.save')} `}
                    </span>
                    <CheckOutlined />
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Spin>
  );
};

ManageFederation.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  fieldsName: PropTypes.string,
  idVersion: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  idMatrice: PropTypes.string,
  getProject: PropTypes.func.isRequired,
  setDataNull: PropTypes.func.isRequired
};
ManageFederation.defaultProps = {
  dataItems: null,
  title: null,
  config: null,
  fieldsName: null,
  idVersion: null,
  idMatrice: null
};

export default ManageFederation;
