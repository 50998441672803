import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

const usePlaningFields = () => {
  const dateFormat = 'DD-MM-YYYY';
  const today = moment();
  const planingFields = [
    {
      name: ['start_project'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['end_project'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    }
  ];

  return { planingFields };
};

export default usePlaningFields;
