/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Coord, Shape } from 'bizcharts';
import {
  CloseOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CameraOutlined
} from '@ant-design/icons';
import { Row, Col, Slider, Button, DatePicker, Spin, message } from 'antd';
import html2canvas from 'html2canvas';
import Logo from '../../assets/images/blue-drop-upsidedown.png';

const { RangePicker } = DatePicker;

const Gantt = ({
  data,
  onClick,
  purpose,
  lengthForFederation,
  defaultLengthSize,
  selectedDates
}) => {
  const [dataChart, setDataChart] = useState();
  const { t } = useTranslation();
  const [scaleChart, setScaleChart] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [widthChart, setWidthChart] = useState({
    width: 2500,
    height: 200
  });

  const setScale = () => {
    setIsLoading(true);
    setScaleChart({
      range: {
        alias: 'Temps',
        tickCount: 20,
        type: 'time',
        formatter: (timeValue) => moment(timeValue).format('DD-MM-YYYY')
      }
    });
    setIsLoading(false);
  };
  const values = [
    'En cours',
    'Finalisé',
    'Planifié',
    'A planifier',
    'Suspendu',
    'Annulé',
    'En attente de validation'
  ];

  const colors = ['#000'];

  const getData = (sort = false) => {
    let sortDataChart;
    if (sort) {
      sortDataChart = data.sort(
        (a, b) => new Date(b?.start_date) - new Date(a?.start_date)
      );
    } else {
      sortDataChart = data.sort(
        (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
      );
    }

    setDataChart(sortDataChart);
    if (sortDataChart !== undefined) {
      sortDataChart.forEach((obj) => {
        obj.range = [obj.start_date, obj.due_date];
        obj.status = values[values.indexOf(obj.status)];
      });
    }
  };

  useEffect(() => {
    if (data) {
      setScale();
      setWidthChart({
        ...widthChart,
        height: lengthForFederation
          ? 200 + lengthForFederation * 30
          : 200 + data.length * 30
      });
      getData();
    }
  }, [data]);

  useEffect(() => {
    Shape.registerShape('point', 'image', {
      draw(cfg, container) {
        cfg.points = this.parsePoints(cfg.points);
        const avatarHeight = cfg.size;
        const avatarWidth = cfg.size - 5;
        const avatarX = cfg.points[0].x;
        const avatarY = cfg.points[0].y - avatarHeight / 2;

        return container.addShape('image', {
          attrs: {
            x: avatarX - avatarWidth + 10,
            y: avatarY - avatarHeight + 20,
            width: avatarWidth,
            height: avatarHeight,
            img: cfg.shape[1]
          }
        });
      }
    });
  }, [dataChart]);

  const handleChangeRange = (value) => {
    if (value) {
      const result = [];
      for (let i = 0; i < dataChart?.length; i += 1) {
        if (
          (moment(dataChart[i].start_date).diff(moment(value[0]), 'days') >=
            0 &&
            moment(dataChart[i].start_date).diff(moment(value[1]), 'days') <=
              0) ||
          (moment(dataChart[i].due_date).diff(moment(value[0]), 'days') >= 0 &&
            moment(dataChart[i].due_date).diff(moment(value[1]), 'days') <=
              0) ||
          (moment(dataChart[i].start_date) <= moment(value[0]) &&
            moment(dataChart[i].due_date) >= moment(value[1]))
        ) {
          result.push(dataChart[i]);
        }
      }
      setDataChart(result);
      setScale();
    } else {
      getData();
    }
  };

  const label = {
    formatter(text) {
      return text.substring(24);
    }
  };

  const onHandleChangeSize = (value) => {
    setWidthChart({
      ...widthChart,
      width: value
    });
    setScale();
  };

  const getScreenShotOfChart = async () => {
    try {
      const result = await html2canvas(
        document.querySelector(`#${purpose}`)
      ).then((canvas) => {
        return canvas.toDataURL();
      });
      const a = document.createElement('a');
      a.href = result;
      a.download = `gantt.png`;
      a.click();
    } catch (error) {
      message.error('Problème de capture, veuillez recharger la page');
    }
  };
  return (
    <>
      <Spin spinning={isLoading}>
        <Row
          justify="space-around"
          style={{
            marginBottom: 20,
            marginTop: 20
          }}
        >
          <Col span={6}>
            <Button
              type="link"
              style={{ color: 'var(--primaryColor)' }}
              onClick={() => {
                getData();
                setScale();
              }}
            >
              {t('projects.show.gantt.chronological')}
            </Button>
            <Button
              type="link"
              style={{ color: 'var(--primaryColor)' }}
              onClick={() => {
                getData(true);
                setScale();
              }}
            >
              {t('projects.show.gantt.antechronological')}
            </Button>
          </Col>
          <Col style={{ textAlign: 'center' }} span={8}>
            <RangePicker
              syle={{ width: 250 }}
              defaultValue={
                selectedDates.length
                  ? [moment(selectedDates[0]), moment(selectedDates[1])]
                  : []
              }
              onChange={(value) => handleChangeRange(value)}
            />
            <p style={{ fontSize: 15, color: 'var(--disabledColor)' }}>
              {t('projects.show.gantt.filter_description')}
            </p>
          </Col>
          <Col span={4}>
            <Row>
              <ZoomOutOutlined
                style={{ fontSize: 20, color: 'var(--disabledColor)' }}
              />
              <Slider
                style={{ width: 200 }}
                min={1000}
                max={4000}
                step={10}
                defaultValue={widthChart.width}
                onChange={onHandleChangeSize}
              />
              <ZoomInOutlined
                style={{ fontSize: 20, color: 'var(--disabledColor)' }}
              />
            </Row>
          </Col>
          {purpose !== 'dashboard' && (
            <Col style={{ textAlign: 'center' }} span={4}>
              <CameraOutlined
                style={{ fontSize: 25, color: 'var(--primaryColor)' }}
                onClick={() => getScreenShotOfChart()}
              />
            </Col>
          )}
        </Row>
        <div
          id={purpose}
          style={{
            overflowY: 'auto',
            maxHeight: 600
          }}
        >
          {dataChart && scaleChart ? (
            <Chart
              onClick={onClick || null}
              theme="light"
              padding="auto"
              width={defaultLengthSize || widthChart.width}
              height={widthChart.height}
              data={dataChart || []}
              autoFit
              scale={scaleChart}
            >
              <Axis name="task" label={label} />
              <Tooltip shared />
              <Axis name="range" />
              <Coord transpose scale={[1, -1]} />
              <Geom
                size={20}
                tooltip={[
                  'status*due_date*description*start_date*init_start*init_due',
                  (
                    status,
                    due_date,
                    description,
                    start_date,
                    init_start,
                    init_due
                  ) => {
                    if (purpose === 'federation') {
                      return {
                        name: status,
                        title: description,
                        value: `${
                          init_start !== init_due
                            ? `Date de début: ${moment(init_start).format(
                                'dddd DD-MM-YYYY'
                              )} - `
                            : ''
                        } Date de Fin: ${moment(init_due || due_date).format(
                          'dddd DD-MM-YYYY'
                        )}`
                      };
                    }
                    return {
                      name: status,
                      title: description,
                      value: `${
                        start_date !== due_date
                          ? `Date de début: ${moment(start_date).format(
                              'dddd DD-MM-YYYY'
                            )} - `
                          : ''
                      } Date de Fin: ${moment(due_date).format(
                        'dddd DD-MM-YYYY'
                      )}`
                    };
                  }
                ]}
                shape={[
                  'start_date*due_date',
                  (start_date, due_date) => {
                    if (
                      moment(start_date).format('DD-MM-YYYY') ===
                      moment(due_date).format('DD-MM-YYYY')
                    )
                      return '';
                    return 'rect';
                  }
                ]}
                type="intervalDodge"
                position="task*range"
                color={['color', (value) => value || colors]}
              />
              <Geom
                type="point"
                position="task*range"
                size={25}
                shape={[
                  'start_date*due_date',
                  (start_date, due_date) => {
                    if (
                      moment(start_date).format('DD-MM-YYYY') ===
                      moment(due_date).format('DD-MM-YYYY')
                    ) {
                      return ['image', Logo];
                    }
                    return null;
                  }
                ]}
              />
            </Chart>
          ) : (
            <span>
              <CloseOutlined style={{ color: '#fff' }} />
            </span>
          )}
        </div>
      </Spin>
    </>
  );
};

Gantt.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  onClick: PropTypes.func,
  purpose: PropTypes.string,
  lengthForFederation: PropTypes.number,
  defaultLengthSize: PropTypes.number,
  selectedDates: PropTypes.arrayOf({})
};

Gantt.defaultProps = {
  data: null,
  onClick: null,
  purpose: null,
  lengthForFederation: undefined,
  defaultLengthSize: null,
  selectedDates: []
};

export default Gantt;
