import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Card, Row, Col, Badge } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const WhyDisplay = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col style={{ marginBottom: 10, textAlign: 'end' }} span={24}>
          <Badge count={data?.files?.length}>
            <CloudServerOutlined style={{ fontSize: 20 }} />
          </Badge>
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Descriptions>
              <Descriptions.Item
                contentStyle={{
                  flexDirection: 'column',
                  whiteSpace: 'pre-line'
                }}
                label={t('projects.details.description')}
              >
                {data?.description || <Tag>Aucune description</Tag>}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
};
WhyDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

WhyDisplay.defaultProps = {
  data: null
};

export default WhyDisplay;
