import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const useGenerateFormItem = () => {
  const { t } = useTranslation();

  return (
    resource,
    {
      label,
      name,
      initialValue,
      dependencies,
      hasFeedback,
      rules,
      input,
      layout,
      noLabel,
      valuePropName,
      extra,
      noStyle,
      hidden,
      priorityLabel,
      colon
    },
    fieldKey
  ) => (
    <Form.Item
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...layout}
      key={fieldKey ? [...fieldKey, ...name] : name}
      name={fieldKey ? [...fieldKey, ...name] : name}
      noStyle={noStyle}
      hidden={hidden}
      initialValue={initialValue}
      dependencies={dependencies}
      colon={colon}
      label={
        !noLabel && !priorityLabel
          ? t(`${resource}.form.${label || name.join('.')}`)
          : label
      }
      hasFeedback={hasFeedback}
      rules={rules}
      valuePropName={valuePropName}
      extra={extra}
    >
      {input || <Input />}
    </Form.Item>
  );
};

export default useGenerateFormItem;
