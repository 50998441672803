import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Col,
  Row,
  Input,
  Card,
  Tabs,
  Badge,
  Tooltip,
  Button,
  Spin,
  Tag,
  Typography
} from 'antd';
import moment from 'moment';
import { HistoryOutlined } from '@ant-design/icons';
// import { useMediaQuery } from 'react-responsive';
import useAuthContext from '../../contexts/AuthContext';
import ButtonCreateProject from '../Projects/Projects/utilsProject/ButtonCreateProject';
import { FilterIcon, SettingsIcon } from '../../utils/constants/customIcon';
import setIconStatus from '../../utils/constants/setIconStatus';
import useErrorMessage from '../../utils/ErrorMessage';
import useTime from './indicatorsCommercial/time';
import useCost from './indicatorsCommercial/cost';
import useUtility from './indicatorsCommercial/utility';
import useFederation from './indicatorsCommercial/federation';
import Realize from './indicatorsCommercial/Realize';

const { TabPane } = Tabs;
const { Title } = Typography;

const HomeCommercial = () => {
  const statuses = ['DRAFT', 'V-Init', 'V0', 'V1', 'VX'];
  const { Search } = Input;
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState();
  const indicatorsList = {
    time: useTime,
    cost: useCost,
    utility: useUtility,
    federation: useFederation
  };

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?status=DRAFT,V-Init,V0,V1,VX&populate=assignee,created_by`
      });
      setProjects(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getProjects();
    })();
  }, [getProjects]);

  const Header = () => {
    return (
      <Row
        justify="space-around"
        align="middle"
        gutter={[16, 48]}
        style={{ paddingRight: '16px' }}
      >
        <Col style={{ marginRight: 8 }}>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            // defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Col>
          <Tooltip title={t('breadcrumbs.filter')}>
            <Button type="link" style={{ padding: 0 }}>
              <FilterIcon />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title={t('profile.settings.title')}>
            <Button type="link" style={{ padding: 0 }}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Col>
        <Col style={{ marginLeft: 16 }}>
          <ButtonCreateProject />
        </Col>
      </Row>
    );
  };

  const getColor = (theme) => {
    if (theme === 'DRAFT') return { color: 'orange', fontSize: 15 };
    if (theme === 'V-Init') return { color: 'orange', fontSize: 15 };
    if (theme === 'V0') return { color: 'var(--secondaryColor)', fontSize: 15 };
    if (theme === 'V1') return { color: 'var(--addColor', fontSize: 15 };
    if (theme === 'VX') return { color: 'var(--addColor', fontSize: 15 };
    return { color: 'var(--addColor', fontSize: 15 };
  };
  const count = (purpose) => {
    let total = 0;
    projects.forEach((e) => {
      if (e.status === purpose) total += 1;
    });
    return (
      <Row justify="space-between">
        <span style={getColor(purpose)}>{purpose}</span>
        <Badge
          style={{
            color: '#000',
            backgroundColor: '#fff',
            letterSpacing: 0,
            fontSize: 10
          }}
          count={total}
        />
      </Row>
    );
  };
  const getCreatedDays = (created) => {
    const getDays = moment(Date.now()).diff(moment(created), 'days');
    if (getDays > 0) return ` Il y a ${getDays} jours`;
    return t('projects.today');
  };
  // const isReductScreen = useMediaQuery({ query: '(max-width: 1500px)' });

  const displayCard = (project) => {
    return (
      <Col key={project._id} xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
        <Card style={{ padding: '8px 0' }}>
          <Row justify="center">
            <Col style={{ textAlign: 'center' }} span={20}>
              <Col style={{ fontSize: 17, fontWeight: 'bold' }} span={24}>
                {project.name}
              </Col>
              <Col span={24} style={{ color: '#8c8c8c', textAlign: 'center' }}>
                {project.customer}
              </Col>
            </Col>
            <Col span={4}>
              {setIconStatus(project.name, project.status, false, 20)}
            </Col>
          </Row>

          <Row
            style={{ marginTop: 10, marginBottom: 10 }}
            justify="space-between"
          >
            <Col style={{ color: '#8c8c8c' }} span={12}>
              {t('projects.project_manager')}
            </Col>
            <Col>
              {project.assignee ? (
                `${project.assignee.first_name} ${project.assignee.last_name}`
              ) : (
                <Tag color="orange">{t('projects.details.no_attachment')}</Tag>
              )}
            </Col>
          </Row>
          <Row
            style={{ marginTop: 10, marginBottom: 10 }}
            justify="space-between"
          >
            {Object.entries(indicatorsList).map(([key, value]) => (
              <Col span={6} key={key}>
                {value(project, t)}
              </Col>
            ))}
          </Row>
          <Realize data={project} translate={t} />
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <HistoryOutlined style={{ marginRight: 5 }} />
              <span>{getCreatedDays(project.created_at)}</span>
            </Col>
            <Link
              to={{
                pathname: `projects/projects/show/${project._id}`
              }}
            >
              <Col span={12}>
                {project.status === 'V-Init' || project.status === 'DRAFT' ? (
                  <Button>{t('projects.edit')}</Button>
                ) : (
                  <Button style={{ color: 'var(--secondaryColor)' }}>
                    {t('projects.see')}
                  </Button>
                )}
              </Col>
            </Link>
          </Row>
        </Card>
      </Col>
    );
  };

  return projects ? (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`projects.title`)}
      </div>
      <Spin spinning={loading}>
        <Tabs tabBarExtraContent={Header()}>
          <TabPane
            tab={
              <Row justify="space-between">
                <span style={{ fontSize: 18 }}>
                  {t('projects.details.all')}
                </span>
                <Badge
                  style={{
                    color: '#000',
                    backgroundColor: '#fff',
                    letterSpacing: 0,
                    fontSize: 10
                  }}
                  title="Tous"
                  count={projects.length}
                />
              </Row>
            }
            key="1"
          >
            <Row gutter={[48, 32]}>{projects.map((e) => displayCard(e))}</Row>
          </TabPane>
          {statuses.map((s) => (
            <TabPane tab={count(s)} key={s}>
              <Row gutter={[48, 32]}>
                {projects.length > 0 ? (
                  projects.map((f) => (f.status === s ? displayCard(f) : null))
                ) : (
                  <Row justify="center">
                    <Title style={{ textAlign: 'center' }} level={2}>
                      {t('errors.message.no_projects')}
                    </Title>
                  </Row>
                )}
              </Row>
            </TabPane>
          ))}
        </Tabs>
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

export default HomeCommercial;
