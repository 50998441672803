import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Card, Row, Col, Badge, Button } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useProjectContext from '../../../../contexts/ProjectContext';
import { EditIcon } from '../../../../utils/constants/customIcon';

const WhyDisplay = ({ data, title, getProject, generateForm, role }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { setDataToModalFiles, getFilesByItem, project } = useProjectContext();

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        description: dataT.description
      })
    }
  };
  const openForm = () => {
    setVisible(true);
  };
  const closeForm = () => {
    setVisible(false);
    getProject();
  };
  useEffect(() => {
    if (data.files) getFilesByItem(data.files);
  }, [project]);

  return (
    <>
      {visible ? (
        <Card style={{ width: '100%' }}>
          {generateForm('edit', title, data, closeForm, null, config)}
        </Card>
      ) : (
        <Row>
          <Col style={{ marginBottom: 10, textAlign: 'end' }} span={24}>
            <Badge count={data?.files?.length}>
              <Button
                disabled={project?.status === 'close'}
                onClick={() =>
                  setDataToModalFiles(data?.files || [], title, data._id)
                }
                type="primary"
                icon={<CloudServerOutlined />}
              />
            </Badge>
          </Col>
          <Col span={24}>
            <Card bordered={false}>
              <Descriptions
                extra={
                  role ? (
                    <Button
                      disabled={project?.status === 'close'}
                      onClick={() => (role ? openForm() : null)}
                      size="small"
                      type="link"
                      style={{
                        color: 'var(--secondaryColor)',
                        top: 2
                      }}
                    >
                      <EditIcon />
                    </Button>
                  ) : null
                }
              >
                <Descriptions.Item
                  contentStyle={{
                    flexDirection: 'column',
                    whiteSpace: 'pre-line'
                  }}
                  label={t('projects.details.description')}
                >
                  {data?.description || <Tag>Aucune description</Tag>}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
WhyDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool
};

WhyDisplay.defaultProps = {
  data: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false
};

export default WhyDisplay;
