import React from 'react';
import GreenDrop from '../../assets/images/icons/green-drop.svg';
import BlueDrop from '../../assets/images/icons/blue-drop.svg';
import OrangeDrop from '../../assets/images/icons/orange-drop.svg';

const setVersionItem = (version) => {
  if (version === 1)
    return (
      <span
        style={{
          backgroundImage: `url(${OrangeDrop})`,
          height: 32,
          width: 24,
          marginBottom: 6
        }}
      >
        <span
          style={{
            fontSize: 10.5,
            color: 'white',
            fontWeight: 700,
            position: 'relative',
            top: 10,
            left: 5
          }}
        >
          V-I
        </span>
      </span>
    );
  if (version === 2)
    return (
      <span
        style={{
          backgroundImage: `url(${BlueDrop})`,
          height: 32,
          width: 24,
          marginBottom: 6
        }}
      >
        <span
          style={{
            fontSize: 10.5,
            color: 'white',
            fontWeight: 700,
            position: 'relative',
            top: 10,
            left: 5
          }}
        >
          V{version - 2}
        </span>
      </span>
    );
  if (version === 3)
    return (
      <span
        style={{
          backgroundImage: `url(${GreenDrop})`,
          height: 32,
          width: 24,
          marginBottom: 6
        }}
      >
        <span
          style={{
            fontSize: 10.5,
            color: 'white',
            fontWeight: 700,
            position: 'relative',
            top: 10,
            left: 5
          }}
        >
          V{version - 2}
        </span>
      </span>
    );
  if (version > 3)
    return (
      <span
        style={{
          backgroundImage: `url(${GreenDrop})`,
          height: 32,
          width: 24,
          marginBottom: 6
        }}
      >
        <span
          style={{
            fontSize: 10.5,
            color: 'white',
            fontWeight: 700,
            position: 'relative',
            top: 10,
            left: 5
          }}
        >
          V{version - 2}
        </span>
      </span>
    );
};

export default setVersionItem;
