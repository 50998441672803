/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tag, Collapse, Tabs, Row, Card, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Gantt from '../../../../components/Widgets/Gantt';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const PlaningDisplay = ({ data, actors }) => {
  const { t } = useTranslation();
  const typeOfPlaning = { planning: 'Planning', utility: 'Utilité' };
  const [ganttData, setGanttData] = useState();

  const getColorForPercentage = (value) => {
    if (value === 0) return { color: 'red' };
    if (value < 50) return { color: 'var(--clientColor)' };
    if (value > 50) return { color: 'var(--addColor)' };
    return { color: 'var(--clientColor)' };
  };

  const formatDataToChart = () => {
    const chartData = [];
    data.deliverables.map((e) => {
      if (e.planning && e.planning.gantt_mark === true) {
        const { start_date, due_date, status, color } = e.planning;
        if ((start_date, due_date !== null)) {
          chartData.push({
            id: e._id,
            task: `${e._id + e.description}`,
            start_date:
              start_date !== undefined
                ? moment(start_date).format('YYYY-MM-DD')
                : moment(due_date).format('YYYY-MM-DD'),
            due_date: moment(due_date).format('YYYY-MM-DD'),
            status,
            description: e.description,
            comment: e.comment,
            color: color || '#000'
          });
        }
      }
      setGanttData(chartData);
      return true;
    });
  };
  useEffect(() => {
    if (data) {
      formatDataToChart();
    }
  }, [data]);

  const displayPlaningHeaderItem = (
    description,
    start_date,
    due_date,
    status,
    percentage
  ) => {
    return (
      <Descriptions column={6} size="small">
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.description`)}
          span={2}
        >
          {description}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.start_date`)}
        >
          {start_date ? (
            <Tag>{moment(start_date).format('DD-MM-YYYY')}</Tag>
          ) : (
            <Tag color="red">Aucune donnée</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.due_date`)}
        >
          {due_date ? (
            <Tag>{moment(due_date).format('DD-MM-YYYY')}</Tag>
          ) : (
            <Tag>Aucune donnée</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.status`)}
        >
          <Tag>{status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.percentage`)}
        >
          <span style={getColorForPercentage(percentage)}>{percentage}</span>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const displayUtilityHeaderItem = (
    description,
    use_for,
    coefficient,
    why,
    actor
  ) => {
    return (
      <Descriptions column={5} size="small">
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.description`)}
          span={2}
        >
          {description}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.use_for`)}
        >
          {`${use_for} -`}

          {actor &&
            actors.map(
              (a) =>
                a._id === actor[0] &&
                `${a.group || ''} ${a.first_name || ''} ${a.last_name || ''}`
            )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.coefficient`)}
        >
          {coefficient}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.why`)}
        >
          {why}
        </Descriptions.Item>
      </Descriptions>
    );
  };
  const setHeaderRender = (type, value) => {
    if (type === 'Planning' && value.planning) {
      return displayPlaningHeaderItem(
        value?.description,
        value.planning?.start_date || null,
        value.planning?.due_date || null,
        value.planning?.status || null,
        value.planning?.percentage || null
      );
    }
    if (type === 'Utilité' && value.utility) {
      return displayUtilityHeaderItem(
        value?.description,
        value.utility?.use_for || null,
        value.utility?.coefficient || null,
        value.utility?.why || null,
        value.utility?.beneficiaries || null
      );
    }
    return null;
  };

  return (
    <>
      <Card bordered={false}>
        <Descriptions column={3} size="small">
          <Descriptions.Item
            style={{ color: '#00000' }}
            label="Début de projet"
          >
            {data.start_project ? (
              moment(data.start_project).format('DD-MM-YYYY')
            ) : (
              <Tag>Aucune date définie</Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Fin de projet">
            {data.end_project ? (
              moment(data.end_project).format('DD-MM-YYYY')
            ) : (
              <Tag>Aucune date définie</Tag>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Tabs
        animated={{ inkBar: true }}
        id="blue-tab-planning"
        defaultActiveKey="Gantt"
      >
        {Object.entries(typeOfPlaning).map(([key, value]) => {
          return (
            <TabPane tab={value} key={key} span={2}>
              {data.deliverables
                .filter((deli) => deli[key])
                .map((e) => {
                  const { _id, planning, utility } = e;
                  // eslint-disable-next-line no-nested-ternary
                  return (
                    <>
                      <Collapse key={_id}>
                        <Panel
                          style={{ marginBottom: 12 }}
                          header={setHeaderRender(value, e)}
                        >
                          <Descriptions
                            column={2}
                            size="small"
                            style={{ padding: 12 }}
                          >
                            <Descriptions.Item
                              label={
                                value === 'Planning'
                                  ? t(`projects.form.deliverables.actor`)
                                  : t(
                                      `projects.form.deliverables.beneficiaries`
                                    )
                              }
                            >
                              {value === 'Planning'
                                ? actors.map((a) =>
                                    a._id === planning.actor
                                      ? `${a.group || ''} ${a.first_name ||
                                          ''} ${a.last_name || ''}`
                                      : null
                                  )
                                : actors.map((a) =>
                                    a._id === (utility && utility.beneficiaries)
                                      ? `${a.group || ''} ${a.first_name ||
                                          ''} ${a.last_name || ''}`
                                      : null
                                  )}
                            </Descriptions.Item>
                          </Descriptions>
                        </Panel>
                      </Collapse>
                    </>
                  );
                })}
            </TabPane>
          );
        })}
        <TabPane tab="Gantt" span={2}>
          <Card
            style={{
              margin: '20px',
              overflowY: 'hidden',
              width: '100%',
              overflowX: 'auto',
              minHeight: 'fit-content'
            }}
          >
            <Row justify="space-between">
              <Col xl={24} sm={24}>
                <Gantt
                  key="planning"
                  range={[data.start_project, data.end_project]}
                  data={ganttData}
                  purpose="planning"
                  width={1200}
                />
              </Col>
            </Row>
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

PlaningDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actors: PropTypes.oneOfType([PropTypes.array])
};

PlaningDisplay.defaultProps = {
  data: null,
  actors: ''
};

export default PlaningDisplay;
