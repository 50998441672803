import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { routes, subRoutes } from '../../utils/constants/routes';
import { ScanContextProvider } from './Scan/ScanContext';
import DocumentTemplates from './Templates/DocumentTemplates';
import { TemplatesContextProvider } from './Templates/TemplatesContext';
import CreateTemplate from './Templates/CreateTemplate';
import SignDocument from './Templates/SignDocument';
import DocumentsManager from './GED/DocumentsManager';
import CreateFile from './GED/CreateFile';

const DocumentsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <TemplatesContextProvider>
      <ScanContextProvider>
        <Switch>
          {/* <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`}
            component={DocumentTemplates}
          /> */}
          <Route
            exact
            path={`${routes.DOCUMENTS}`}
            component={DocumentsManager}
          />
          <Route
            exact
            path={`${routes.DOCUMENTS}/create`}
            component={CreateFile}
          />
          {/* <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/create`}
            render={() => <CreateTemplate typeSelector />}
          /> */}
          {/* <Route exact path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/sign`} component={SignDocument} /> */}
          {/* <Redirect from="*" to={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`} /> */}
        </Switch>
      </ScanContextProvider>
    </TemplatesContextProvider>
  );
};

export default DocumentsRouter;
