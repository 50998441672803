import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Modal, Divider, Table } from 'antd';
import ManagePlanning from '../Projects/Projects/utilsProject/ManagePlanning/ManagePlanning';
import useHomeContext from '../../contexts/HomeContext';

const Actions = ({ id, data, purpose, title, actors }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const { getProjects, updateResource } = useHomeContext();
  const columns = [
    {
      title: `${t(`datatable.column.type`)}`,
      dataIndex: 'type',
      key: 'type',
      width: 20
    },
    {
      title: `${t(`datatable.column.entitled`)}`,
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: `${t(`datatable.column.actor`)}`,
      key: 'actor',
      dataIndex: 'actor',
      render: (el) => el,
      sorter: (a, b) => a.actor.localeCompare(b.actor)
      // width: 10
    },
    {
      title: `${t(`datatable.column.date`)}`,
      dataIndex: 'date',
      sorter: (a, b) => {
        return new Date(a?.date) - new Date(b?.date);
      },
      key: 'date',
      render: (date) => date && moment(date).format('DD-MM-YYYY'),
      width: 30
    },
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      width: 40
    }
  ];

  const config = {
    onGetResource: {
      // eslint-disable-next-line no-shadow
      setFields: (dataT) => ({
        deliverables: {
          ...dataT,
          planning: dataT.planning
            ? {
                ...dataT.planning,
                start_date: dataT.planning.start_date
                  ? moment(dataT.planning?.start_date)
                  : undefined,
                due_date: dataT.planning.due_date
                  ? moment(dataT.planning?.due_date)
                  : undefined
              }
            : undefined
        }
      })
    }
  };

  const setColor = (arg) => {
    if (arg) {
      return (
        <span
          style={{
            color: 'var(--primaryColor)',
            fontWeight: 'bold'
          }}
        >
          L
        </span>
      );
    }
    return (
      <span style={{ color: 'var(--secondaryColor)', fontWeight: 'bold' }}>
        T
      </span>
    );
  };

  const displayedData = [];
  // eslint-disable-next-line no-unused-expressions
  data?.deliverables
    .filter((deli) => deli.planning !== undefined)
    .forEach((deliverable) => {
      const tmpActor = actors.filter(
        (item) => item._id === deliverable.planning.actor
      )[0];
      if (
        purpose === 'Planifié' &&
        deliverable.planning.percentage !== 100 &&
        deliverable.planning.piloting_mark &&
        (deliverable.planning.status === 'Finalisé' ||
          deliverable.planning.status === 'Planifié' ||
          deliverable.planning.status === 'En attente de validation' ||
          deliverable.planning.status === 'Suspendu' ||
          deliverable.planning.status === 'A planifier' ||
          deliverable.planning.status === 'En cours')
      ) {
        const tmp = {
          _id: deliverable._id,
          data: deliverable,
          type: setColor(deliverable.planning?.is_task),
          label: `${deliverable?.description.substr(0, 25)}${
            deliverable?.description.length > 25 ? '[...]' : ''
          }`,
          actor: `${tmpActor?.group || ''} ${tmpActor?.first_name ||
            ''} ${tmpActor?.last_name || ''}`,
          date: deliverable.planning?.due_date,
          percentage: deliverable.planning?.percentage
        };
        displayedData.push(tmp);
      }
      if (
        purpose === 'Finalisé' &&
        deliverable.planning.percentage === 100 &&
        deliverable.planning.piloting_mark
      ) {
        const tmp = {
          _id: deliverable._id,
          data: deliverable,
          type: setColor(deliverable.planning?.is_task),
          label: `${deliverable?.description.substr(0, 25)}${
            deliverable?.description.length > 25 ? '[...]' : ''
          }`,
          actor: `${tmpActor?.group || ''}${tmpActor?.first_name ||
            ''} ${tmpActor?.last_name || ''}`,
          date: deliverable.planning?.due_date,
          percentage: deliverable.planning?.percentage
        };
        displayedData.push(tmp);
      }
    });

  const handleSubmitPlanning = (values) => {
    updateResource(
      values,
      id,
      data,
      'manage_planning',
      'deliverables',
      getProjects
    );
    setVisible(false);
  };
  return data ? (
    <Card
      style={{
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0'
      }}
      title={t(`projects.details.${title}`)}
    >
      <Divider />
      <Table
        rowKey="_id"
        onRow={(record) => ({
          onClick: () => {
            setDataToModal(record.data);
            setVisible(true);
          }
        })}
        size="small"
        columns={columns}
        dataSource={displayedData}
        scroll={{ x: 500 }}
      />
      <Modal
        closable
        width={1000}
        destroyOnClose
        visible={visible}
        onOk={() => {
          setDataToModal();
          setVisible(false);
        }}
        onCancel={() => {
          setDataToModal();
          setVisible(false);
        }}
        footer={false}
      >
        {dataToModal && (
          <ManagePlanning
            purposeArg="edit"
            dataItems={dataToModal}
            projectId={id}
            config={config}
            handleCancel={() => {
              setVisible(false);
            }}
            handleSubmit={handleSubmitPlanning}
          />
        )}
      </Modal>
    </Card>
  ) : (
    <Card
      style={{
        background: 'var(--itemHoverBackground)',
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0',
        borderRadius: 50
      }}
      title={t(`projects.details.${title}`)}
    >
      <Divider />
    </Card>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  title: PropTypes.string,
  actors: PropTypes.oneOfType([PropTypes.array])
};

Actions.defaultProps = {
  id: null,
  data: null,
  title: null,
  purpose: null,
  actors: null
};

export default Actions;
