/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const UtilityDisplay = ({ data, actors }) => {
  const { t } = useTranslation();

  return (
    <>
      {data.deliverables
        .filter((deli) => deli.utility)
        .map((e) => {
          const { _id, description, utility } = e;
          // eslint-disable-next-line no-nested-ternary
          return (
            <Collapse collapsible={false} key={_id}>
              <Panel
                showArrow={false}
                style={{ marginBottom: 16 }}
                header={
                  <Descriptions column={5} size="small">
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.description`
                      )}
                    >
                      {description}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.use_for`
                      )}
                    >
                      {`${utility?.use_for} -`}

                      {utility?.beneficiaries &&
                        actors.map(
                          (a) =>
                            a._id === utility?.beneficiaries &&
                            `${a?.group || ''} ${a?.first_name ||
                              ''} ${a?.last_name || ''}`
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t(
                        `projects.form.items.utility.deliverables.coefficient`
                      )}
                    >
                      {utility?.coefficient}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={t(`projects.form.items.utility.deliverables.why`)}
                    >
                      {utility?.why}
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </Collapse>
          );
        })}
    </>
  );
};

UtilityDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actors: PropTypes.oneOfType([PropTypes.array])
};

UtilityDisplay.defaultProps = {
  data: null,
  actors: null
};

export default UtilityDisplay;
