import React, { useEffect, useState, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Row,
  Tag,
  Tabs,
  Col,
  Spin,
  Card,
  Divider,
  Typography,
  Select,
  Form
} from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import {
  WhatIcon,
  WhyIcon,
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  UtilityIcon,
  PlanComIcon,
  RiskIcon
} from '../../utils/constants/customIcon';
import useErrorMessage from '../../utils/ErrorMessage';
import setIconStatus from '../../utils/constants/setIconStatus';
import ItemContainer from './ItemContainer';
import useTime from './indicatorsResume/time';
import useCost from './indicatorsResume/cost';
import useUtility from './indicatorsResume/utility';
import useFederation from './indicatorsResume/federation';

import ChartHidden from './ChartHidden/ChartHidden';
import EvolutionProjectSelected from './TypeSelectedComponents/EvolutionProjectSelected';
import FederationSelected from './TypeSelectedComponents/FederationSelected';
import GapSelected from './TypeSelectedComponents/GapSelected';
import MeetingReport from './TypeSelectedComponents/MeetingReport';
import PLanningSelected from './TypeSelectedComponents/PLanningSelected';
import ProjectStateSelected from './TypeSelectedComponents/ProjectStateSelected';
import {
  listTypeOfReport,
  versionToIndex,
  listItems,
  listIDForGetIndicators
} from '../../utils/constants/constantes';
import useFormatReportByType from './widgetsReporting/formatToXlsxByTypeOfReport/useFormatReportByType';

const { Title } = Typography;

const ShowReporting = () => {
  const { message } = useErrorMessage();
  const { user, dispatchAPI } = useAuthContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState();
  const [KeyTab, setKeyTab] = useState();
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [checkError, setCheckError] = useState(false);
  const checkManagePlanning = ['planing', 'utility'];
  const [version, setVersion] = useState('V0');
  const [typeOfReport, setTypeOfReport] = useState();
  const [selectedItems, setSelectedItems] = useState([...listItems]);
  const [extensionFile, setExtensionFile] = useState('pdf');
  const [selectedDates, setselectedDates] = useState([]);
  const [selectedActor, setselectedActor] = useState(null);
  const formatByTypeOfReport = useFormatReportByType(typeOfReport);

  const { TabPane } = Tabs;

  const indicatorsResume = {
    time: useTime,
    cost: useCost,
    utility: useUtility,
    federation: useFederation
  };

  const itemKeysInit = {
    what: <WhatIcon />,
    why: <WhyIcon />,
    prior: <PriorIcon />,
    actors: <ActorsIcon />,
    context: <ContextIcon />,
    cost: <CostIcon />,
    risks: <RiskIcon />,
    planing: <PlanningIcon />,
    utility: <UtilityIcon />,
    plan_com: <PlanComIcon />
  };

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?status=V&status=V0&status=V1&status=VX&assignee=${user._id}&populate=assignee,created_by&sort=-updated_at`
      });
      setProjects(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setLoading(false);
  }, [user._id]);

  const getTemplateSetting = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/reporting?filter[type]=reporting_${typeOfReport}`
      });
      setUsedTemplates(data);
      if (data.length === 0) setCheckError(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, [dispatchAPI, message, typeOfReport]);

  const generateReporting = async (name, chart, filter) => {
    try {
      if (extensionFile === 'docx' || extensionFile === 'pdf') {
        const response = await dispatchAPI('POST', {
          url: `/reporting/generate/${KeyTab}/${
            usedTemplates[usedTemplates.length - 1]._id
          }`,
          responseType: 'arraybuffer',
          body: { chart: { ...chart }, filter: { ...filter } }
        });
        const bfArray = new Uint8Array(response.data.data);
        const blob = new Blob([bfArray], {
          type: `application/${filter.extension_file}`
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}_${t(`reporting.type.${filter.type}`)}.${
          filter.extension_file
        }`;

        a.click();
      } else {
        formatByTypeOfReport(
          projects?.find((el) => el._id === KeyTab),
          filter
        );
      }
      setLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getProjects();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (typeOfReport) await getTemplateSetting();
    })();
  }, [typeOfReport]);

  useEffect(() => {
    if (projects) {
      setKeyTab(projects[0]?._id);
    }
  }, [projects]);
  const getKeyTab = (activekey) => {
    form.resetFields();
    setTypeOfReport(null);
    setUsedTemplates([]);
    setselectedActor(null);
    setKeyTab(activekey);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    const nameProject = projects.find((e) => e._id === KeyTab);
    const result = {};
    if (extensionFile === 'docx' || extensionFile === 'pdf') {
      await Promise.all(
        listIDForGetIndicators[typeOfReport].map((idOfChart) =>
          html2canvas(document.querySelector(`#${idOfChart}`)).then(
            (canvas) => {
              result[idOfChart] = canvas.toDataURL();
              return true;
            }
          )
        )
      );
      generateReporting(nameProject.name, result, values);
    } else {
      generateReporting(nameProject.name, null, values);
    }
  };

  const onValuesChange = (_, values) => {
    if (values.version) setVersion(values.version);
    if (values.items) setSelectedItems(values.items);
  };
  const onChangeTypeReport = (e) => {
    setTypeOfReport(e);
  };
  const getLengthData = (key, data) => {
    if (checkManagePlanning.includes(key)) {
      if (version !== 'VX') return versionToIndex[version] + 1;

      return data.manage_planning.length - 1 + 1;
    }
    if (version !== 'VX') return versionToIndex[version] + 1;
    return data[key].length - 1 + 1;
  };

  const getCheckPlanning = (key) => {
    if (selectedItems.includes(key)) {
      return (
        <CheckCircleOutlined
          style={{
            fontSize: 20,
            marginLeft: 40,
            marginTop: -5,
            position: 'absolute',
            color: 'var(--addColor)'
          }}
        />
      );
    }
    return null;
  };
  const listTypeOfReportComponent = {
    project_state: (
      <ProjectStateSelected
        t={t}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        keyTab={KeyTab}
        version={version}
        current_status={projects?.find((el) => el._id === KeyTab)?.status}
      />
    ),
    evolution_project: (
      <EvolutionProjectSelected
        t={t}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        keyTab={KeyTab}
        version="V0toVX"
        current_status={projects?.find((el) => el._id === KeyTab)?.status}
      />
    ),
    federation: (
      <FederationSelected
        t={t}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        keyTab={KeyTab}
        version={version}
        current_status={projects?.find((el) => el._id === KeyTab)?.status}
        actors_list={
          projects?.find((el) => el._id === KeyTab)?.actors[
            projects?.find((el) => el._id === KeyTab)?.actors?.length - 1
          ].actors_list
        }
      />
    ),
    planning: (
      <PLanningSelected
        t={t}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        keyTab={KeyTab}
        version={version}
        current_status={projects?.find((el) => el._id === KeyTab)?.status}
        actors_list={
          projects?.find((el) => el._id === KeyTab)?.actors[
            projects?.find((el) => el._id === KeyTab)?.actors?.length - 1
          ].actors_list
        }
        setselectedDates={setselectedDates}
        setselectedActor={setselectedActor}
      />
    ),
    gap: (
      <GapSelected
        t={t}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        keyTab={KeyTab}
        version={version}
        current_status={projects?.find((el) => el._id === KeyTab)?.status}
        actors_list={
          projects?.find((el) => el._id === KeyTab)?.actors[
            projects?.find((el) => el._id === KeyTab)?.actors?.length - 1
          ].actors_list
        }
      />
    ),
    meeting_report: (
      <MeetingReport
        typeOfReport={typeOfReport}
        keyTab={KeyTab}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
      />
    )
  };

  return projects ? (
    <>
      <Spin spinning={loading} tip={`${t('global.loading')}`}>
        <div style={{ fontSize: '21px', marginBottom: 10 }}>
          {t(`${pathname.substring(1)}.title`)}
        </div>
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={projects[0]?._id}
          activeKey={KeyTab}
          onChange={(activeKey) => getKeyTab(activeKey)}
        >
          {projects.length > 0 ? (
            projects.map((project) => {
              return (
                <TabPane
                  tab={setIconStatus(project.name, project.status, true, 29)}
                  key={project._id}
                >
                  <div style={{ position: 'relative' }}>
                    {typeOfReport && (
                      <ChartHidden
                        data={project}
                        version={version}
                        type={typeOfReport}
                        selectedDates={selectedDates}
                        selectedActor={selectedActor}
                      />
                    )}
                  </div>

                  <Row justify="space-around">
                    <Col style={{ textAlign: 'center' }} xl={8} lg={10}>
                      <Divider>
                        {`${t('reporting.indicators_by_version')}`}
                      </Divider>
                      <Card style={{ marginBottom: 16 }}>
                        <Row justify="space-around">
                          {Object.entries(indicatorsResume).map(
                            ([key, value]) => (
                              <Col xxl={6} sm={12} key={key}>
                                {value(project, t, version)}
                              </Col>
                            )
                          )}
                        </Row>
                      </Card>
                    </Col>
                    <Col style={{ textAlign: 'center' }} xl={10} lg={10}>
                      <Form
                        onValuesChange={onValuesChange}
                        onFinish={handleSubmit}
                        form={form}
                      >
                        <Row justify="space-between">
                          <Col span={24}>
                            <Form.Item name={['type']}>
                              <Select
                                onChange={onChangeTypeReport}
                                style={{ width: 250 }}
                              >
                                {listTypeOfReport.map((el) => (
                                  <Select.Option key={el} value={el}>
                                    {t(`reporting.type.${el}`)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {listTypeOfReportComponent[typeOfReport]}
                          <Col span={24}>
                            {usedTemplates[usedTemplates.length - 1] ? (
                              <Button
                                type="add"
                                htmlType="submit"
                                style={{ minHeight: 50, margin: 50 }}
                                disabled={!typeOfReport}
                              >
                                {`${t('projects.generate_note')} ${
                                  project.name
                                }`}
                              </Button>
                            ) : null}
                          </Col>
                        </Row>

                        {checkError ? (
                          <>
                            <Col span={24}>
                              <ExclamationCircleOutlined />
                              <span>
                                {` ${t('reporting.no_template')} ${t(
                                  `reporting.type.${typeOfReport}`
                                )}`}
                              </span>
                            </Col>

                            <Tag
                              color="red"
                              style={{ justifyContent: 'center' }}
                            >
                              {`${t('global.error')}`}
                            </Tag>
                          </>
                        ) : null}
                      </Form>
                    </Col>
                  </Row>

                  <Tabs
                    defaultActiveKey={location?.item || undefined}
                    size="large"
                    style={{
                      margin: '16px 0'
                    }}
                  >
                    {Object.entries(itemKeysInit).map(([key, value]) => {
                      return (
                        <TabPane
                          tab={
                            <Col>
                              <Row
                                justify="center"
                                style={{
                                  position: 'relative',
                                  left: 6,
                                  marginBottom: 7,
                                  textAlign: 'center'
                                }}
                              >
                                {value}
                                {getCheckPlanning(key)}
                              </Row>
                              <Row
                                justify="center"
                                style={{ fontSize: 12, textAlign: 'center' }}
                              >
                                {t(`projects.form.items.${key}.title`)}
                              </Row>
                            </Col>
                          }
                          key={key}
                          span={2}
                        >
                          <ItemContainer
                            selectedItems={selectedItems}
                            key={key}
                            data={
                              checkManagePlanning.includes(key)
                                ? project.manage_planning[
                                    version !== 'VX'
                                      ? versionToIndex[version]
                                      : project.manage_planning.length - 1
                                  ]
                                : project[key][
                                    version !== 'VX'
                                      ? versionToIndex[version]
                                      : project[key].length - 1
                                  ]
                            }
                            title={key}
                            projectId={project._id}
                            managePlanning={
                              checkManagePlanning.includes(key)
                                ? 'manage_planning'
                                : null
                            }
                            actors={
                              project.actors[project.actors.length - 1]
                                ?.actors_list
                            }
                            version={getLengthData(key, project)}
                          />
                        </TabPane>
                      );
                    })}
                  </Tabs>
                </TabPane>
              );
            })
          ) : (
            <Row justify="center">
              <Title style={{ textAlign: 'center' }} level={2}>
                {t('errors.message.no_projects')}
              </Title>
            </Row>
          )}
        </Tabs>
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

export default ShowReporting;
