/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { listObjectForItems } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';

const evolutionProject = (t, project, filter) => {
  const actorsList = project.actors[project.actors.length - 1].actors_list;
  const getActor = (actorId) => {
    if (actorId) {
      const result = actorsList.map(
        (a) =>
          a._id === actorId &&
          `${a.group || ''} ${a.first_name || ''} ${a.last_name || ''}`
      );
      return result[0] || '';
    }
    return '';
  };
  const getCellToMerge = {
    what: 'B',
    why: 'B',
    prior: 'E',
    actors: 'I',
    context: 'E',
    cost: 'G',
    risks: 'H',
    planing: 'I',
    utility: 'H',
    plan_com: 'N'
  };

  const typeOfEvolution = (arrayOfItem) => {
    if (filter.type_evolution === 'V0toV1') {
      return [arrayOfItem[1], arrayOfItem[2]];
    }
    return arrayOfItem.slice(1);
  };
  const checkDataAndReturnSorting = (data, type) => {
    switch (type) {
      case 'prior':
        return [
          data.type,
          data.denomination,
          moment(data.creation_date).format('DD-MM-YYYY'),
          data.description
        ];
      case 'actors':
        return [
          data.group,
          data.effective,
          data.first_name,
          data.last_name,
          data.code,
          data.role,
          data.gain,
          data.coefficient
        ];
      case 'context':
        return [data.denomination, data.type, data.impact, data.description];
      case 'cost':
        return [
          data.denomination,
          data.type,
          data.quote,
          `${data.charge} j/h`,
          `${data.cost_value} €`,
          data.description
        ];
      case 'risks':
        return [
          data.number,
          data.potential_risk,
          data.type_of_risk,
          data.impact_effect,
          data.criticality,
          data.decision,
          data.measure_description
        ];
      case 'planing':
        if (data.planning) {
          return [
            data.description,
            data.global_type,
            data.comment,
            moment(data.planning.start_date).format('DD-MM-YYYY'),
            moment(data.planning.due_date).format('DD-MM-YYYY'),
            getActor(data.planning.actor),
            data.planning.status,
            `${data.planning.percentage} %`
          ];
        }
        return false;
      case 'utility':
        if (data.utility) {
          return [
            data.description,
            data.global_type,
            data.comment,
            data.utility.use_for,
            getActor(data.utility.beneficiaries),
            data.utility.why,
            data.utility.coefficient
          ];
        }
        return false;
      case 'plan_com':
        return [
          data.name,
          moment(data.start_date).format('DD-MM-YYYY'),
          moment(data.due_date).format('DD-MM-YYYY'),
          data.number_frequency && data.type_frequency
            ? `${data.number_frequency} tous les ${data.type_frequency} jours`
            : '',
          data.type,
          data.channel,
          data.impact,
          getActor(data.transmitter),
          getActor(data.in_charge_of),
          getActor(data.target),
          data.federation,
          data.goal_description,
          data.message
        ];
      default:
        return false;
    }
  };
  const workbook = new Workbook();
  try {
    filter.items.forEach((key) => {
      const worksheet = workbook.addWorksheet(`${t(`projects.form.${key}`)}`);
      worksheet.properties.defaultColWidth = 15;
      worksheet.properties.defaultRowHeight = 20;
      const newHeader = headerByitems(true);
      const header = newHeader[key].map(({ label }) => {
        return t(`projects.form.${label}`);
      });
      const headerRow = worksheet.addRow(header);

      headerRow.eachCell((cell) => {
        cell.width = cell._value.model.value?.length + 10;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '202364' },
          bgColor: { argb: '' }
        };
        cell.font = {
          bold: false,
          color: { argb: 'FFFFFF' },
          size: 11
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      if (key === 'what' || key === 'why') {
        const typeEvolution = typeOfEvolution(project[key]);
        typeEvolution.forEach((element, index) => {
          const cellToStyle = worksheet.addRow([
            `${moment(element.event.date).format('DD-MM-YYYY')} - ${
              element.event.type
            } - ${element.event.name}`
          ]);
          cellToStyle.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '757171' },
              bgColor: { argb: '' }
            };
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          });
          worksheet.mergeCells(
            `${worksheet.lastRow._cells[0]._address}:B${
              worksheet.lastRow._cells[0]._address.split('A')[1]
            }`
          );
          if (element.description) {
            const cellStyle = worksheet.addRow([
              `V${index}`,
              element.description
            ]);
            if (index < typeEvolution.length - 1) {
              if (
                element.description !== typeEvolution[index + 1].description
              ) {
                cellStyle.eachCell((cell) => {
                  if (cell._value.value === element.description) {
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '' },
                      bgColor: { argb: 'D80000' }
                    };
                    cell.font = {
                      bold: false,
                      color: { argb: 'FFFFFF' },
                      size: 12
                    };
                  }
                });
              }
            } else if (index > 0) {
              if (
                element.description !== typeEvolution[index - 1].description
              ) {
                cellStyle.eachCell((cell) => {
                  if (cell._value.value === element.description) {
                    cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '' },
                      bgColor: { argb: '159001' }
                    };
                    cell.font = {
                      bold: false,
                      color: { argb: 'FFFFFF' },
                      size: 12
                    };
                  }
                });
              }
            }
          }
        });
      } else {
        let row;
        const typeEvolution = typeOfEvolution(
          project[
            key === 'planing' || key === 'utility' ? 'manage_planning' : key
          ]
        );
        typeEvolution.forEach((element, index) => {
          const cellToStyle = worksheet.addRow([
            `${moment(element.event.date).format('DD-MM-YYYY')} - ${
              element.event.type
            } - ${element.event.name}`
          ]);
          cellToStyle.eachCell((cell) => {
            cell.height = 20;
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '757171' },
              bgColor: { argb: '' }
            };
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          });
          worksheet.mergeCells(
            `${worksheet.lastRow._cells[0]._address}:${getCellToMerge[key]}${
              worksheet.lastRow._cells[0]._address.split('A')[1]
            }`
          );
          element[listObjectForItems[key]].forEach((itemList, itemIndex) => {
            row = checkDataAndReturnSorting(itemList, key);
            if (row) {
              const cellStyle = worksheet.addRow([`V${index}`, ...row]);
              row.forEach((cell, columnIndex) => {
                if (index > 0) {
                  const prevElement =
                    typeEvolution[index - 1][listObjectForItems[key]][
                      itemIndex
                    ];
                  let prevVersionRow;
                  if (prevElement) {
                    prevVersionRow = checkDataAndReturnSorting(
                      prevElement,
                      key
                    );
                  }
                  if (
                    cell !==
                    (prevVersionRow
                      ? prevVersionRow[columnIndex]
                      : prevVersionRow)
                  ) {
                    cellStyle.eachCell((styleCell) => {
                      if (styleCell._value.value === cell) {
                        styleCell.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '159001' },
                          bgColor: { argb: '' }
                        };
                        styleCell.font = {
                          bold: false,
                          color: { argb: 'FFFFFF' },
                          size: 12
                        };
                      }
                    });
                  }
                  if (index < typeEvolution.length - 1) {
                    const nextElement =
                      typeEvolution[index + 1][listObjectForItems[key]][
                        itemIndex
                      ];
                    let nextVersionRow;
                    if (nextElement) {
                      nextVersionRow = checkDataAndReturnSorting(
                        nextElement,
                        key
                      );
                    }
                    if (
                      cell !==
                      (nextVersionRow
                        ? nextVersionRow[columnIndex]
                        : nextVersionRow)
                    ) {
                      cellStyle.eachCell((styleCell) => {
                        if (styleCell._value.value === cell) {
                          styleCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'D80000' },
                            bgColor: { argb: '' }
                          };
                          styleCell.font = {
                            bold: false,
                            color: { argb: 'FFFFFF' },
                            size: 12
                          };
                        }
                      });
                    }
                  }
                }
              });
            }
          });
        });
      }
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default evolutionProject;
