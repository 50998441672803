/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Card } from 'antd';
import { useTranslation } from 'react-i18next';

const ActorsDisplay = ({ data }) => {
  const { t } = useTranslation();

  const setSpan = (arg) => {
    if (arg === 'gain') {
      return 2;
    }
    return 1;
  };

  return (
    <>
      {data.actors_list.map((e) => {
        const { _id, group, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            <Card>
              <Descriptions key={_id} column={4} title={group} size="small">
                {Object.entries(rest).map(([key, value]) => {
                  return (
                    <Descriptions.Item
                      key={key}
                      label={t(`projects.form.items.actors.${key}`)}
                      span={setSpan(key)}
                    >
                      {value}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
};

ActorsDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ActorsDisplay.defaultProps = {
  data: null
};

export default ActorsDisplay;
