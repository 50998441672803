import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Row, Col } from 'antd';
import moment from 'moment';

const Realize = ({ data, translate }) => {
  if (data.status !== 'V0' && data.status !== 'V' && data.manage_planning[2]) {
    const diff = moment(
      data.manage_planning[data.manage_planning.length - 1].end_project
    ).diff(
      moment(
        data.manage_planning[data.manage_planning.length - 1].start_project
      ),
      'days'
    );
    const realize =
      (moment(Date.now()).diff(
        moment(
          data.manage_planning[data.manage_planning.length - 1].start_project
        ),
        'days'
      ) /
        diff) *
      100;

    return (
      <Row style={{ textAlign: 'start', marginBottom: 8 }}>
        <Col span={24}>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.10em',
              fontSize: 10,
              color: '#8c8c8c'
            }}
          >
            {translate(`projects.details.realize`)}
          </span>
        </Col>
        <Col span={24}>
          <Progress
            style={{ color: '#fff !important' }}
            showInfo
            status="active"
            precision={0}
            percent={Math.round(realize) > 0 ? Math.round(realize) : 0}
            strokeWidth={10}
            type="line"
          />
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {translate(`projects.details.realize`)}
        </span>
      </Col>
      <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Progress
          status="normal"
          precision={0}
          style={{ paddingBottom: 10 }}
          percent={0}
          strokeWidth={10}
          type="line"
        />
      </Col>
    </>
  );
};

Realize.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  translate: PropTypes.func
};

Realize.defaultProps = {
  data: null,
  translate: null
};

export default Realize;
