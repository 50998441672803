import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tabs, Collapse, Row, Col } from 'antd';

import RiskMatrixChart from '../../../../components/Widgets/RiskMatrixChart';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const RiskDisplay = ({ data }) => {
  const { t } = useTranslation();
  const itemRisks = [];

  const dataChart = () => {
    data.risk_list.forEach((e) => {
      const { criticality } = e;
      itemRisks.push({
        item: e.type_of_risk ? e.type_of_risk.toString() : 'N/A',
        id: e._id,
        type: 'Risques',
        value: Math.round(criticality)
      });
    });
    return itemRisks;
  };

  return (
    <>
      <Tabs centered id="blue-tab-risks">
        <TabPane tab="Risques" key={1} span={2}>
          {data.risk_list.map((e) => {
            const {
              _id,
              files,
              type_of_risk,
              number,
              potential_risk,
              impact_effect,
              date,
              decision,
              measure_description,
              criticality,
              ...rest
            } = e;
            return (
              <Collapse key={_id}>
                <Panel
                  style={{ marginBottom: 16 }}
                  header={
                    <Descriptions
                      column={6}
                      title={`${t(
                        `projects.form.items.risks.number`
                      )} : ${number}`}
                      size="small"
                      extra={`${t(`projects.form.items.risks.date`)} : ${moment(
                        date
                      ).format('DD-MM-YYYY')} `}
                    >
                      <Descriptions.Item
                        span={2}
                        label={t(`projects.form.items.risks.potential_risk`)}
                      >
                        {potential_risk}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={t(`projects.form.items.risks.type_of_risk`)}
                      >
                        {type_of_risk}
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={2}
                        label={t(`projects.form.items.risks.impact_effect`)}
                      >
                        {impact_effect}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={t(`projects.form.items.risks.criticality`)}
                      >
                        {criticality}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                >
                  <Descriptions column={3} size="small" style={{ padding: 12 }}>
                    {Object.entries(rest).map(([key, value]) => {
                      return (
                        <Descriptions.Item
                          key={key}
                          label={t(`projects.form.items.risks.${key}`)}
                        >
                          {value}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                  <Descriptions column={4} size="small" style={{ padding: 12 }}>
                    <Descriptions.Item
                      label={t(`projects.form.items.risks.decision`)}
                    >
                      {decision}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={t(`projects.form.items.risks.measure_description`)}
                    >
                      {measure_description}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            );
          })}
        </TabPane>
        );
        <TabPane tab="Matrice" span={2}>
          <Row
            justify="center"
            style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%' }}
          >
            <Col>
              <RiskMatrixChart
                dataChart={dataChart()}
                width={700}
                height={450}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
};

RiskDisplay.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

RiskDisplay.defaultProps = {
  data: null
};

export default RiskDisplay;
